import axios from "axios";
import BaseUrl from "../config/Config";


export const GetCategory = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-categories`)
        return response
    } catch (error) {
        return error

    }
}
export const PostOverTabsData = async (_id) => {
    try {
        const response = await axios.post(`${BaseUrl}/get-data/${_id}`)
        return response
    } catch (error) {
        return error

    }
}
// <----------- Get Home Page Data ----------->
export const GetHomePageData = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-landingPage`)
        return response
    } catch (error) {
        return error

    }
}

// <----------- Get Home Page Data ----------->
export const ApplyHere = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/apply`,data)
        return response
    } catch (error) {
        return error

    }
}

// <----------- Get Home Page Data ----------->
export const ContactUs = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/contact-us`,data)
        return response
    } catch (error) {
        return error

    }
}
// <----------- Get Home Page Data ----------->
export const FeedbackForm = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/create-feedback`,data)
        return response
    } catch (error) {
        return error

    }
}

// <----------- Get Placement List ----------->
export const GetPlacementList = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-placementData`)
        return response
    } catch (error) {
        return error

    }
}

// <----------- Get Placement List ----------->
export const GetNewsEvents = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/news-events`)
        return response
    } catch (error) {
        return error

    }
}
// <----------- Get Placement List ----------->
export const GetMous_Tie = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-MoU`)
        return response
    } catch (error) {
        return error

    }
}

// <----------- Get Placement List ----------->
export const CreateStudentEnquirey = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/createStudent-enquirey`,data)
        return response
    } catch (error) {
        return error

    }
}

    // <--------------------Contact Us---------------->


export const ContactUsAdd = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}/get-contact-us-address`,data)
        return response
    } catch (error) {
        return error

    }
}

export const GetExploreAdd = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-bgbanner`)
        return response
    } catch (error) {
        return error

    }
}

export const GetSettingsAdd = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-settings`)
        return response
    } catch (error) {
        return error

    }
}
export const Getalllegaldoc = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-all-legal-doc`)
        return response
    } catch (error) {
        return error

    }
}
export const Studentstestimonial = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/get-testimonial`)
        return response
    } catch (error) {
        return error

    }
}