import React from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/about-bg-img.webp'
import aboutus from '../../assets/images/about-us.png'
// import Trained from '../Trained/Trained'
import Footer from '../footer/Footer'
import Header from '../header/Header'
const Ctdc = () => {
  return (
    <div>
        <Header/>
       < section className="about-breadcrumb breadcumb-bg"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '400px'
                }}>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link >/</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Coding & Technical Development Cell</li>
                                </ul>
                                <h2 className="heading-title">About Coding & Technical Development Cell</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className="overview-area aboutus-vi">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="overview-content">
								<div className="image">
									<img src={aboutus}
										alt="overview-image"/>
								</div>

							</div>
						</div>
						<div className="col-lg-6">
							<div className="overview-image aboutover">
								<div className="title">
									<h2>
										Coding & Technical Development Cell
									</h2>

									<p>The Department of Training and Placement (T & P) at CDGI is
										vibrant and strives to place the final year students of MBA and
										BE. The department of T & P is known for extending full time
										services to the students by providing proper guidelines to get
										placed in campus interviews. The team at T & P department
										strives hard to contact company officials and invite them to
										visit the institute for recruiting our eligible students.</p>
									<p><strong>Every year several companies are approached and a good
											number of students get placed.</strong></p>

								</div>

							</div>
						</div>
					</div>
					
				</div>
			</div>
            {/* <Trained/> */}
            <Footer/>
    </div>
  )
}

export default Ctdc
