import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { GetHomePageData } from "../../api/Global";
import imagebaseurl from '../../config/imageurl';

const Banners = () => {
  const [BannerList, setBannerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ChameliDeviInstitueId, setChameliDeviInstitueId] = useState(null);
const [Second_instituteIcon,setSecond_InstitueIcon] = useState("");
  useEffect(() => {
    const fetchBannerList = async () => {
      setIsLoading(true);
      try {
        const response = await GetHomePageData();
        setBannerList(response.data.data.instituteBanners);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
        console.log(error);
      }
    };
    fetchBannerList();
  }, []);

  useEffect(() => {
    const checkLocalStorageAndCallGet = () => {
      var Chameli_Devi_InstitueId = localStorage.getItem(
        "Chameli_Devi_InstitueId"
      );

      if (Chameli_Devi_InstitueId) {
        console.log("Chameli_Devi_InstitueId", Chameli_Devi_InstitueId);
        setChameliDeviInstitueId(Chameli_Devi_InstitueId); // Update state with the ID
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkLocalStorageAndCallGet, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const checkAndSaveInstituteIcon = () => {
      const instituteIcon1 = BannerList[1]?.instituteIcon;
      const instituteIcon2 = BannerList[2]?.instituteIcon;
      const instituteIcon3 = BannerList[3]?.instituteIcon;

      if (instituteIcon1 !== undefined) { 
        localStorage.setItem("Second_instituteIcon", instituteIcon1);
        setSecond_InstitueIcon(instituteIcon1); 
        clearInterval(interval); 
      }
      if(instituteIcon2 != undefined){
        localStorage.setItem("Third_instituteIcon", instituteIcon2);
        setSecond_InstitueIcon(instituteIcon1); 
        clearInterval(interval); 
      }
      if(instituteIcon3 != undefined){
        localStorage.setItem("Fourth_instituteIcon", instituteIcon3);
        setSecond_InstitueIcon(instituteIcon3); 
        clearInterval(interval); 
      }
    };

    const interval = setInterval(checkAndSaveInstituteIcon, 1000);

    return () => clearInterval(interval); 
  }, [BannerList]); 


  console.log("Second_instituteIcon",Second_instituteIcon)
  return (
    <div>
      {isLoading ? (
        <>
          <div className="chat-window text-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
                zIndex: "999",
              }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="bg-light p-4">
            <div className="container">
              <div className="row text-center">
                {BannerList?.map((image, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-lg-3 col-sm-6 col-xs-12"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-widget-container"
                          onClick={() => {
                            if (index === 0) {
                              localStorage.setItem(
                                "selectedSubCategoryName",
                                "Overview"
                              );
                            } else if (index === 1) {
                              localStorage.setItem(
                                "selectedSubCategoryName",
                                "Chameli Devi Institute of Pharmacy"
                              );
                            } else if (index === 2) {
                              localStorage.setItem(
                                "selectedSubCategoryName",
                                "About CDIPS"
                              );
                            } else if (index === 3) {
                              localStorage.setItem(
                                "selectedSubCategoryName",
                                "Chameli Devi Institute of Law"
                              );
                            }
                            if (index === 1) {
                              localStorage.setItem("College_id", image?._id);
                            }
                          }}
                        >
                          <Link
                            className="tm-cover-image-box unicamp-box style-01 link-secret"
                            to={
                              index === 0
                                ? `${`/overview/${ChameliDeviInstitueId}/cdgi`}`
                                : index === 1
                                ? `/Overview/66e95ca59fd5e8df87e797cf/cdip`
                                : index === 2
                                ? `/Overview/66e984119fd5e8df87e79e7c/cdips`
                                : index === 3
                                ? `/Overview/66ec1056382587071faff44e/cdil`
                                : `/Overview/default`
                            }
                          >
                            <div
                              className="content-wrap"
                              onClick={() =>
                                localStorage.setItem(
                                  "instituteIcon",
                                  image?.instituteIcon
                                )
                              }
                            >
                              <div className="unicamp-image image">
                                <img
                                  src= {`${imagebaseurl}/${image?.instituteImage}`} 
                                  alt={image?.name}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div className="box-caption-wrap">
                                <div className="box-caption">
                                  <h3 className="title">
                                    {image?.instituteName}
                                  </h3>
                                  <div className="tm-button-wrapper">
                                    <div className="tm-button style-text tm-button-nm icon-right">
                                      <Link
                                        to={
                                          index === 0
                                            ? `/overview/66e44073a14302881efc887a/cdgi`
                                            : index === 1
                                            ? `/Overview/66e95ca59fd5e8df87e797cf/cdip`
                                            : index === 2
                                            ? `/Overview/66e984119fd5e8df87e79e7c/cdips`
                                            : index === 3
                                            ? `/Overview/66ec1056382587071faff44e/cdil`
                                            : `/Overview/default`
                                        }
                                      >
                                        <div className="button-content-wrapper">
                                          <span className="button-text">
                                            Learn more
                                          </span>
                                          <span className="button-icon">
                                            <FaArrowRightLong />
                                          </span>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Banners;
