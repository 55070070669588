// import React, { useEffect, useState } from 'react'
// import { Link, useParams } from 'react-router-dom'
// import ReactHtmlParser from "react-html-parser";
// import backgroundImage from '../../assets/images/444.png'
// import Header from '../header/Header'
// // import Trained from '../Trained/Trained'
// import Footer from '../footer/Footer'
// import { PostOverTabsData } from '../../api/Global';
// import { FaFilePdf } from "react-icons/fa";
// import { useNavigate } from 'react-router-dom';
// import imagebaseurl from '../../config/imageurl';
// import { TiArrowUp, TiArrowDown } from "react-icons/ti";
// import { Accordion } from "react-bootstrap";
// import moment from 'moment/moment';


// const Overview = () => {
//     const navigate = useNavigate();
//     const id = useParams();
//     const Overview_id = id?._id;
//     console.log("Overview_id", Overview_id)
//     const [OverViewdata, setOverViewdata] = useState([]);
//     const [OverViewPageDetails, setOverViewPageDetails] = useState({});
//     console.log("OverViewPageDetails", OverViewPageDetails)
//     const [activeKey, setActiveKey] = useState("");
//     const GetselectedCategoryName = localStorage.getItem("selectedSubCategoryName")
//     console.log("GetselectedCategoryName", GetselectedCategoryName)
//     const [data, setData] = useState([]);
//     const [error, setError] = useState(null);
//     const [isOpen, setIsOpen] = useState(false);
//     const [photoIndex, setPhotoIndex] = useState(0);
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const GetTheOverViewData = async () => {
//         try {
//             if (Overview_id) {
//                 const response = await PostOverTabsData(Overview_id)
//                 setOverViewdata(response?.data?.data)
//             }
//             // console.log('ererere',response?.data?.data);

//         }
//         catch (error) {
//             console.log("error", error)
//         }
//     }
//     console.log("GetTheOverViewData", OverViewdata)

//     const HandleActiveTabs = (id) => {
//         setActiveKey(id);
//         GetOverDataDetails(id)
//     }

//     const GetOverDataDetails = async (id) => {
//         try {
//             const response = await PostOverTabsData(id);
//             setOverViewPageDetails(response?.data?.data);
//         }
//         catch (error) {
//             console.log("error", error);
//         }
//     }
//     console.log("GetOverDataDetails", OverViewPageDetails)
//     useEffect(() => {
//         GetTheOverViewData();
//     }, [Overview_id])

//     useEffect(() => {
//         GetOverDataDetails(activeKey);
//     }, [activeKey])

//     useEffect(() => {
//         const savedActiveTabId = localStorage.getItem('ActiveTabs');
//         if (savedActiveTabId) {
//             setActiveKey(savedActiveTabId);
//         } else if (OverViewdata && OverViewdata?.tabs?.length > 0) {
//             setActiveKey(OverViewdata?.tabs[0]?._id);
//         }
//     }, [OverViewdata]);


//     const handleClick = (overviewDoc, pdftitle) => {
//         localStorage.setItem('selectedDoc', overviewDoc);
//         localStorage.setItem('selectedDoc_title', pdftitle);
//         navigate('/More_Pdf_Open');
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);





//     const handleImageClick = async (index) => {
//         // setLoading(true); 
//         const imageToLoad = new Image();
//         imageToLoad.src = images[index];


//         imageToLoad.onload = () => {
//             setLoading(false);
//             setPhotoIndex(index);
//             setIsOpen(true);
//         };


//         imageToLoad.onerror = () => {
//             setLoading(false);
//             console.error('Error loading image:', images[index]);
//             alert('Failed to load image, please try again later.');
//         };
//     };
//     const [showTopIcon, setShowTopIcon] = useState(false);

//     // Scroll to top
//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: "smooth",
//         });
//     };

//     // Scroll to bottom
//     const scrollToBottom = () => {
//         window.scrollTo({
//             top: document.documentElement.scrollHeight,
//             behavior: "smooth",
//         });
//     };

//     // Handle scroll to toggle visibility of buttons
//     const handleScroll = () => {
//         const scrollTop = window.scrollY;
//         const scrollHeight = document.documentElement.scrollHeight;
//         const clientHeight = document.documentElement.clientHeight;

//         if (scrollTop === 0) {
//             setShowTopIcon(false); // At the top, show scroll-down button
//         } else if (scrollTop + clientHeight >= scrollHeight) {
//             setShowTopIcon(true); // At the bottom, show scroll-up button
//         } else {
//             setShowTopIcon(scrollTop > 0); // Show scroll-up button when scrolling
//         }
//     };

//     useEffect(() => {
//         window.addEventListener("scroll", handleScroll);
//         return () => {
//             window.removeEventListener("scroll", handleScroll);
//         };
//     }, []);
//     const sortedData = OverViewPageDetails?.multipleImages
//   ?.filter((imgData) => imgData?.images?.length > 0)
//   .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting in Descending Order

//     return (
//         Overview_id && <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//         page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
//          e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
//             <Header />
//             < section className="about-breadcrumb breadcumb-bg"
//                 style={{
//                     backgroundImage: `url(${backgroundImage})`,
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center',
//                     backgroundRepeat: 'no-repeat',
//                     width: '100%',
//                     height: '60vh',
//                     backgroundAttachment: 'fixed',

//                 }}>

//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="breadcrumb-content">
//                                 <ul className="breadcrumb">
//                                     {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li> */}
//                                     {/* <li className="breadcrumb-item"><Link >/</Link></li>
//                                     <li className="breadcrumb-item active" aria-current="page">Abuot Us
//                                     </li> */}
//                                 </ul>
//                                 <h2 className="heading-title">{GetselectedCategoryName}</h2>
//                                 {/* <h2 className="heading-title">CHAMELI DEVI GROUP OF<br/> INSTITUTIONS </h2> */}
//                                 {/* <h2 className="heading-title">Chameli devi group of<br/> Institute </h2> */}

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section >
//             <div className='row text-center m-3 '>

//                 {
//                     OverViewdata?.tabs?.map((OverViewdataResult, index) => {
//                         return (
//                             <div key={index} className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
//                                 <div className={`p-2 m-1 ${activeKey == OverViewdataResult?._id ? "active-tab1" : "inactive-tab1"}`}
//                                     onClick={() => {
//                                         HandleActiveTabs(OverViewdataResult?._id)
//                                         localStorage.setItem("ActiveTabs", OverViewdataResult?._id)
//                                     }}>
//                                     <p>{OverViewdataResult?.name}</p>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//             <div className="py-5">
//                 <div className='container'>

//                     <div>
//                         {OverViewPageDetails?.blogs?.map((OverviewBlogDeatilsResult, index) => {
//                             return (
//                                 <div key={index} className="blog-content-container">
//                                     {ReactHtmlParser(OverviewBlogDeatilsResult?.content)}
//                                 </div>
//                             );
//                         })}
//                     </div>

//                     <div>
//                         {
//                             OverViewdata?.blogs?.map((OverViewdataBlogsResult) => {
//                                 return (
//                                     <>
//                                         <span>{ReactHtmlParser(OverViewdataBlogsResult?.content)}</span>
//                                     </>
//                                 )
//                             })
//                         }
//                     </div>

//                     <div className="row bog_team">
//                         {
//                             OverViewPageDetails?.BOG?.length > 0 && (
//                                 <>
//                                     {
//                                         OverViewPageDetails?.BOG?.sort((a, b) => {
//                                             if (a.designation_position === null) return 1;
//                                             if (b.designation_position === null) return -1;
//                                             return a.designation_position - b.designation_position;
//                                         })
//                                             .map((OverViewBlogDetails) => {
//                                                 return (
//                                                     <>
//                                                         {
//                                                             OverViewBlogDetails?.pdfFile != null ? (
//                                                                 <div class="col-md-4 col-12" style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
//                                                                     <div class="team-card style2">
//                                                                         <div class="team-img-wrap">
//                                                                             <div class="team-img"><img
//                                                                                 src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                                                 alt="Team" /></div>
//                                                                         </div>
//                                                                         <div class="team-content">
//                                                                             <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
//                                                                             <p>{OverViewBlogDetails?.companyName}</p>
//                                                                             {/* <p>{OverViewBlogDetails?.designation_position}</p> */}
//                                                                             <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             ) : (
//                                                                 <>
//                                                                     <div class="col-md-4 col-12 d-flex">
//                                                                         <div class="team-card style2 dkkdkkd w-100">
//                                                                             <div class="team-img-wrap">
//                                                                                 <div class="team-img"><img
//                                                                                     src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                                                     alt="Team" /></div>
//                                                                             </div>
//                                                                             <div class="team-content">
//                                                                                 <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
//                                                                                 <p>{OverViewBlogDetails?.companyName}</p>
//                                                                                 {/* <p>{OverViewBlogDetails?.designation_position}</p> */}
//                                                                                 <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </>
//                                                             )
//                                                         }

//                                                     </>
//                                                 )
//                                             })
//                                     }
//                                 </>
//                             )
//                         }
//                     </div>

//                     <div className="row bog_team">
//                         {
//                             OverViewdata?.BOG?.length > 0 && (
//                                 <>
//                                     {
//                                         OverViewdata?.BOG?.sort((a, b) => {
//                                             if (a.designation_position === null) return 1;
//                                             if (b.designation_position === null) return -1;
//                                             return a.designation_position - b.designation_position;
//                                         })

//                                             .map((OverViewBlogDetails) => {
//                                                 return (
//                                                     <>
//                                                         {
//                                                             OverViewBlogDetails?.pdfFile != null ? (

//                                                                 <div class="col-md-4 col-12" style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
//                                                                     <div class="team-card style2">
//                                                                         <div class="team-img-wrap">
//                                                                             <div class="team-img"><img
//                                                                                 src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                                                 alt="Team" /></div>
//                                                                         </div>
//                                                                         <div class="team-content">
//                                                                             <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
//                                                                             <p>{OverViewBlogDetails?.companyName}</p>
//                                                                             <p>{OverViewBlogDetails?.designation_position}</p>
//                                                                             <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>

//                                                             ) : (
//                                                                 <>
//                                                                     <div class="col-md-4 col-12">
//                                                                         <div class="team-card style2">
//                                                                             <div class="team-img-wrap">
//                                                                                 <div class="team-img"><img
//                                                                                     src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                                                     alt="Team" /></div>
//                                                                             </div>
//                                                                             <div class="team-content">
//                                                                                 <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
//                                                                                 <p>{OverViewBlogDetails?.companyName}</p>
//                                                                                 <p>{OverViewBlogDetails?.designation_position}</p>
//                                                                                 <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </>
//                                                             )
//                                                         }

//                                                     </>
//                                                 )
//                                             })
//                                     }
//                                 </>
//                             )
//                         }
//                     </div>



//                     {/* <div className="row bog_team">
//     {
//         OverViewPageDetails?.BOG?.length > 0 && (
//             <>
//                 {
//                     OverViewPageDetails?.BOG
//                         ?.sort((a, b) => {

//                             if (a.designation_position === null) return 1; // Move null to the bottom
//                             if (b.designation_position === null) return -1; // Move null to the bottom
//                             return a.designation_position - b.designation_position; // Sort numerically
//                         })
//                         .map((OverViewBlogDetails) => {
//                             return (
//                                 <div className="col-md-4 col-12" key={OverViewBlogDetails?._id} style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
//                                     <div className="team-card style2">
//                                         <div className="team-img-wrap">
//                                             <div className="team-img">
//                                                 <img
//                                                     src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                     alt="Team" />
//                                             </div>
//                                         </div>
//                                         <div className="team-content">
//                                             <h3 className="team-title">{OverViewBlogDetails?.name}</h3>
//                                             <p>{OverViewBlogDetails?.companyName}</p>

//                                             <span className="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             );
//                         })
//                 }
//             </>
//         )
//     }
// </div>

// <div className="row bog_team">
//     {
//         OverViewdata?.BOG?.length > 0 && (
//             <>
//                 {
//                     OverViewdata?.BOG
//                         ?.sort((a, b) => {

//                             if (a.designation_position === null) return 1; 
//                             if (b.designation_position === null) return -1; 
//                             return a.designation_position - b.designation_position;
//                         })
//                         .map((OverViewBlogDetails) => {
//                             return (
//                                 <div className="col-md-4 col-12" key={OverViewBlogDetails?._id} style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
//                                     <div className="team-card style2">
//                                         <div className="team-img-wrap">
//                                             <div className="team-img">
//                                                 <img
//                                                     src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
//                                                     alt="Team" />
//                                             </div>
//                                         </div>
//                                         <div className="team-content">
//                                             <h3 className="team-title">{OverViewBlogDetails?.name}</h3>
//                                             <p>{OverViewBlogDetails?.companyName}</p>
//                                             <p>{OverViewBlogDetails?.designation_position}</p>
//                                             <span className="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             );
//                         })
//                 }
//             </>
//         )
//     }
// </div> */}



// {/* <div className="row bog_team">
//                         {OverViewPageDetails?.multipleImages?.length > 0 ? (
//                             <Accordion>
//                                 {OverViewPageDetails.multipleImages
//                                     .filter((imgData) => imgData?.images?.length > 0)
//                                     .map((imgData, index) => (
//                                         <Accordion.Item eventKey={index.toString()} key={index}>


//                                             <Accordion.Header>
//                                                 <div className="event-caption d-flex align-items-center">

//                                                     <img
//                                                         src={`${imagebaseurl}/${imgData.images[0]}`}
//                                                         alt="Event Thumbnail"
//                                                         style={{
//                                                             width: "150px",
//                                                             height: "150px",
//                                                             objectFit: "cover",
//                                                         }}
//                                                     />

//                                                     <div className="event_title_info">
//                                                         <div className="metaevent-time">Event:
//                                                             {moment(imgData.date).format("DD MMMM YYYY")}
//                                                         </div>
//                                                         <h5>{ReactHtmlParser(imgData?.title)}</h5>
//                                                     </div>
//                                                 </div>
//                                             </Accordion.Header>



//                                             <Accordion.Body>
//                                                 <div className="event-images mb-3">
//                                                     <div className="event_grid">
//                                                         {imgData.images.map((imgSrc, imgIndex) => (
//                                                             <div key={imgIndex} className="image-wrapper mb-2">
//                                                                 <a
//                                                                     href={`${imagebaseurl}/${imgSrc}`}
//                                                                     data-fancybox={`gallery-${index}`}
//                                                                     data-caption={`Event Image ${imgIndex + 1}`}
//                                                                 >
//                                                                     <img
//                                                                         src={`${imagebaseurl}/${imgSrc}`}
//                                                                         alt={`Event Image ${imgIndex + 1}`}
//                                                                         className="img-fluid"
//                                                                         style={{
//                                                                             width: "300px",
//                                                                             height: "250px",
//                                                                             marginRight: "10px",
//                                                                             objectFit: "cover",
//                                                                             cursor: "pointer",
//                                                                         }}
//                                                                     />
//                                                                 </a>
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 </div>
//                                             </Accordion.Body>
//                                         </Accordion.Item>
//                                     ))}
//                             </Accordion>
//                         ) : (
//                             <p>No images available</p>
//                         )}
//                     </div> */}


// {/* <div className="row bog_team">
//   {OverViewPageDetails?.multipleImages?.length > 0 ? (
//     <Accordion>
//       {OverViewPageDetails.multipleImages
//         .filter((imgData) => imgData?.images?.length > 0)
//         .sort((a, b) => new Date(b.date) - new Date(a.date)) 
//         .map((imgData, index) => (
//           <Accordion.Item eventKey={index.toString()} key={index}>

//             <Accordion.Header>
//               <div className="event-caption d-flex align-items-center">

//                 <img
//                   src={`${imagebaseurl}/${imgData.images[0]}`}
//                   alt="Event Thumbnail"
//                   style={{
//                     width: "150px",
//                     height: "150px",
//                     objectFit: "cover",
//                   }}
//                 />

//                 <div className="event_title_info">
//                   <div className="metaevent-time">
//                     Event: {moment(imgData.date).format("DD MMMM YYYY")}
//                   </div>
//                   <h5>{ReactHtmlParser(imgData?.title)}</h5>
//                 </div>
//               </div>
//             </Accordion.Header>


//             <Accordion.Body>
//               <div className="event-images mb-3">
//                 <div className="event_grid">
//                   {imgData.images.map((imgSrc, imgIndex) => (
//                     <div key={imgIndex} className="image-wrapper mb-2">
//                       <a
//                         href={`${imagebaseurl}/${imgSrc}`}
//                         data-fancybox={`gallery-${index}`}
//                         data-caption={`Event Image ${imgIndex + 1}`}
//                       >
//                         <img
//                           src={`${imagebaseurl}/${imgSrc}`}
//                           alt={`Event Image ${imgIndex + 1}`}
//                           className="img-fluid"
//                           style={{
//                             width: "300px",
//                             height: "250px",
//                             marginRight: "10px",
//                             objectFit: "cover",
//                             cursor: "pointer",
//                           }}
//                         />
//                       </a>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Accordion.Body>
//           </Accordion.Item>
//         ))}
//     </Accordion>
//   ) : (
//     <p>No images available</p>
//   )}
// </div> */}

// <div className="row bog_team">
//   {OverViewPageDetails?.multipleImages?.length > 0 ? (
//     <Accordion defaultActiveKey="0"> {/* 👈 First accordion item open by default */}
//       {OverViewPageDetails.multipleImages
//         .filter((imgData) => imgData?.images?.length > 0)
//         .sort((a, b) => new Date(b.date) - new Date(a.date)) // 👈 Sorting by Date (Latest First)
//         .map((imgData, index) => (
//           <Accordion.Item eventKey={index.toString()} key={index}>
//             {/* Accordion Header */}
//             <Accordion.Header>
//               <div className="event-caption d-flex align-items-center">
//                 {/* First Image */}
//                 <img
//                   src={`${imagebaseurl}/${imgData.images[0]}`}
//                   alt="Event Thumbnail"
//                   style={{
//                     width: "150px",
//                     height: "150px",
//                     objectFit: "cover",
//                   }}
//                 />
//                 {/* Title & Date */}
//                 <div className="event_title_info">
//                   <div className="metaevent-time">
//                     Event: {moment(imgData.date).format("DD MMMM YYYY")}
//                   </div>
//                   <h5>{ReactHtmlParser(imgData?.title)}</h5>
//                 </div>
//               </div>
//             </Accordion.Header>

//             {/* Accordion Body (Images Section) */}
//             <Accordion.Body>
//               <div className="event-images mb-3">
//                 <div className="event_grid">
//                   {imgData.images.map((imgSrc, imgIndex) => (
//                     <div key={imgIndex} className="image-wrapper mb-2">
//                       <a
//                         href={`${imagebaseurl}/${imgSrc}`}
//                         data-fancybox={`gallery-${index}`}
//                         data-caption={`Event Image ${imgIndex + 1}`}
//                       >
//                         <img
//                           src={`${imagebaseurl}/${imgSrc}`}
//                           alt={`Event Image ${imgIndex + 1}`}
//                           className="img-fluid"
//                           style={{
//                             width: "300px",
//                             height: "250px",
//                             marginRight: "10px",
//                             objectFit: "cover",
//                             cursor: "pointer",
//                           }}
//                         />
//                       </a>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Accordion.Body>
//           </Accordion.Item>
//         ))}
//     </Accordion>
//   ) : (
//     <p></p>
//   )}
// </div>



//                     <div className="row bog_team">
//                         {OverViewPageDetails?.gallery?.length > 0 && (
//                             OverViewPageDetails?.gallery?.map((image, index) => (
//                                 <div className="col-12 col-md-3 col-lg-4 mt-3">
//                                     <div className="card" key={index}>
//                                         <div className="card-image">
//                                             {
//                                                 image?.mediaType === "video" ? (
//                                                     <>
//                                                         <video
//                                                             // autoPlay
//                                                             controls
//                                                             className="photo-item__video NewsDetailsVideos"
//                                                             muted
//                                                             // preload="auto"
//                                                             style={{
//                                                                 width: "90%",
//                                                                 // maxHeight: "500px",
//                                                                 height: "500px",
//                                                                 objectFit: "contain",
//                                                                 display: "block",
//                                                                 margin: "0 auto"
//                                                             }}
//                                                         >
//                                                             <source src={`${imagebaseurl}/${image?.galleryVideo}`} type="video/mp4" />
//                                                         </video>
//                                                         <h6>{image?.galleryName}</h6>

//                                                     </>

//                                                 ) : (
//                                                     <>
//                                                         <Link className='leb-setup' to={`${imagebaseurl}/${image?.galleryImage}`} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
//                                                             <img className='img-fluid' src={`${imagebaseurl}/${image?.galleryImage}`} alt={image.alt || `Image ${index + 1}`}

//                                                             />
//                                                         </Link>
//                                                         <h6>{image?.galleryName}</h6>
//                                                     </>
//                                                 )
//                                             }
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))
//                         )
//                         }
//                     </div>
//                     <div className="row bog_team">
//                         {OverViewdata?.gallery?.length > 0 && (
//                             OverViewdata?.gallery?.map((image, index) => (
//                                 <div className="card col-4 mt-3" key={index}>
//                                     <div className="card-image">
//                                         {
//                                             image?.mediaType === "video" ? (
//                                                 <>
//                                                     <video
//                                                         // autoPlay
//                                                         controls
//                                                         className="photo-item__video NewsDetailsVideos"
//                                                         muted
//                                                         // preload="auto"
//                                                         style={{
//                                                             width: "90%",
//                                                             // maxHeight: "500px",
//                                                             height: "500px",
//                                                             objectFit: "contain",
//                                                             display: "block",
//                                                             margin: "0 auto"
//                                                         }}
//                                                     >
//                                                         <source src={`${imagebaseurl}/${image?.galleryVideo}`} type="video/mp4" />
//                                                     </video>
//                                                     <h6>{image?.galleryName}</h6>
//                                                 </>

//                                             ) : (
//                                                 <>
//                                                     <Link to={`${imagebaseurl}/${image?.galleryImage}`} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
//                                                         <img src={`${imagebaseurl}/${image?.galleryImage}`} alt={image.alt || `Image ${index + 1}`}
//                                                             style={{ height: "50vh", width: "90%" }}
//                                                         />
//                                                     </Link>
//                                                     <h6>{image?.galleryName}</h6>
//                                                 </>
//                                             )
//                                         }
//                                         {/* <h4>{image?.galleryName}</h4> */}
//                                     </div>
//                                 </div>
//                             ))
//                         )
//                         }
//                     </div>



//                     {/* {OverViewPageDetails?.multipleImages?.length > 0 ? (
//                         <div className="row bog_team">
//                             {OverViewPageDetails.multipleImages
//                                 .filter((imgData) => imgData?.images?.length > 0) 
//                                 .map((imgData, index) => (
//                                     <div className="container my-5" key={index}>
//                                         <div className="row">
//                                             <div className="col-12">
//                                                 <div className="event-item">
//                                                     {imgData?.title && (
//                                                         <div className="event-caption mb-2">
//                                                             <h5>{ReactHtmlParser(imgData.title)}</h5>
//                                                         </div>
//                                                     )}

//                                                     <div className="event-images mb-3">
//                                                         <div className="event_grid">
//                                                             {imgData.images.map((imgSrc, imgIndex) => (
//                                                                 <div key={imgIndex} className="image-wrapper mb-2">

//                                                                     <a
//                                                                         href={`${imagebaseurl}/${imgSrc}`}
//                                                                         data-fancybox={`gallery-${index}`}
//                                                                         data-caption={`Event Image ${imgIndex + 1}`}>
//                                                                         <img
//                                                                             src={`${imagebaseurl}/${imgSrc}`}
//                                                                             alt={`Event Image ${imgIndex + 1}`}
//                                                                             className="img-fluid"
//                                                                             style={{
//                                                                                 width: '300px',
//                                                                                 height: '250px',
//                                                                                 marginRight: '10px',
//                                                                                 objectFit: 'cover',
//                                                                                 cursor: 'pointer',
//                                                                             }}
//                                                                         />
//                                                                     </a>
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}
//                         </div>
//                     ) : (
//                         <p>No images available</p>
//                     )} */}



//                     <div className="row initiative_box">
//                         {
//                             OverViewPageDetails?.docs?.length > 0 && (
//                                 <>
//                                     <h4>Please click on the view button to view the messages:</h4>
//                                     {
//                                         OverViewPageDetails?.docs?.map((OverviewDocResult) => {
//                                             return (
//                                                 <>
//                                                     <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6">
//                                                         <div class="courses-item">
//                                                             <div class="content">
//                                                                 <div className="d-flex justify-content-between align-items-center">
//                                                                     <h5>{OverviewDocResult?.fileName}</h5>
//                                                                     <FaFilePdf size={60} color='rgb(120 155 194)' />
//                                                                 </div>
//                                                                 <div onClick={() => handleClick(OverviewDocResult?.fileUrl, OverviewDocResult?.fileName)} >
//                                                                     <Link to='#' class="text-decoration-none pharmabtn"
//                                                                     >
//                                                                         View
//                                                                         <i class="fas fa-chevron-right"></i>
//                                                                     </Link>
//                                                                 </div>

//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </>
//                                             )
//                                         })
//                                     }
//                                 </>
//                             )
//                         }
//                     </div>
//                     {/* <div className="row bog_team">
//                         {OverViewPageDetails?.multipleImages?.length > 0 && (
//                             OverViewPageDetails?.multipleImages
//                                 ?.map((image, index) => (
//                                     <div className="container my-5">
//                                         <div className="row">
//                                             <div className="col-12">

//                                                 <div className="event-item" key={index}>

//                                                     <div className="event-caption mb-2">
//                                                         <h5>{ReactHtmlParser(image?.title)}</h5>
//                                                     </div>


//                                                     <div className="event-images mb-3">
//                                                         {image?.images && image?.images.length > 0 ? (
//                                                             <div className="event_grid">
//                                                                 {image?.images?.map((image, imgIndex) => (
//                                                                     <div key={imgIndex} className="image-wrapper mb-2">
//                                                                         <img
//                                                                             src={`${imagebaseurl}/${image}`}
//                                                                             alt={`Event Image ${imgIndex + 1}`}
//                                                                             className="img-fluid"
//                                                                             style={{
//                                                                                 width: imgIndex === 0 ? '300px' : '300px',
//                                                                                 height: '250px',
//                                                                                 marginRight: imgIndex === 0 ? '0' : '10px',
//                                                                                 objectFit: 'cover',
//                                                                                 cursor: 'pointer',
//                                                                             }}
//                                                                             onClick={() => handleImageClick(imgIndex)}
//                                                                         />
//                                                                     </div>
//                                                                 ))}
//                                                             </div>
//                                                         ) : (
//                                                             <p>No images available</p>
//                                                         )}
//                                                     </div>
//                                                 </div>


//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))
//                         )
//                         }
//                     </div> */}
//                     <div className="row initiative_box mt-5">
//                         {
//                             OverViewdata?.docs?.length > 0 && (
//                                 <>
//                                     <h4>Please click on the view button to view the messages:</h4>
//                                     {
//                                         OverViewdata?.docs?.map((OverviewDocResult) => {
//                                             return (
//                                                 <>
//                                                     <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6">
//                                                         <div class="courses-item">
//                                                             <div class="content">
//                                                                 <div className="d-flex justify-content-between align-items-center">
//                                                                     <h5>{OverviewDocResult?.fileName}</h5>
//                                                                     <FaFilePdf size={60} color='rgb(120 155 194)' />
//                                                                 </div>
//                                                                 <div onClick={() => handleClick(OverviewDocResult?.fileUrl, OverviewDocResult?.fileName)} >
//                                                                     <Link to='#' class="text-decoration-none pharmabtn"
//                                                                     >
//                                                                         View
//                                                                         <i class="fas fa-chevron-right"></i>
//                                                                     </Link>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </>
//                                             )
//                                         })
//                                     }
//                                 </>
//                             )
//                         }
//                     </div>





//                 </div>
//             </div>
//             {showTopIcon ? (
//                 <Link
//                     id="scrollUp"
//                     to="#"
//                     onClick={scrollToTop}
//                     style={{
//                         position: "fixed",
//                         bottom: "20px",
//                         right: "20px",
//                         zIndex: "2147483647",
//                         backgroundColor: "red",
//                         color: "#fff",
//                         padding: "10px",
//                         borderRadius: "50%",
//                         textAlign: "center",
//                         boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
//                     }}
//                 >
//                     <TiArrowUp style={{ fontSize: "24px" }} />
//                 </Link>
//             ) : (
//                 <Link
//                     id="scrollBottom"
//                     to="#"
//                     onClick={scrollToBottom}
//                     style={{
//                         position: "fixed",
//                         bottom: "20px",
//                         right: "20px",
//                         zIndex: "2147483647",
//                         backgroundColor: "red",
//                         color: "#fff",
//                         padding: "10px",
//                         borderRadius: "50%",
//                         textAlign: "center",
//                         boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
//                     }}
//                 >
//                     <TiArrowDown style={{ fontSize: "24px" }} />
//                 </Link>
//             )}


//             {/* <Trained /> */}
//             <Footer />
//         </div >
//     )
// }

// export default Overview


import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";
import backgroundImage from '../../assets/images/444.png'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { PostOverTabsData } from '../../api/Global';
import { FaFilePdf } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import imagebaseurl from '../../config/imageurl';
import { TiArrowUp, TiArrowDown } from "react-icons/ti";
import { Accordion } from "react-bootstrap";
import moment from 'moment/moment';


const Overview = () => {
    const navigate = useNavigate();
    const id = useParams();
    const name = useParams();
    console.log(name);
    
    const Overview_id = id?._id;
    console.log("Overview_id", Overview_id)
    const [OverViewdata, setOverViewdata] = useState([]);
    const [OverViewPageDetails, setOverViewPageDetails] = useState({});
    console.log("OverViewPageDetails", OverViewPageDetails)
    const [activeKey, setActiveKey] = useState("");
    const GetselectedCategoryName = localStorage.getItem("selectedSubCategoryName")
    console.log("GetselectedCategoryName", GetselectedCategoryName)
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const GetTheOverViewData = async () => {
        try {
            if (Overview_id) {
                const response = await PostOverTabsData(Overview_id)
                setOverViewdata(response?.data?.data)
            }
            // console.log('ererere',response?.data?.data);

        }
        catch (error) {
            console.log("error", error)
        }
    }
    console.log("GetTheOverViewData", OverViewdata)

    const HandleActiveTabs = (id) => {
        setActiveKey(id);
        GetOverDataDetails(id)
    }

    const GetOverDataDetails = async (id) => {
        try {
            const response = await PostOverTabsData(id);
            setOverViewPageDetails(response?.data?.data);
        }
        catch (error) {
            console.log("error", error);
        }
    }
    console.log("GetOverDataDetails", OverViewPageDetails)
    useEffect(() => {
        GetTheOverViewData();
    }, [Overview_id])

    useEffect(() => {
        GetOverDataDetails(activeKey);
    }, [activeKey])

    useEffect(() => {
        const savedActiveTabId = localStorage.getItem('ActiveTabs');
        if (savedActiveTabId) {
            setActiveKey(savedActiveTabId);
        } else if (OverViewdata && OverViewdata?.tabs?.length > 0) {
            setActiveKey(OverViewdata?.tabs[0]?._id);
        }
    }, [OverViewdata]);


    const handleClick = (overviewDoc, pdftitle) => {
        localStorage.setItem('selectedDoc', overviewDoc);
        localStorage.setItem('selectedDoc_title', pdftitle);
        navigate('/More_Pdf_Open');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

   const handleImageClick = async (index) => {
        // setLoading(true); 
        const imageToLoad = new Image();
        imageToLoad.src = images[index];


        imageToLoad.onload = () => {
            setLoading(false);
            setPhotoIndex(index);
            setIsOpen(true);
        };


        imageToLoad.onerror = () => {
            setLoading(false);
            console.error('Error loading image:', images[index]);
            alert('Failed to load image, please try again later.');
        };
    };
    const [showTopIcon, setShowTopIcon] = useState(false);

    // Scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Scroll to bottom
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    };

    // Handle scroll to toggle visibility of buttons
    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = document.documentElement.clientHeight;

        if (scrollTop === 0) {
            setShowTopIcon(false); // At the top, show scroll-down button
        } else if (scrollTop + clientHeight >= scrollHeight) {
            setShowTopIcon(true); // At the bottom, show scroll-up button
        } else {
            setShowTopIcon(scrollTop > 0); // Show scroll-up button when scrolling
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const sortedData = OverViewPageDetails?.multipleImages
        ?.filter((imgData) => imgData?.images?.length > 0)
        .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting in Descending Order

    return (
        Overview_id && <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
         e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
            <Header />
            < section className="about-breadcrumb breadcumb-bg"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '60vh',
                    backgroundAttachment: 'fixed',

                }}>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <ul className="breadcrumb">
                                </ul>
                                <h2 className="heading-title">{GetselectedCategoryName}</h2>          
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className='row text-center m-3 '>

                {
                    OverViewdata?.tabs?.map((OverViewdataResult, index) => {
                        return (
                            <div key={index} className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
                                <div className={`p-2 m-1 ${activeKey == OverViewdataResult?._id ? "active-tab1" : "inactive-tab1"}`}
                                    onClick={() => {
                                        HandleActiveTabs(OverViewdataResult?._id)
                                        localStorage.setItem("ActiveTabs", OverViewdataResult?._id)
                                    }}>
                                    <p>{OverViewdataResult?.name}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="py-5">
                <div className='container'>

                    <div>
                        {OverViewPageDetails?.blogs?.map((OverviewBlogDeatilsResult, index) => {
                            return (
                                <div key={index} className="blog-content-container">
                                    {ReactHtmlParser(OverviewBlogDeatilsResult?.content)}
                                </div>
                            );
                        })}
                    </div>

                    <div>
                        {
                            OverViewdata?.blogs?.map((OverViewdataBlogsResult) => {
                                return (
                                    <>
                                        <span>{ReactHtmlParser(OverViewdataBlogsResult?.content)}</span>
                                    </>
                                )
                            })
                        }
                    </div>

                    <div className="row bog_team">
                        {
                            OverViewPageDetails?.BOG?.length > 0 && (
                                <>
                                    {
                                        OverViewPageDetails?.BOG?.sort((a, b) => {
                                            if (a.designation_position === null) return 1;
                                            if (b.designation_position === null) return -1;
                                            return a.designation_position - b.designation_position;
                                        })
                                            .map((OverViewBlogDetails) => {
                                                return (
                                                    <>
                                                        {
                                                            OverViewBlogDetails?.pdfFile != null ? (
                                                                <div class="col-md-4 col-12" style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
                                                                    <div class="team-card style2">
                                                                        <div class="team-img-wrap">
                                                                            <div class="team-img"><img
                                                                                src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
                                                                                alt="Team" /></div>
                                                                        </div>
                                                                        <div class="team-content">
                                                                            <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
                                                                            <p>{OverViewBlogDetails?.companyName}</p>
                                                                            {/* <p>{OverViewBlogDetails?.designation_position}</p> */}
                                                                            <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div class="col-md-4 col-12 d-flex">
                                                                        <div class="team-card style2 dkkdkkd w-100">
                                                                            <div class="team-img-wrap">
                                                                                <div class="team-img"><img
                                                                                    src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
                                                                                    alt="Team" /></div>
                                                                            </div>
                                                                            <div class="team-content">
                                                                                <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
                                                                                <p>{OverViewBlogDetails?.companyName}</p>
                                                                                {/* <p>{OverViewBlogDetails?.designation_position}</p> */}
                                                                                <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </>
                                                )
                                            })
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="row bog_team">
                        {
                            OverViewdata?.BOG?.length > 0 && (
                                <>
                                    {
                                        OverViewdata?.BOG?.sort((a, b) => {
                                            if (a.designation_position === null) return 1;
                                            if (b.designation_position === null) return -1;
                                            return a.designation_position - b.designation_position;
                                        })

                                            .map((OverViewBlogDetails) => {
                                                return (
                                                    <>
                                                        {
                                                            OverViewBlogDetails?.pdfFile != null ? (

                                                                <div class="col-md-4 col-12" style={{ cursor: "pointer" }} onClick={() => handleClick(OverViewBlogDetails?.pdfFile, OverViewBlogDetails?.name)}>
                                                                    <div class="team-card style2">
                                                                        <div class="team-img-wrap">
                                                                            <div class="team-img"><img
                                                                                src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
                                                                                alt="Team" /></div>
                                                                        </div>
                                                                        <div class="team-content">
                                                                            <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
                                                                            <p>{OverViewBlogDetails?.companyName}</p>
                                                                            <p>{OverViewBlogDetails?.designation_position}</p>
                                                                            <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ) : (
                                                                <>
                                                                    <div class="col-md-4 col-12">
                                                                        <div class="team-card style2">
                                                                            <div class="team-img-wrap">
                                                                                <div class="team-img"><img
                                                                                    src={`${imagebaseurl}/${OverViewBlogDetails?.imageLink}`}
                                                                                    alt="Team" /></div>
                                                                            </div>
                                                                            <div class="team-content">
                                                                                <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
                                                                                <p>{OverViewBlogDetails?.companyName}</p>
                                                                                <p>{OverViewBlogDetails?.designation_position}</p>
                                                                                <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </>
                                                )
                                            })
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="row bog_team">
                        {OverViewPageDetails?.multipleImages?.length > 0 ? (
                            <Accordion defaultActiveKey="0"> {/* 👈 First accordion item open by default */}
                                {OverViewPageDetails.multipleImages
                                    .filter((imgData) => imgData?.images?.length > 0)
                                    .sort((a, b) => new Date(b.date) - new Date(a.date)) // 👈 Sorting by Date (Latest First)
                                    .map((imgData, index) => (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            {/* Accordion Header */}
                                            <Accordion.Header>
                                                <div className="event-caption d-flex align-items-center">
                                                    {/* First Image */}
                                                    <img
                                                        src={`${imagebaseurl}/${imgData.images[0]}`}
                                                        alt="Event Thumbnail"
                                                        style={{
                                                            width: "150px",
                                                            height: "150px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                    {/* Title & Date */}
                                                    <div className="event_title_info">
                                                        <div className="metaevent-time">
                                                            Event: {moment(imgData.date).format("DD MMMM YYYY")}
                                                        </div>
                                                        <h5>{ReactHtmlParser(imgData?.title)}</h5>
                                                    </div>
                                                </div>
                                            </Accordion.Header>

                                            {/* Accordion Body (Images Section) */}
                                            <Accordion.Body>
                                                <div className="event-images mb-3">
                                                    <div className="event_grid">
                                                        {imgData.images.map((imgSrc, imgIndex) => (
                                                            <div key={imgIndex} className="image-wrapper mb-2">
                                                                <a
                                                                    href={`${imagebaseurl}/${imgSrc}`}
                                                                    data-fancybox={`gallery-${index}`}
                                                                    data-caption={`Event Image ${imgIndex + 1}`}
                                                                >
                                                                    <img
                                                                        src={`${imagebaseurl}/${imgSrc}`}
                                                                        alt={`Event Image ${imgIndex + 1}`}
                                                                        className="img-fluid"
                                                                        style={{
                                                                            width: "300px",
                                                                            height: "250px",
                                                                            marginRight: "10px",
                                                                            objectFit: "cover",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        ) : (
                            <p></p>
                        )}
                    </div>
                    <div className="row bog_team">
                        {OverViewPageDetails?.gallery?.length > 0 && (
                            OverViewPageDetails?.gallery?.map((image, index) => (
                                <div className="col-12 col-md-3 col-lg-4 mt-3">
                                    <div className="card" key={index}>
                                        <div className="card-image">
                                            {
                                                image?.mediaType === "video" ? (
                                                    <>
                                                        <video
                                                            controls
                                                            className="photo-item__video NewsDetailsVideos"
                                                            muted
                                                            style={{
                                                                width: "90%",
                                                                height: "500px",
                                                                objectFit: "contain",
                                                                display: "block",
                                                                margin: "0 auto"
                                                            }}
                                                        >
                                                            <source src={`${imagebaseurl}/${image?.galleryVideo}`} type="video/mp4" />
                                                        </video>
                                                        <h6>{image?.galleryName}</h6>

                                                    </>

                                                ) : (
                                                    <>
                                                        <Link className='leb-setup' to={`${imagebaseurl}/${image?.galleryImage}`} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
                                                            <img className='img-fluid' src={`${imagebaseurl}/${image?.galleryImage}`} alt={image.alt || `Image ${index + 1}`}

                                                            />
                                                        </Link>
                                                        <h6>{image?.galleryName}</h6>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                        }
                    </div>
                    <div className="row bog_team">
                        {OverViewdata?.gallery?.length > 0 && (
                            OverViewdata?.gallery?.map((image, index) => (
                                <div className="card col-4 mt-3" key={index}>
                                    <div className="card-image">
                                        {
                                            image?.mediaType === "video" ? (
                                                <>
                                                    <video
                                                        // autoPlay
                                                        controls
                                                        className="photo-item__video NewsDetailsVideos"
                                                        muted
                                                        style={{
                                                            width: "90%",
                                                            height: "500px",
                                                            objectFit: "contain",
                                                            display: "block",
                                                            margin: "0 auto"
                                                        }}
                                                    >
                                                        <source src={`${imagebaseurl}/${image?.galleryVideo}`} type="video/mp4" />
                                                    </video>
                                                    <h6>{image?.galleryName}</h6>
                                                </>

                                            ) : (
                                                <>
                                                    <Link to={`${imagebaseurl}/${image?.galleryImage}`} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
                                                        <img src={`${imagebaseurl}/${image?.galleryImage}`} alt={image.alt || `Image ${index + 1}`}
                                                            style={{ height: "50vh", width: "90%" }}
                                                        />
                                                    </Link>
                                                    <h6>{image?.galleryName}</h6>
                                                </>
                                            )
                                        }
                                        {/* <h4>{image?.galleryName}</h4> */}
                                    </div>
                                </div>
                            ))
                        )
                        }
                    </div>
                    <div className="row initiative_box">
                        {
                            OverViewPageDetails?.docs?.length > 0 && (
                                <>
                                    <h4>Please click on the view button to view the messages:</h4>
                                    {
                                        OverViewPageDetails?.docs?.map((OverviewDocResult) => {
                                            return (
                                                <>
                                                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6">
                                                        <div class="courses-item">
                                                            <div class="content">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5>{OverviewDocResult?.fileName}</h5>
                                                                    <FaFilePdf size={60} color='rgb(120 155 194)' />
                                                                </div>
                                                                <div onClick={() => handleClick(OverviewDocResult?.fileUrl, OverviewDocResult?.fileName)} >
                                                                    <Link to='#' class="text-decoration-none pharmabtn"
                                                                    >
                                                                        View
                                                                        <i class="fas fa-chevron-right"></i>
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="row initiative_box mt-5">
                        {
                            OverViewdata?.docs?.length > 0 && (
                                <>
                                    <h4>Please click on the view button to view the messages:</h4>
                                    {
                                        OverViewdata?.docs?.map((OverviewDocResult) => {
                                            return (
                                                <>
                                                    <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6">
                                                        <div class="courses-item">
                                                            <div class="content">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5>{OverviewDocResult?.fileName}</h5>
                                                                    <FaFilePdf size={60} color='rgb(120 155 194)' />
                                                                </div>
                                                                <div onClick={() => handleClick(OverviewDocResult?.fileUrl, OverviewDocResult?.fileName)} >
                                                                    <Link to='#' class="text-decoration-none pharmabtn"
                                                                    >
                                                                        View
                                                                        <i class="fas fa-chevron-right"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            {showTopIcon ? (
                <Link
                    id="scrollUp"
                    to="#"
                    onClick={scrollToTop}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        zIndex: "2147483647",
                        backgroundColor: "red",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "50%",
                        textAlign: "center",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <TiArrowUp style={{ fontSize: "24px" }} />
                </Link>
            ) : (
                <Link
                    id="scrollBottom"
                    to="#"
                    onClick={scrollToBottom}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        zIndex: "2147483647",
                        backgroundColor: "red",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "50%",
                        textAlign: "center",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <TiArrowDown style={{ fontSize: "24px" }} />
                </Link>
            )}
            <Footer />
        </div >
    )
}

export default Overview

