import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../home/Home'
import Campus from '../home/Campus'
import Banners from '../home/Banners'
import Gallery from '../home/Gallery'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Header from '../header/Header'
// import Trained from '../Trained/Trained'
import Cdip_Faculty from '../cdip/Cdip_Faculty'
import Faculty_quarters from '../Facilities/Faculty_quarters.js'
import Cdip_Program from '../cdip/Cdip_Program'
import Central_Library from '../cdip/Central_Library'
import Department_Engineering from '../department/Department_Engineering.js'
import Computer_Science from '../department/Computer_Science.js'
import Mechanical_Eng from '../department/Mechanical_Eng.js'
import Facilities from '../Facilities/Facilities.js'
import Information_Technology from '../Information Technology/Information_Technology.js'
import Indoor_outdoor_games from '../Information Technology/Indoor_outdoor_games.js'
import Naac_as from '../naacas/Naac_as.js'
import Overview from '../Overview/Overview.js'
import Computer_Science_Information_Technology from '../department/Computer_Science_Information_Technology.js'
import Artificial_Intelligence_Data_Science from '../department/Artificial_Intelligence_Data_Science'
// import Electronics_and_Telecommunication from '../department/Electronics_and_Telecommunication'
import Dept_of_mgmt from '../department/Dept_of_mgmt'
import Mba_core from '../mba/Mba_core'
import Mba_marketing_mgmt from '../mba/Mba_marketing_mgmt.js'
import Mba_Financial_Admin from '../mba/Mba_Financial_Admin.js'
import Cdil_ba_llb_hons from '../cdil/Cdil_ba_llb_hons'
import Cdil_bba_llb_hons from '../cdil/Cdil_bba_llb_hons'
import Cdil_llb_hons from '../cdil/Cdil_llb_hons'
import Campus_life from '../cdil/Campus_life'
import Gymnasium_swimming_pool from '../pool/Gymnasium_swimming_pool.js'
import Hostel_canteen_facilities from '../hostel/Hostel_canteen_facilities.js'
import Placement from '../placement/Placement.js'
import Smart_classrooms from '../smart-class/Smart_classrooms.js'
import Transportation_facility from '../transportation-facility/Transportation_facility.js'
import Well_equipped_laboratories from '../well-equipped/Well_equipped_laboratories.js'
import Search_bar from '../searchbar/Search_bar.js'
import Bba_plain_overview from '../bba/Bba_plain_overview.js'
import Bca_overview from '../bca/Bca_overview.js'
import Bcom_ca_overview from '../bcom/Bcom_ca_overview.js'
import Admission_mba from '../admission/Admission_mba.js'
import Apply_for_job from '../apply-for/Apply_for_job.js'
import Awsacademy from '../awsacademy/Awsacademy.js'
import Cdgi_bosch from '../cdgi/Cdgi_bosch.js'
import Cdgi_icecsd from '../cdgi/Cdgi_icecsd.js'
import Cdgi_ncspc from '../cdgi/Cdgi_ncspc.js'
import Cdips_mba from '../cdips/Cdips_mba.js'
import Cdips_nc2 from '../cdips/Cdips_nc2.js'
import Covid_events from '../covid/covid_events.js'
import Department_of_master_of_computer_application from '../department/Department_of_master_of_computer_application.js'
import Extra_curricular_activities from '../extra/extra_curricular_activities.js'
import Feepayment from '../Facilities/Feepayment.js'
import Gate_achievers from '../gate-achievers/Gate_achievers.js'
import Iqac from '../iqac/Iqac.js'
import Login from '../login/login.js'
import Naac from '../naacas/Naac.js'
import Antiragging from '../about/Antiragging.js'
import Cdgi_icicrtc from '../cdgi/Cdgi_icicrtc.js'
import Cdgi_nptel from '../cdgi/Cdgi_nptel.js'
import Cdip_ac from '../cdip/Cdip_ac.js'
import Cdip_ah from '../cdip/Cdip_ah.js'
import Cdip_pc from '../cdip/Cdip_pc.js'
import Cdip_ra from '../cdip/Cdip_ra.js'
import Cdip_rf from '../cdip/Cdip_rf.js'
import Cdipmandatory_disclosures from '../cdip/Cdipmandatory_disclosures.js'
import Cea from '../cdips/Cea.js'
import Csa from '../cdips/Csa.js'
import Ctdc from '../cdips/Ctdc.js'
import Eca from '../extra/Eca.js'
import Ecell from '../extra/Ecell.js'
import Electronics_and_telecommunication from '../extra/Electronics_and_telecommunication.js'
import Innovation_cdgi from '../Information Technology/Innovation_cdgi.js'
import Institute_com from '../Information Technology/Institute_com.js'
import Ishrae from '../iqac/Ishrae.js'
import Isse from '../iqac/Isse.js'
import Iste from '../iqac/Iste.js'
import Ita from '../iqac/Ita.js'

import Mea from '../mba/Mea.js'
import Mandatory_disclosures from '../mba/Mandatory_disclosures.js'
import Microsoftadv from '../mba/Microsoftadv.js'
import Movie from '../mba/Movie.js'
import Nirmaan from '../naacas/Nirmaan'
import Office from '../Overview/Office'
import Saath from '../saath/Saath.js'
import Sae from '../saath/Sae.js'
import Sea from '../saath/Sea.js'
import Sports from '../saath/Sports.js'
import Stua from '../saath/Stua.js'
import Stut from '../saath/Stut.js'
import Tie_ups from '../transportation-facility/Tie_ups.js'
import Wdc from '../well-equipped/Wdc.js'
import Yi from '../well-equipped/Yi.js'
import Cmc from '../cdips/Cmc.js'
import Contact_us from '../contact_us/Contact_us.js'
import Visionmission from '../about/Visionmission.js'
import Pdf_Open from '../pdf_open/Pdf_Open.js'
import Feedback from '../feedback/Feedback.js'
import GalleryMore from '../gallery/Gallery.js'
import More_Pdf_Open from '../more_pdf_open/More_Pdf_Open.js'
import Campus_Life_Details from '../campus_life_details/Campus_Life_Details.js'
import About_Institutions from '../about_institutions/About_Institutions.js'
import News_And_Events from '../news_and_events/News_And_Events.js'
import Mous_Tie from '../mous_tie/Mous_Tie.js'
import AntiRangging from '../antirangging/AntiRangging.js'
import Student_Testimonial from '../student_testimonial/Student_Testimonial.js'
import Ugc_2f from '../Ugc_2f/Ugc_2f.js'
import Bus from '../bus/Bus.js'
import Mandatory_Disclosures from '../Mandatory_Disclosures/Mandatory_Disclosures.js'
import Mandatory_Disclosures_pdf from '../Mandatory_Disclosures_pdf/Mandatory_Disclosures_pdf.js'
import Icc from '../icc/Icc.js'
import Fees_Structure_Pdf from '../Fees_structure_pdf/Fees_Structure_Pdf.js'
import StudentInfraStructure from '../feedback/StudentInfraStructure.js'
import Advertise from '../advertise/Advertise.js'
import Privacypolicymobile from '../privacypolicy/Privacypolicymobile.js'
import Terms_condition from '../terms/Terms_condition.js'
const Router = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/Campus' element={<Campus />} />
        <Route path='/Banners' element={<Banners />} />
        <Route path='/Navbar' element={<Navbar />} />
        <Route path='/Footer' element={<Footer />} />
        <Route path='/Header' element={<Header />} />
        {/* <Route path='/Search_bar' element={<Search_bar />} /> */}

        {/* << about.............. */}
        <Route path='/Antiragging' element={<Antiragging />} />
        <Route path='/Visionmission' element={<Visionmission />} />


        {/* ............cdip............ */}
        <Route path='/Cdip_Faculty' element={<Cdip_Faculty />} />
        <Route path='/Cdip_Program' element={<Cdip_Program />} />
        <Route path='/Central_Library' element={<Central_Library />} />
        <Route path='/Cdip_ac' element={<Cdip_ac />} />
        <Route path='/Cdip_ah' element={<Cdip_ah />} />
        <Route path='/Cdip_pc' element={<Cdip_pc />} />
        <Route path='/Cdip_ra' element={<Cdip_ra />} />
        <Route path='/Cdip_rf' element={<Cdip_rf />} />
        <Route path='/Cdipmandatory_disclosures' element={<Cdipmandatory_disclosures />} />
        {/* .........cdil........ */}
        <Route path='/Cdil_ba_llb_hons' element={<Cdil_ba_llb_hons />} />
        <Route path='/Cdil_bba_llb_hons' element={<Cdil_bba_llb_hons />} />
        <Route path='/Cdil_llb_hons' element={<Cdil_llb_hons />} />
        <Route path='/Campus_life' element={<Campus_life />} />

        {/* Department */}
        <Route path='/Departmet_Engineering' element={<Department_Engineering />} />
        {/* <Route path='Department_Engineering' element={<Department_Engineering/>} />                                                                                                                                                                          ' element={<Department_Engineering/>}  />     */} */}
        <Route path='/Computer_Science' element={<Computer_Science />} />
        <Route path='/Mechanical_Eng' element={<Mechanical_Eng />} />
        <Route path='/Computer_Science_Information_Technology' element={<Computer_Science_Information_Technology />} />
        <Route path='/Artificial_Intelligence_Data_Science' element={<Artificial_Intelligence_Data_Science />} />
        {/* <Route path='Electronics_and_Telecommunication' element={<Electronics_and_Telecommunication />} /> */}
        <Route path='/Dept_of_mgmt' element={<Dept_of_mgmt />} />
        <Route path='/Department_of_master_of_computer_application' element={<Department_of_master_of_computer_application />} />

        {/* ...............mba..................... */}
        <Route path='/Mba_core' element={<Mba_core />} />
        <Route path='/Mba_marketing_mgmt' element={<Mba_marketing_mgmt />} />
        <Route path='/Mba_Financial_Admin' element={<Mba_Financial_Admin />} />
        <Route path='/Mea' element={<Mea />} />
        {/* <Route path='/Mandatory_disclosures' element={<Mandatory_disclosures />} /> */}
        <Route path='/Microsoftadv' element={<Microsoftadv />} />
        <Route path='/Movie' element={<Movie />} />


        {/* Facilities */}

        <Route path='/Facilities' element={<Facilities />} />
        <Route path='/Faculty_quarters' element={<Faculty_quarters />} />
        <Route path='/Feepayment' element={<Feepayment />} />

        {/* <Route path='/Trained' element={<Trained />} /> */}

        <Route path='/Information_Technology' element={<Information_Technology />} />
        <Route path='/Indoor_outdoor_games' element={<Indoor_outdoor_games />} />
        <Route path='/Innovation_cdgi' element={<Innovation_cdgi />} />
        <Route path='/Institute_com' element={<Institute_com />} />

        

        {/* <Route path='/Overview/:_id' element={<Overview />} /> */}
        <Route path='/Overview/:_id/:slug' element={<Overview />} />
        <Route path='/Office' element={<Office />} />


        {/* ................. pool........................ */}
        <Route path='/Gymnasium_swimming_pool' element={<Gymnasium_swimming_pool />} />

        {/* .................. HOstel......................... */}
        <Route path='/Hostel_canteen_facilities' element={<Hostel_canteen_facilities />} />
        <Route path='/Placement' element={<Placement />} />
        {/* ....................Smart.................. */}
        <Route path='/Smart_classrooms' element={<Smart_classrooms />} />

        <Route path='/Transportation_facility' element={<Transportation_facility />} />
        <Route path='/Tie_ups' element={<Tie_ups />} />


        <Route path='/Well_equipped_laboratories' element={<Well_equipped_laboratories />} />
        <Route path='/Wdc' element={<Wdc />} />
        <Route path='/Yi' element={<Yi />} />

        {/* ............................Bba............... */}
        <Route path='/Bba_plain_overview' element={<Bba_plain_overview />} />
        <Route path='/Bca_overview' element={<Bca_overview />} />
        <Route path='/Bcom_ca_overview' element={<Bcom_ca_overview />} />

        {/* naacas */}
        <Route path='/Naac' element={<Naac />} />
        <Route path='/Nirmaan' element={<Nirmaan />} />
        <Route path='/Naac_as' element={<Naac_as/>} />
        {/* Admission */}
        <Route path='/Admission_mba' element={<Admission_mba />} />
        {/* Apply_for */}
        <Route path='/Apply_for_job' element={<Apply_for_job />} />
        <Route path='/Awsacademy' element={<Awsacademy />} />
        {/* cdgi */}
        <Route path='/Cdgi_bosch' element={<Cdgi_bosch />} />
        <Route path='/Cdgi_icecsd' element={<Cdgi_icecsd />} />
        <Route path='/Cdgi_ncspc' element={<Cdgi_ncspc />} />
        <Route path='/Cdgi_icicrtc' element={<Cdgi_icicrtc />} />
        <Route path='/Cdgi_nptel' element={<Cdgi_nptel />} />
        {/* Cdips */}

        <Route path='/Cea' element={<Cea />} />
        <Route path='/Cdips_mba' element={<Cdips_mba />} />
        <Route path='/Cdips_nc2' element={<Cdips_nc2 />} />
        <Route path='/Csa' element={<Csa />} />
        <Route path='/Ctdc' element={<Ctdc />} />
        <Route path='/Cmc' element={<Cmc />} />


        {/* Covid */}
        <Route path='/Covid_events' element={<Covid_events />} />
        {/* Extra */}
        <Route path='/Extra_curricular_activities' element={<Extra_curricular_activities />} />
        <Route path='/Eca' element={<Eca />} />
        <Route path='/Ecell' element={<Ecell />} />
        <Route path='/Electronics_and_telecommunication' element={<Electronics_and_telecommunication />} />
        {/* ......................gate.......................... */}
        <Route path='/Gate_achievers' element={<Gate_achievers />} />
        {/* Iqac */}
        <Route path='/Iqac' element={<Iqac />} />
        <Route path='/Ishrae' element={<Ishrae />} />
        <Route path='/Isse' element={<Isse />} />
        <Route path='/Iste' element={<Iste />} />
        <Route path='/Ita' element={<Ita />} />
        {/* login */}
        <Route path='/Login' element={<Login />} />
        {/* saath */}
        <Route path='/Saath' element={<Saath />} />
        <Route path='/Sae' element={<Sae />} />
        <Route path='/Sea' element={<Sea />} />
        <Route path='/Sports' element={<Sports />} />
        <Route path='/Stua' element={<Stua />} />
        <Route path='/Stut' element={<Stut />} />
        <Route path='/Contact_us'element={<Contact_us/>}/>
        <Route path='/Pdf_Open/:_id/:slug'element={<Pdf_Open/>}/>
        <Route path='/Feedback'element={<Feedback/>}/>
        <Route path='/Gallery_More'element={<GalleryMore/>}/>
        <Route path='/More_Pdf_Open'element={<More_Pdf_Open/>}/>
        <Route path='/Campus_Life_Details/:_id'element={<Campus_Life_Details/>}/>
        <Route path='/About_Institutions' element={<About_Institutions/>}/>
        <Route path='/News_And_Events' element={<News_And_Events/>}/>
        <Route path='/Mous_Tie' element={<Mous_Tie/>}/>
        <Route path='/AntiRangging' element={<AntiRangging/>}/>
        <Route path='/Student_Testimonial' element={<Student_Testimonial/>}/>
        <Route path='/Ugc_2f' element={<Ugc_2f/>}/>
        <Route path='/Bus' element={<Bus/>}/>
        <Route path='/Mandatory_Disclosures' element={<Mandatory_Disclosures/>}/>
        <Route path='/Icc' element={<Icc/>}/>
        <Route path='/Mandatory_Disclosures/Mandatory_Disclosures_pdf' element={<Mandatory_Disclosures_pdf/>}/>
        <Route path='/Privacypolicymobile'element={<Privacypolicymobile/>}/>
        <Route path='/Terms_condition'element={<Terms_condition/>}/>
        <Route path='/Fees_Structure_Pdf' element={<Fees_Structure_Pdf/>}/>
     
        <Route path='/Advertisement' element={<Advertise/>}/>
      
      </Routes>


    </div>
  )
}

export default Router
