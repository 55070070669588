// import React, { useState, useEffect } from "react";
// import Header from "../header/Header";
// import { Getalllegaldoc } from "../../api/Global";
// import backgroundImage from "../../assets/images/444.png";
// import ReactHtmlParser from "react-html-parser";



// const Privacypolicymobile = () => {
//   const [placementList, setPlacementList] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
// //   const [placement, setPlacement] = useState([]);

//   const HandleGetalllegaldoc = async () => {
//     setIsLoading(true);
//     try {
//       const response = await Getalllegaldoc();
//       console.log("HandleGetalllegaldoc", response?.data?.data?.tabs);
//       setPlacementList(response?.data?.data);
//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(true);
//     }
//   };
//   useEffect(() => {
//     HandleGetalllegaldoc();
//     window.scrollTo(0, 0);
//   }, []);


//   return (
//     <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//     page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
//     e--ua-chrome e--ua-webkit loaded"
//       data-elementor-device-mode="desktop">
//       <Header />
//       {/* Breadcrumb Section */}
//       <section
//         className="about-breadcrumb breadcumb-bg"
//         style={{
//           backgroundImage: `url(${backgroundImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           width: "100%",
//           height: "400px",
//         }}
//       >
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="breadcrumb-content">
//                 <h2 className="heading-title">Privacy Policy</h2>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
      
//       <h2>{data.privacy.title}</h2>

   
    





      
//     </div>

//   )
// }

// export default Privacypolicymobile



import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { Getalllegaldoc } from "../../api/Global";
import backgroundImage from "../../assets/images/444.png";
import ReactHtmlParser from "react-html-parser";
import Footer from "../footer/Footer";

const Privacypolicymobile = () => {
  const [placementList, setPlacementList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const HandleGetalllegaldoc = async () => {
    setIsLoading(true);
    try {
      const response = await Getalllegaldoc();
      // console.log("API Response:", response?.data?.data);
      setPlacementList(response?.data?.data?.privacy );
    } catch (error) {
      console.error("Error fetching privacy policy:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    HandleGetalllegaldoc();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
      page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
      e--ua-chrome e--ua-webkit loaded"
      data-elementor-device-mode="desktop"
    >
      <Header />
      {/* Breadcrumb Section */}
      <section
        className="about-breadcrumb breadcumb-bg"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="heading-title">Privacy And Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-5">
        {isLoading ? (
          <p>Loading...</p>
        ) : placementList ? (
          <div>
            <h2>{placementList?.title}</h2>
            <div>{ReactHtmlParser(placementList?.content)}</div>
            <p><strong>Last Updated:</strong> {new Date(placementList.updatedAt).toLocaleDateString()}</p>
          </div>
        ) : (
          <p>No privacy policy available.</p>
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default Privacypolicymobile;
