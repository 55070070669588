import React,{useEffect} from 'react'
import bus from "../../assets/pdf/bus.pdf";
import Header from '../header/Header';
import Footer from '../footer/Footer';
const Bus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
     e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
            <Header />
            <div style={{ marginTop: "100px" }}>
                <div className='text-center'>

                    <h1>BUS ROUTE
                    </h1>
                </div>
                <iframe
                    src={`${bus}#toolbar=0&navpanes=0&scrollbar=0`}
                    type="application/pdf"
                    width="80%"
                    height="800px"
                    style={{
                        width: '100%',
                        border: 'none'
                    }}
                    title="PDF Viewer"
                />
            </div>
            <div className='mt-5'>
                <Footer />

            </div>
        </div>
  )
}

export default Bus