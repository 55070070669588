import React,{useEffect} from 'react'
import Header from '../header/Header'
import backgroundImage from '../../assets/images/444.png'
import aboutus from '../../assets/images/campus1.jpg'
// import Trained from '../Trained/Trained'
import Footer from '../footer/Footer'
const Icc = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded"
            data-elementor-device-mode="desktop">
            <Header />
            < section className="about-breadcrumb breadcumb-bg"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '400px'
                }}>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <ul className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item"><Link >/</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Internal Complaint Committee
                                    </li>
                                </ul>
                                <h2 className="heading-title">Internal Complaint Committee</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <div class="overview-area aboutus-vi">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="overview-content">
                                <div class="image">
                                    <img src={aboutus}
                                        alt="overview-image"/>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="overview-image aboutover">
                                <div class="title">
                                    <h2>
                                        Internal Complaints Committee (ICC)
                                    </h2>

                                    <p>In pursuance of UGC (Prevention, prohibition and redressal of sexual harassment
                                        of women employees and students in higher educational institutions) Regulations,
                                        2015 read with Sexual Harassment of Women at Workplace (Prevention, Prohibition
                                        and Redressal) Act, 2013 and in partial modification of Office Order No. 449
                                        dated 05.08.2016, Internal Complaints Committee (ICC) is re-constituted as under
                                        to deal with the complaints relating to Sexual harassment at work place.</p>

                                </div>

                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="overview-image aboutover m-0">
                                <div class="title">
                                    <h4>
                                        Office Order
                                    </h4>

                                    <p>The ICC comprises of the following members:</p>
                                    <div class="table-responsive">
                                        <table class="table table_hover sportstbl">
                                            <tbody>
                                                <tr>
                                                    <th width="54">
                                                        <p><strong>S. No.</strong></p>
                                                    </th>
                                                    <th width="186">
                                                        <p><strong>Member</strong></p>
                                                    </th>
                                                    <th width="228">
                                                        <p><strong>Name</strong></p>
                                                    </th>
                                                    <th width="156">
                                                        <p><strong>Position</strong></p>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td width="54">
                                                        <p>1</p>
                                                    </td>
                                                    <td width="186">
                                                        <p>Presiding Officer</p>
                                                    </td>
                                                    <td width="228">
                                                        <p>Dr. Preeti Kasliwal, Associate Professor</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Chairperson</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2" width="54">
                                                        <p>2</p>
                                                        <p>3</p>
                                                    </td>
                                                    <td rowspan="2" width="186">
                                                        <p>Two faculty members</p>
                                                    </td>
                                                    <td width="228">
                                                        <p>Prof. Kavita Jaimini, Assistant Professor, MBA</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="228">
                                                        <p>Prof. Madhu Sharma, Assistant Professor CSE</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2" width="54">
                                                        <p>4</p>
                                                        <p>5</p>
                                                    </td>
                                                    <td rowspan="2" width="186">
                                                        <p>Two Non-Teaching Employees</p>
                                                    </td>
                                                    <td width="228">
                                                        <p>Mr. Dharmendra Khanchandani, Admin Officer</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="228">
                                                        <p>Mr. Mukesh Chaubey CMC Incharge</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="54">
                                                        <p>6</p>
                                                    </td>
                                                    <td width="186">
                                                        <p>A member from NGO or a person familiar with sexual harassment
                                                            issues</p>
                                                    </td>
                                                    <td width="228">
                                                        <p>Ms. Vasudha Khargonkar</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3" width="54">
                                                        <p>7</p>
                                                    </td>
                                                    <td rowspan="3" width="186">
                                                        <p>Three Student nominees (if the matter involves students)</p>
                                                    </td>
                                                    <td width="228">
                                                        <p>Ms Teena Wadhwani</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="228">
                                                        <p>Ms Prachi Paliwal</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="228">
                                                        <p>Ms Bhavna Tikare</p>
                                                    </td>
                                                    <td width="156">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>*Mr. Dharmendra Khanchandani will act as the Member Secretary.</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <p><br/>
</p>

<ul>
  <p  style={{lineHeight:"26px",textAlign:"justify"}}>On receipt of a complaint ICC shall conduct preliminary enquiry so as to ascertain the truth of the allegations by collecting the documentary evidence as well as recording statements of any possible witnesses including the complainant. ICC shall then submit the preliminary enquiry report to Director/Disciplinary Authority along with all the original documents adduced during the preliminary enquiry proceedings. In case the allegations are not in the nature of sexual harassment, ICC may refer such complaints to the Grievance Redressal cell or to Registrar.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>Where sexual harassment occurs as a result of an act or omission by any third party or outsider, ICC shall take all steps necessary and reasonable to assist the affected person in terms of support and preventive action.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>ICC shall comply with the procedure prescribed in the aforementioned UGC Regulations 2015 and the Sexual Harassment Act for inquiring into the complaint in a time bound manner.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>If ICC concludes that the allegations made were false, malicious or the complaint was made knowing it to be untrue or forged or misleading information has been provided during the inquiry, the complainant shall be liable to be punished as per the relevant provision of the aforementioned UGC Regulations 2015.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>Member Secretary/Chairperson, ICC shall receive the complaints of sexual harassment, if any, on behalf of ICC and shall co-ordinate the deliberations of the ICC on the complaints received.</p>
  <h3 class="headingh3" style={{lineHeight:"1.5em"}}>Who can approach ICC for help?</h3>
  <li  style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
<div  style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}>Any female employee (faculty, student or staff) of CDGI, Indore, MP.</div>
  </li>
  <h3 class="headingh3"  style={{lineHeight:"1.5em"}}>Definition of Sexual Harassment:</h3>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>&quot;Sexual harassment&quot; includes any unwelcome sexually inclined behaviour, whether directly or indirectly, such as:</p>
  <ul>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Physical contact and advances</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Demand or request for sexual favours</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Sexually coloured remarks</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Showing any pornography, or</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Any other unwelcome physical, verbal or non-verbal conduct of sexual nature</li>
  </ul>
  <h3 class="headingh3" style={{lineHeight:"1.5em"}}>What are the possible actions that can be taken against the respondent?</h3>
  <ul>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Warning</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Written apology</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Bond of good behaviour</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Adverse remark in the Confidential Report</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Stopping of increments/promotion</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Suspension</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Dismissal</li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}>• Any other relevant actions</li>
  </ul>
  <h3 class="headingh3" style={{lineHeight:"1.5em"}}>If you think you are harassed or being harassed, what should you do?</h3>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>• Send an email to<a href="mailto:someone@example.com">chairperson.icc@cdgi.edu.in</a></p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>• You may also refer to<a href="http://www.shebox.nic.in/" target="_blank">http://www.shebox.nic.in</a></p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>• Your complaint will be kept<strong>CONFIDENTIAL</strong>.</p>
  <h3 class="headingh3" style={{lineHeight:"1.5em"}}>Inquiry process:</h3>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>The inquiry shall be completed within a period of ninety days from the date of the complaint.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>On completion of the inquiry, the ICC shall provide a report of its findings to the employer within a period of ten days from the date of completion of the inquiry and such report be made available to the concerned parties.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>If the allegation against the respondent has been proved, it shall recommend punitive actions to be taken against the respondent to the employer.</p>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>The employer shall act upon the recommendation within sixty days of receiving it.</p>
  <h3 class="headingh3" style={{lineHeight:"1.5em"}}>Useful Documents/Notices:</h3>
  <p style={{lineHeight:"26px",textAlign:"justify"}}>ICC Annual Report from April 2017 onwards:</p>
  <ul>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><a href="https://cdgi.edu.in/pdf/Annual_report_ICC_2017-18.pdf">• Annual Report ICC 2017-18</a></li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><a href="https://cdgi.edu.in/pdf/Annual_report_ICC_2018-19.pdf">• Annual Report ICC 2018-19</a></li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><a href="https://cdgi.edu.in/pdf/Annual_report_ICC_2019-20.pdf">• Annual Report ICC 2019-20</a></li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><a href="https://cdgi.edu.in/pdf/Annual_report_ICC_2020-21.pdf">• Annual Report ICC 2020-21</a></li>
    <li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><a href="https://cdgi.edu.in/pdf/Annual_report_ICC_2021-22.pdf">• Annual Report ICC 2021-22</a></li>
  </ul>
</ul>

            {/* <Trained/> */}
            <Footer/>
        </div>
    )
}

export default Icc