import React, { useState, useEffect } from 'react'
import { FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import ReactHtmlParser from "react-html-parser";
import { Link, useNavigate } from 'react-router-dom';
import penicon from '../../assets/images/pen-icon.png'
import bookicon from '../../assets/images/book-icon.png'
import Footer from '../footer/Footer';
import Gallery from './Gallery'
import Header from '../header/Header'
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaNewspaper } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa";
import Banners from './Banners';
import Campus from './Campus';
import Carousel from 'react-bootstrap/Carousel';
import { GetHomePageData, GetNewsEvents, GetExploreAdd } from '../../api/Global';
import about1 from "../../assets/images/4.png";
import { Bars } from 'react-loader-spinner'
import imagebaseurl from '../../config/imageurl';
import backgroundImageUrl from '../../assets/images/8.jpg'
import Modal from 'react-bootstrap/Modal';
import { CreateStudentEnquirey } from '../../api/Global';
import { TiArrowUp, TiArrowDown } from "react-icons/ti";
import { FaWhatsapp } from 'react-icons/fa';

const Home = () => {
  const [image, setImage] = useState([]);
  const [slides, setSlides] = useState([]);
  const [data, setData] = useState([]);
  const [homeBanners, setHomeBanner] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [placementList, setPlacementList] = useState([]);
  const [exploreBanners, setExploreBanners] = useState([])
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [course, setCourse] = useState("");
  const [mathAnswer, setMathAnswer] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleSubmit = async () => {
    const data = {
      name,
      email,
      mobile,
      email,
      city,
      course,
      mathAnswer
    }
    try {
      const response = await CreateStudentEnquirey(data)
      console.log("response", response)
      if (response?.data?.status == true) {
        alert("Enquiry successfully Submitted")
        setName("")
        setEmail("")
        setMobile("")
        setCity("")
        setCourse("")
        setMathAnswer("")
        setShow(false)
      }
    }
    catch (error) {
      console.log("error", error)
    }
  }

  const handleClick = () => {
    navigate('/Fees_Structure_Pdf');
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const HandleGetPlacementList = async () => {
    setIsLoading(true);
    try {
      const response = await GetNewsEvents();

      if (Array.isArray(response?.data?.data)) {
        // Sorting the events in descending order (latest first)
        const sortedData = response.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));

        setPlacementList(sortedData);
      } else {
        setPlacementList([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching placement list:", error);
      setIsLoading(false);
    }
  };

  const HandleGetGetExploreAdd = async () => {
    // setIsLoading(true)
    try {
      const response = await GetExploreAdd();
      setExploreBanners(response?.data?.data)
    } catch (error) {
      console.log('setExploreBanners', error);


    }
  }



  const FetchHomePageData = async () => {
    setIsLoading(true)
    try {
      const response = await GetHomePageData()
      setData(response.data.data.factsInfo)
      setSlides(response.data.data.placementsBlogs);
      setHomeBanner(response.data.data.homeBanners);
      if (Array.isArray(response.data.data.aboutUs)) {
        setImage(response.data.data.aboutUs);
      }
      console.log('api error', response.data.data.placementsBlogs);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    FetchHomePageData();
    HandleGetPlacementList();
    HandleGetGetExploreAdd();
  }, []);
  console.log("FetchHomePageData_response", homeBanners)

  const [showTopIcon, setShowTopIcon] = useState(false);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Scroll to bottom
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  // Handle scroll to toggle visibility of buttons
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop === 0) {
      setShowTopIcon(false); // At the top, show scroll-down button
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setShowTopIcon(true); // At the bottom, show scroll-up button
    } else {
      setShowTopIcon(scrollTop > 0); // Show scroll-up button when scrolling
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = "919109998199"; 
    const message = "Hello! I have a question.";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank", "noopener,noreferrer");
  };



  return (
    <div>
      {
        isLoading ? (
          <>
            <div className="chat-window text-center">
              <div style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
                zIndex: "999"
              }} className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
                        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
                        e--ua-chrome e--ua-webkit loaded"
              data-elementor-device-mode="desktop">
              <Header />
              <div id="page" className="site" >
                <div className="unicamp-modern-slider-style-05 carousel__margin">
                  <Carousel>
                    {
                      homeBanners?.map((homebannerResult, index) => {
                        return (
                          <Carousel.Item key={index}>
                            <img src={`${imagebaseurl}/${homebannerResult?.bannerImage}`}

                              style={{ width: "100%" }} alt={`slide-${index}`}
                              onClick={() =>
                                handleShow(`${imagebaseurl}/${homebannerResult?.bannerImage}`)
                              }
                            />
                            <Carousel.Caption>
                              <div
                                class="slide-content tm-modern-slider">
                                <div
                                  class="slide-layers">
                                  <div
                                    class="slide-layer-wrap title-wrap">
                                    <div
                                      class="slide-layer">
                                      {/* <h1 className="title">{homebannerResult?.bannerName}</h1> */}
                                    </div>
                                  </div>
                                  <div
                                    class="slide-layer-wrap description-wrap">
                                    <div
                                      class="slide-layer">

                                    </div>
                                  </div>
                                  <ul
                                    class="view_btns">

                                  </ul>
                                </div>
                              </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                        );
                      })
                    }
                  </Carousel>
                  <Modal size='lg' show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Admission Enquiry Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="bg-white">
                        <div class="modlaPopup">
                          <div class="modal-title">
                            Admissions 2024-25 <a href="#" target="_blank">(Visit CDGI Virtual Tour)</a>
                            <table class="table table-striped">
                              <thead>
                                <tr><th>Courses</th>
                                  <th>Specialization</th>
                                  <th>Helpline</th>
                                </tr></thead>
                              <tbody><tr>
                                <td>Engineering/MCA<sup></sup>-CDGI</td>
                                <td>CS, IT, AI &amp; DS, CSIT, EC, ME, Civil /MCA<sup></sup></td>
                                <td>9981621000, 9109998199</td>
                              </tr>
                                <tr>
                                  <td>Management-CDGI</td>
                                  <td>MBA(FT,MM,FA)</td>
                                  <td>9109998198</td >
                                </tr >
                                <tr>
                                  <td>Pharmacy-CDIP</td>
                                  <td>B.Pharm, D.Pharm, M.Pharm (Industrial Pharmacy,Pharmacology, Pharmaceutics)<sup></sup></td >
                                  <td>6262050008, 9826720320</td >
                                </tr >
                                <tr>
                                  <td>Professional-CDIPS</td>
                                  <td>BBA, BCA, B.Com</td >
                                  <td>6262050006</td >
                                </tr >
                                <tr>
                                  <td>Law-CDIL</td>
                                  <td>LLB (Hons.), BA LLB (Hons.), BBA LLB (Hons.)</td >
                                  <td>6262050007</td >
                                </tr >
                                <tr>
                                  <td colspan="3"><a href="#" onClick={handleClick} target="_blank">
                                    Fees Structure &amp; Institute Merit Scholarship</a></td>
                                </tr>
                              </tbody ></table >
                          </div >
                          <div class="elementor-widget-container">
                            <div class="tm-modern-heading">
                              <div class="heading-primary-wrap">
                                <h2 class="heading-primary elementor-heading-title">Admission Enquiry Form
                                </h2>
                              </div>
                              <div class="heading-divider"></div>
                              <div class="heading-description-wrap">
                                <div class="heading-description">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="elementor-widget-container">
                            <div class="unicamp-contact-form-7">
                              <div class="wpcf7">
                                <form action="#" method="post" class="wpcf7-form init">
                                  <div class="form-group">
                                    <lable>Your Name</lable>
                                    <input class="form-control" placeholder="Name"
                                      onChange={(e) => setName(e.target.value)}
                                      type="text" name="name" value={name}
                                    />
                                  </div>
                                  <div class="form-group">
                                    <lable>Your Email</lable>
                                    <input class="form-control" placeholder="Email ID"
                                      onChange={(e) => setEmail(e.target.value)}
                                      value={email} type="email" name="email" />
                                  </div>
                                  <div class="form-group">
                                    <label>Mobile Number</label>
                                    <input class="form-control" placeholder="Phone"
                                      onChange={(e) => setMobile(e.target.value)}
                                      value={mobile} type="number" name="mobile" />
                                  </div>
                                  <div class="form-group">
                                    <label>City</label>
                                    <input class="form-control" placeholder="City"
                                      onChange={(e) => setCity(e.target.value)}
                                      value={city} type="text" name="city" />
                                  </div>
                                  <div class="form-group">
                                    <label>Course</label>
                                    <select required="" size="1" name="course"
                                      onChange={(e) => setCourse(e.target.value)} class="form-control">
                                      <option value="">Please select Any Course
                                      </option>
                                      <option value="B.Tech. Courses[CS,IT,AI &amp; DS,CSIT]">Engineering Courses [CS,IT,AI &amp; DS,CSIT]
                                      </option>
                                      <option value="B.Tech. Course [EC]">Engineering Course [Electronics &amp; Comm. Engg.]
                                      </option>
                                      <option value="B.Tech. Course [ME]">Engineering Course [Mechnical Engineering]
                                      </option>
                                      <option value="B.Tech. Course [Civil Engg.]">Engineering Course [Civil Engineering]
                                      </option>
                                      <option value="Pharmacy Courses[B.Pharm, D.Pharm]">Pharmacy Courses [B.Pharm, D.Pharm]
                                      </option>
                                      <option value="Pharmacy Course[M.Pharm]">Pharmacy Course [M.Pharm]
                                      </option>
                                      <option value="Management Courses[MBA(FT,MM,FA)]">Management Courses[MBA(FT,MM,FA)]
                                      </option>
                                      <option value="Professional Course [BBA]">Professional Courses [BBA]
                                      </option>
                                      <option value="Professional Course[BCA]">Professional Courses [BCA]
                                      </option>
                                      <option value="Professional Course[B.Com]">Professional Courses [B.Com]
                                      </option>
                                      <option value="Law Courses(LLB Hons, BA LLB Hons, BBA LLB Hons)">Law Courses [LLB Hons, BA LLB Hons, BBA LLB Hons]
                                      </option>
                                      <option value="MCA Course">MCA Course
                                      </option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <label>What is nine plus five?</label>
                                    <input class="form-control" placeholder="Answer" value={mathAnswer}
                                      onChange={(e) => setMathAnswer(e.target.value)}
                                      type="text" name="mathAnswer" />
                                  </div>
                                  <div class="form-group">
                                    <div class="form-submit">
                                      <button type="button" onClick={HandleSubmit}>
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div >
                      </div >
                    </Modal.Body >

                  </Modal >


                </div>
                <div className="content-wrapper">
                  <div id="page-content" className="page-content m-0 p74s24d">
                    <div id="page-main-content" className="page-main-content">
                      <article id="post-2585" className="post-2585 page type-page status-publish hentry post-no-thumbnail">
                        <div className="elementor elementor-2585">
                          <div className="elementor-inner">
                            <div className="elementor-section-wrap">
                              <Banners />
                              <div className="overview-area aboutus-vi">
                                <div className="container">
                                  {image?.length > 0 ? (
                                    image?.map((about, index) => (
                                      <div className="row" key={index}>

                                        {index % 2 === 1 ? (
                                          <>
                                            <div className="col-lg-6">
                                              <div className="overview-content">

                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="overview-image m-0">

                                              </div>

                                            </div>
                                          </>
                                        ) : (

                                          <>
                                            <div className="col-lg-6">
                                              <div className="overview-content">
                                                <img src={`${imagebaseurl}/${about?.images}`} alt="overview" />
                                              </div>
                                              <div className="title d-flex align-items-center">
                                                <h2 className="d-flex align-items-center">
                                                  <img src={bookicon} alt="title-shape" />
                                                  <span akhi="20" className="counter value">20</span>+

                                                  <Link className="text-decoration-none arrow-btn" to="/#">
                                                    More Graduates Programs
                                                    {/* <i><FaArrowRight /></i> */}
                                                  </Link>
                                                </h2>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="overview-content">
                                                <div className="title">
                                                  <h2 className="d-flex align-items-center">
                                                    <img src={penicon} alt="title-shape" />
                                                    {ReactHtmlParser(about.title)}
                                                  </h2>
                                                  <div> {ReactHtmlParser(about?.content)}</div>

                                                </div>
                                                <Link className="text-decoration-none arrow-btn mt-4" to="/About_Institutions">
                                                  View More
                                                  <i><FaArrowRight /></i>
                                                </Link>

                                              </div>
                                              <div className="casual-image greddd">
                                                <img src={about1} alt="overview" />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    (<Bars
                                      height="80"
                                      width="80"
                                      color="#4fa94d"
                                      ariaLabel="bars-loading"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      visible={true}
                                    />)
                                    // <p>Loading data.......</p>
                                  )}
                                </div>
                              </div>
                              {exploreBanners?.slice().reverse()[0] && (
                                <section
                                  className="elementor-section programs101 elementor-top-section elementor-element elementor-element-063448a elementor-section-content-middle
                                    elementor-section-boxed elementor-section-gap-beside-yes elementor-section-height-default elementor-section-height-default
                                    elementor-section-column-vertical-align-stretch"
                                  style={{
                                    backgroundImage: `url(${imagebaseurl}/${exploreBanners.slice().reverse()[0].image})`
                                  }}
                                >


                                  <div className="elementor-container elementor-column-gap-extended">


                                    <div className="elementor-row">

                                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-69ad18c"
                                        data-id="69ad18c" data-element_type="column">
                                        <div className="elementor-column-wrap elementor-element-populated">
                                          <div className="elementor-widget-wrap">
                                            <div className="elementor-element elementor-element-977e01f unicamp-modern-heading-style-04 elementor-invisible 
                                          elementor-widget elementor-widget-tm-heading" data-id="977e01f" data-element_type="widget"
                                              data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-heading.default">
                                              <div className="elementor-widget-container">
                                                <div className="tm-modern-heading">

                                                  <div className="heading-primary-wrap">
                                                    <h2 className="heading-primary elementor-heading-title">
                                                      <mark className='header-title'> {exploreBanners?.slice().reverse()[0].heading}</mark>
                                                      {/* <mark>  Explore <span style={{color:'#004b9c'}}>CDGI</span>  <br/>
                                                    </mark> */}
                                                    </h2>
                                                  </div>


                                                  <div className="heading-description-wrap">
                                                    <div className="heading-description">
                                                      {exploreBanners?.slice().reverse()[0].subHeading}
                                                      {/* Choose from 20+ Undergraduate and Postgraduate Programs */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="elementor-element elementor-element-e60b0c2 elementor-mobile-align-center elementor-invisible elementor-widget elementor-widget-tm-button" data-id="e60b0c2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-button.default">
                                              <div className="elementor-widget-container">
                                                <div className="tm-button-wrapper">

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a4ccc2b" data-id="a4ccc2b" data-element_type="column">
                                        <div className="elementor-column-wrap">
                                          <div className="elementor-widget-wrap">
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </section>
                              )
                              }
                              <Campus />
                              <div className="funfact-area pb-75">
                                <div className="container">

                                  <div className="row g-0 justify-content-center">
                                    {data?.length > 0 ? (
                                      data?.slice(0, 4)?.map((dates, index) => (
                                        <div key={index} className="col-lg-3 col-sm-6 col-xs-6 col-md-6">
                                          <div className="single-funfact-info text-center">
                                            <h2 className="d-flex align-items-center justify-content-center">
                                              <span akhi='10000' className="counter value">{dates?.factNumber}</span>
                                            </h2>
                                            <p>{dates?.factName}</p>

                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <p>No images available</p>
                                    )
                                    }
                                  </div>
                                </div>
                              </div>


                              <section className="contact_sec">
                                <div className="container">
                                  <div className="contact_topdd">
                                    <div className="row">
                                      <div className="col-md-6 col-lg-4">

                                        <div className="elementor-widget-wrap">
                                          <div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
                                            <div className="unicamp-box unicamp-contact-box">
                                              <div className="calendericons">
                                                <i ><FaRegCalendarAlt />
                                                </i>
                                              </div>
                                              <h4 className="contact-box-title">Placement Activity</h4>
                                              <div className="contact-box-info">

                                                <div className="contact-box-info-item address">
                                                  <span className="meta-label">More than 300+ for the AY:2022 and More than 200+ students offered from various reputed companies for the AY:2023 till now</span>
                                                  <Link to="/Placement" className="mt-5 tm-button-link tm-button style-flat tm-button-nm icon-right">
                                                    <div className="button-content-wrapper">
                                                      <span className="button-text">Read More</span>
                                                      <span className="button-icon">
                                                        <FaArrowRight />
                                                      </span>
                                                    </div>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="elementor-widget-wrap">
                                          <div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
                                            <div className="unicamp-box unicamp-contact-box">
                                              <div className="calendericons">
                                                <i ><FaNewspaper />
                                                </i>
                                              </div>
                                              <h4 className="contact-box-title">News and Events</h4>
                                              <div className="contact-box-info">
                                                <div className="contact-box-info-item address">
                                                  <span className="meta-label">First International conference on Energy Conservation and Sustainable Development using AI</span>
                                                  <Link to="/News_And_Events" className="mt-5 tm-button-link tm-button style-flat tm-button-nm icon-right">
                                                    <div className="button-content-wrapper">
                                                      <span className="button-text">Read More</span>
                                                      <span className="button-icon">
                                                        <FaArrowRight />

                                                      </span>
                                                    </div>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="col-md-6 col-lg-4">

                                        <div className="elementor-widget-wrap">
                                          <div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
                                            <div className="unicamp-box unicamp-contact-box">
                                              <div className="calendericons">
                                                <i><FaLayerGroup />
                                                </i>
                                              </div>
                                              <h4 className="contact-box-title">Tie-ups,MoUs & Associations</h4>
                                              <div className="contact-box-info">
                                                <div className="contact-box-info-item address">
                                                  <span className="meta-label">The institute has life time memberships, corporate tie ups and MoUs with high profile, renowned organizations.</span>
                                                  <Link to="/Mous_Tie" className="mt-5 tm-button-link tm-button style-flat tm-button-nm icon-right">
                                                    <div className="button-content-wrapper">
                                                      <span className="button-text">Read More</span>
                                                      <span className="button-icon">
                                                        <FaArrowRight />
                                                      </span>
                                                    </div>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </section>
                              <Gallery />
                              <section className="bg-light py-5 elementor-section-column-vertical-align-stretch" data-id="161aa64" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-7 col-lg-8 col-sm-12">
                                      <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-508ee1e elementor-widget__width-auto elementor-invisible elementor-widget elementor-widget-tm-heading" data-id="508ee1e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-heading.default">
                                          <div className="elementor-widget-container">
                                            <div className="tm-modern-heading">
                                              <div className="heading-primary-wrap d-flex align-items-center justify-content-between">
                                                <h4 className="heading-primary elementor-heading-title">
                                                  Placement
                                                  Activity
                                                </h4>

                                                <Link to="/placement">
                                                  <button className="button-content-wrapper">
                                                    <span className="button-text">View all</span>
                                                    <span className="button-icon">
                                                      <FaArrowRight />
                                                    </span>
                                                  </button>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="elementor-element elementor-element-7b2dee9 elementor-widget__width-auto elementor-invisible elementor-widget elementor-widget-tm-button" data-id="7b2dee9" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-button.default">
                                            <div className="elementor-widget-container">
                                              <div className="tm-button-wrapper">
                                                <Link to="#" className="tm-button-link tm-button style-text tm-button-nm icon-right" role="button">
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="elementor-element elementor-element-dcdf23e elementor-widget elementor-widget-spacer" data-id="dcdf23e" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                              <div className="elementor-spacer">
                                                <div className="elementor-spacer-inner">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="elementor-element elementor-element-7e3199b unicamp-animation-zoom-in unicamp-blog-caption-style-01 bullets-v-align-below elementor-invisible elementor-widget elementor-widget-tm-post-carousel" data-id="7e3199b" data-element_type="widget" data-settings="{&quot;swiper_items&quot;:&quot;3&quot;,&quot;swiper_items_mobile&quot;:&quot;auto-fixed&quot;,&quot;_animation&quot;:&quot;unicampFadeInUp&quot;,&quot;swiper_items_tablet&quot;:&quot;2&quot;}" data-widget_type="tm-post-carousel.default">
                                            <div className="elementor-widget-container">
                                              <div className="tm-swiper tm-slider-widget pagination-style-01 bullets-horizontal bullets-h-align-center bullets-v-align-below unicamp-blog">
                                                <Slider {...settings} style={{ gap: '20px' }}>
                                                  {slides?.map((slid, index) => (
                                                    <div key={index} className="swiper-inner ks45785d">
                                                      <div className="post-wrapper unicamp-box">
                                                        <div className="post-feature post-thumbnail unicamp-image">
                                                          <Link to="#">
                                                            <img src={`${imagebaseurl}/${slid?.images}`} alt={slid?.title} style={{ width: "380px", height: "300px" }} />

                                                          </Link>
                                                        </div>
                                                        <div className="post-caption">
                                                          <div className="post-categories">
                                                            <Link to="#"><span className="cat-shape" style={{ background: '#a371ff' }}></span><span className="cat-name">Student</span></Link>
                                                          </div>
                                                          <h3 className="post-title post-title-2-rows">
                                                            <Link to="#">{slid?.title}</Link>
                                                          </h3>
                                                          <div className="post-meta">
                                                            <div className="inner">
                                                              <div className="post-meta-author">
                                                                <Link to="#author/thememove/">
                                                                  <span className="meta-value">Chameli Devi</span>
                                                                </Link>
                                                              </div>
                                                              <div className="post-date">
                                                                <span className="meta-value">July 29, 2024</span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </Slider>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5 col-lg-4 col-sm-12" data-id="3e786b3" data-element_type="column">
                                      <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">

                                          <div className="elementor-element elementor-element-181eb0b elementor-invisible elementor-widget elementor-widget-tm-event-box" data-id="181eb0b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-event-box.default" >
                                            <div className="elementor-widget-container">
                                              <div className="unicamp-event-box unicamp-box">
                                                <div className="box-header">
                                                  <h4 className="box-title">
                                                    News and
                                                    Events
                                                  </h4>

                                                  <Link to="/News_And_Events">
                                                    <button className="button-content-wrapper px-2">
                                                      <span className="button-text">View all</span>
                                                      <span className="button-icon">
                                                        <FaArrowRight />
                                                      </span>
                                                    </button>
                                                  </Link>

                                                  <div className="tm-button-wrapper">
                                                    <Link className="tm-button style-text tm-button-nm icon-right" to="#">
                                                      <div className="button-content-wrapper">
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                                <Link to="/News_And_Events">

                                                  <div className="box-body">
                                                    {
                                                      placementList?.slice(0, 3)?.map((News_And_Events_Result) => {
                                                        return (
                                                          <>
                                                            <div className="post-item">
                                                              <div className="event-caption">
                                                                <div className="left-box">
                                                                  <div className="event-date">
                                                                    <div className="event-date--day">
                                                                      {new Date(News_And_Events_Result.date).toLocaleDateString('en-US', {
                                                                        day: 'numeric'
                                                                      })}
                                                                    </div>
                                                                    <div className="event-date--month">

                                                                      {new Date(News_And_Events_Result.date).toLocaleDateString('en-US', {
                                                                        month: 'short'
                                                                      })}
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                <div className="right-box">
                                                                  <p className="post-title post-title-2-rows title-has-link">
                                                                    <a href="#" className='event-meta'>
                                                                      {ReactHtmlParser(News_And_Events_Result?.title)}
                                                                    </a>
                                                                  </p>
                                                                  <div className="event-meta">
                                                                    <div className="event-time" >
                                                                      {News_And_Events_Result?.time}
                                                                    </div>

                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )
                                                      })
                                                    }


                                                  </div>
                                                </Link>

                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>


                            </div>
                          </div>
                        </div>
                      </article>
                    </div>

                  </div>
                </div>
                {/* <Trained /> */}
              </div >

              {showTopIcon ? (
                <Link
                  id="scrollUp"
                  to="#"
                  onClick={scrollToTop}
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zIndex: "2147483647",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    textAlign: "center",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <TiArrowUp style={{ fontSize: "24px" }} />
                </Link>
              ) : (
                <Link
                  id="scrollBottom"
                  to="#"
                  onClick={scrollToBottom}
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zIndex: "2147483647",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    textAlign: "center",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <TiArrowDown style={{ fontSize: "24px" }} />
                </Link>
              )}
              <button
                onClick={handleWhatsAppClick}
                style={{
                  position: "fixed",
                  bottom: "20px",
                  right: "80px",
                  zIndex: "9999",
                  backgroundColor: "#25D366",
                  color: "white",
                  border: "none",
                  outline: "none",
                  padding: "10px 15px",
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <FaWhatsapp size={40} />
                Chat for Admission Enquiry
              </button>

              <Footer />
            </div>
          </>
        )
      }
    </div>
  )
}

export default Home

