import React, { useEffect, useState } from 'react'
import { GetHomePageData } from '../../api/Global';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import ReactHtmlParser from "react-html-parser";
import backgroundImage from '../../assets/images/444.png'
import { useParams } from 'react-router-dom';
import imagebaseurl from '../../config/imageurl';


const Campus_Life_Details = () => {
    const { _id } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [CampusLifeList, setCampusLifeList] = useState([]);
    const [selectedCampusLife, setSelectedCampusLife] = useState(null);
    const GetCampusLifeList = async () => {
        setIsLoading(true)
        try {
            const response = await GetHomePageData();
            setCampusLifeList(response?.data?.data?.campusLife)
            setIsLoading(false)
        } catch (error) {
            console.log("error", error)
            setIsLoading(true)
        }
    }
    console.log("CampusLifeList", CampusLifeList)
    useEffect(() => {
        GetCampusLifeList();
    }, [])

    useEffect(() => {
        if (CampusLifeList.length > 0) {
            const selectedData = CampusLifeList.find(item => item._id == _id);
            setSelectedCampusLife(selectedData);
        }
    }, [CampusLifeList, _id])
    console.log("selectedCampusLife", selectedCampusLife)

    return (
        <div>
            {
                isLoading ? (
                    <>
                        <div className="chat-window text-center">
                            <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                zIndex: "999"
                            }} className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
        e--ua-chrome e--ua-webkit loaded"
                            data-elementor-device-mode="desktop">
                            <Header />
                            <div class="menu_space"></div>
                            <section class="about-breadcrumb breadcumb-bg"
                                style={{
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '400px'
                                }}>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="breadcrumb-content">
                                                <ul class="breadcrumb">
                                                    {/* <li class="breadcrumb-item active" aria-current="page">{selectedCampusLife?.title}</li> */}
                                                </ul>
                                                <h2 class="heading-title">{selectedCampusLife?.title}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div id="page-content" class="page-content">
                                <div class="overview-area py-100">
                                    <div class="container">
                                        <div class="row">
                                            {/* <!-- Left Column: Image --> */}
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="campus_thumbs">
                                                    <img src={`${imagebaseurl}/${selectedCampusLife?.images}`}  alt="campus-life" class="img-fluid" />
                                                </div>
                                            </div>

                                            {/* <!-- Right Column: Content --> */}
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="campuslifes">
                                                    <div class="box-caption">
                                                        <h3 class="title">
                                                            {selectedCampusLife?.title}
                                                        </h3>
                                                        {ReactHtmlParser(selectedCampusLife?.content)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Footer />
                        </div>
                    </>
                )
            }

        </div>

    )
}

export default Campus_Life_Details