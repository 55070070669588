import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/444.png';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded">
      <Header />
      <section 
        className="about-breadcrumb breadcumb-bg"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '400px'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">/</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Login</li>
                </ul> */}
                <h2 className="heading-title">Login</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="apply_jobs">
        <div className="container custome_container">
          <div className="row">
            <div className="col-lg-8 mx-auto p-0">
              <div className="bg-light"style={{padding:'50px'}}>
                <div className="elementor-widget-container">
                  <div className="tm-modern-heading">
                    <div className="heading-primary-wrap">
                      <h2 className="heading-primary elementor-heading-title">Login Form</h2>
                    </div>
                    <div className="heading-divider"></div>
                    <div className="heading-description-wrap">
                      <div className="heading-description"></div>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="unicamp-contact-form-7">
                    <div className="wpcf7">
                      <form action="#" method="post" className="wpcf7-form init">
                        <div className="form-group">
                          <label>Email ID</label> 
                          <input className="form-control" placeholder="Email ID" type="text" name="email" />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input className="form-control" placeholder="Password" type="password" name="password" />
                        </div>
                        <div className="form-group">
                          <div className="form-submit">
                            <button type="submit" value="Submit">
                              Login
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
