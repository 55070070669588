import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/444.png'
import { GetMous_Tie, GetNewsEvents, GetPlacementList } from '../../api/Global';
import ReactHtmlParser from "react-html-parser";
import Header from '../header/Header';
import { useNavigate } from 'react-router-dom';
import { FaFilePdf } from "react-icons/fa";
// import Trained from '../Trained/Trained';
import Footer from '../footer/Footer';
import imagebaseurl from '../../config/imageurl';
const Mous_Tie = () => {
    const navigate = useNavigate();
    const [placementList, setPlacementList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const HandleGetPlacementList = async () => {
        setIsLoading(true)
        try {
            const response = await GetMous_Tie();
            setPlacementList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(true)

        }
    }
    console.log("placementList", placementList)
  

    const handleClick = (overviewDoc, pdftitle) => {
        localStorage.setItem('selectedDoc', overviewDoc);
        localStorage.setItem('selectedDoc_title', pdftitle);
        navigate('/More_Pdf_Open');
    };

    useEffect(() => {
        HandleGetPlacementList();
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            {
                isLoading ? (
                    <>
                        <div className="chat-window text-center">
                            <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                zIndex: "999"
                            }} className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
        e--ua-chrome e--ua-webkit loaded"
                            data-elementor-device-mode="desktop">
                            <Header />
                            < section className="about-breadcrumb breadcumb-bg"
                                style={{
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '400px'
                                }}>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="breadcrumb-content">
                                                <ul className="breadcrumb">
                                                    {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item"><Link >/</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Tie-ups,MoUs & Associations
                                                    </li> */}
                                                </ul>
                                                <h2 className="heading-title">Tie-ups,MoUs & Associations</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section >
                            <div className='container'>
                            <div>
                                    {
                                        placementList?.blogs?.length > 0 && (
                                            <>
                                                {placementList?.blogs?.map((OverviewBlogDeatilsResult, index) => {
                                                    return (
                                                        <div key={index} className="blog-content-container">
                                                            {ReactHtmlParser(OverviewBlogDeatilsResult?.content)}
                                                        </div>
                                                    );
                                                })}
                                            </>

                                        )
                                    }

                                </div>
                                <div className='text-center mt-5'>
                                    <h1>Tie-ups,MoUs & Associations</h1>
                                </div>
                                <div className="row bog_team">
                                    {placementList?.gallery?.length > 0 && (
                                        placementList?.gallery?.map((image, index) => (
                                           <div className="col-12 col-md-4 col-lg-3 mt-3 d-flex">
                                            <div className="card w-100" key={index} 
                                            // style={{
                                            //     width: "23%",
                                            //     margin: "10px",
                                            //     padding: "10px"
                                            // }}
                                            >
                                                <div className="card-image moustie">
                                                    <Link to={`${imagebaseurl}/${image?.galleryImage}`} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
                                                        <img src={`${imagebaseurl}/${image?.galleryImage}`} alt={image.alt || `Image ${index + 1}`}
                                                            //style={{ height: "50vh", width: "100%" }} 
                                                            />
                                                    </Link>
                                                    <div className="logo_title">{image?.galleryName}</div>
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                    )
                                    }
                                </div>
                                {/* <div>
                                    {
                                        placementList?.blogs?.length > 0 && (
                                            <>
                                                {placementList?.blogs?.map((OverviewBlogDeatilsResult, index) => {
                                                    return (
                                                        <div key={index} className="blog-content-container">
                                                            {ReactHtmlParser(OverviewBlogDeatilsResult?.content)}
                                                        </div>
                                                    );
                                                })}
                                            </>

                                        )
                                    }

                                </div> */}

                                <div className="row bog_team">
                                    {
                                        placementList?.BOG?.length > 0 && (
                                            <>
                                                {
                                                    placementList?.BOG?.map((OverViewBlogDetails) => {
                                                        return (
                                                            <>
                                                                <div class="col-md-12">
                                                                    <div class="team-card style2">
                                                                        <div class="team-img-wrap">
                                                                            <div class="team-img"><img
                                                                                src={OverViewBlogDetails?.imageLink}
                                                                                alt="Team" /></div>
                                                                        </div>
                                                                        <div class="team-content">
                                                                            <h3 class="team-title">{OverViewBlogDetails?.name}</h3>
                                                                            <p>{OverViewBlogDetails?.companyName}</p>
                                                                            <span class="team-desig text-blue">{OverViewBlogDetails?.designation}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </div>

                                <div className="row initiative_box">
                                    {
                                        placementList?.docs?.length > 0 && (
                                            <>
                                                <h4>Please click on the view button to view the messages:</h4>
                                                {
                                                    placementList?.docs?.map((OverviewDocResult) => {
                                                        return (
                                                            <>
                                                                <div class="col-lg-3 col-md-4 col-xs-12 col-sm-6">
                                                                    <div class="courses-item">
                                                                        <div class="content">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <h5>{OverviewDocResult?.fileName}</h5>
                                                                                <FaFilePdf size={60} color='rgb(120 155 194)' />
                                                                            </div>
                                                                            <div onClick={() => handleClick(OverviewDocResult?.fileUrl, OverviewDocResult?.fileName)} >
                                                                                <Link to='#' class="text-decoration-none pharmabtn">
                                                                                    View
                                                                                    <i class="fas fa-chevron-right"></i>
                                                                                </Link>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </>
                )
            }
{/* <Trained/> */}
<br/>
<Footer/>
        </div>

    )
}

export default Mous_Tie
