// import React, { useEffect, useState } from 'react'
// import Header from '../header/Header'
// import backgroundImage from '../../assets/images/444.png'
// import {	Studentstestimonial} from "../../api/Global";
// // import Trained from '../Trained/Trained'

// import Footer from '../footer/Footer'
// const Student_Testimonial = () => {
// const [student,setStudent] = useState([]);
// const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {
//     const StudentTest = async () => {
//       setIsLoading(true);
//       try {
//         const response = await Studentstestimonial();
//         setStudent(response?.data?.data || []);
//         // setIsLoading(false);
//       } catch (error) {
//         setIsLoading(true);
//         console.log("error", error);
//       }
//     };
//     StudentTest();
//   }, []);


// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	return (
// 		<div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//     page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
//      e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
// 			<div id="page" class="site">
// 				<div class="content-wrapper">
// 					<Header />

// 					<div class="menu_space"></div>
// 					{/* <!-- about bg --> */}
// 					<section class="about-breadcrumb breadcumb-bg"
// 						style={{
// 							backgroundImage: `url(${backgroundImage})`,
// 							backgroundSize: 'cover',
// 							backgroundPosition: 'center',
// 							width: '100%',
// 							height: '400px'
// 						}}>
// 						<div class="container">
// 							<div class="row">
// 								<div class="col-12">
// 									<div class="breadcrumb-content">
// 										<ul class="breadcrumb">
// 											{/* <li class="breadcrumb-item"><a href="index.html">Home</a></li>
// 									<li class="breadcrumb-item"><a>/</a></li> */}
// 											{/* <li class="breadcrumb-item active" aria-current="page">Students’ Testimonials</li> */}
// 										</ul>
// 										<h2 class="heading-title">Students’ Testimonials</h2>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</section>
// 					<div class="stutbgs">
// 						<div class="container">
// 							<div class="row">
// 								<div class="col-md-12">
// 									<div class="overview-image program_out text-center">
// 										<div class="title">
// 											{/* <h2>
// 												Students’ <span class="text-blue">Testimonials</span>
// 											</h2> */}
// 										</div>
// 									</div>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F526549804921431%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F371410773746475%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F2975353385861220%2F&amp;show_text=true&amp;width=560&amp;t=0"
// 										style={{ border: "none", overflow: "hidden", height: "800px" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F894533811135273%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F549128666512832%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
// 								</div>
// 								<div class="col-md-4">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F217251180293561%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=308&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F3043336569275927%2F&amp;show_text=true&amp;width=560&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>

// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F360468308944206%2F&amp;show_text=true&amp;width=476&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F3029018437317471%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F910780836144548%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F960363688081462%2F&amp;show_text=true&amp;width=214&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F910780836144548%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F530482304835002%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3470115329671385&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								{/* <div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3470115329671385&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div> */}
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3470140276335557&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3472598916089693&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3503086159707635&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3529151757101075&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3473041502712101&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3473076316041953&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3473133029369615&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3475486205800964&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3475492419133676&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3480642245285360&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3487741864575398&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3487805537902364&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3487910357891882&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3529640413718876&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3493469227335995&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>

// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3453924657957119&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F217251180293561%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3467489206600664&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3470690069613911&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F371116111192946%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F214225747142534%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=308&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F3043336569275927%2F&amp;show_text=true&amp;width=560&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/video.php?height=476&amp;href=https%3A%2F%2Fbusiness.facebook.com%2Fcdgiindore%2Fvideos%2F783083572358081%2F&amp;show_text=true&amp;width=261&amp;t=0"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3490390377643880&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>
// 								<div class="col-md-4 mt-3">
// 									<iframe
// 										src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcdgiindore%2Fposts%2F3496133627069555&amp;show_text=true&amp;width=500"
// 										width="261" height="591" style={{ border: "none", overflow: "hidden" }} scrolling="no"
// 										frameborder="0" allowfullscreen="true"
// 										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

// 								</div>

// 								<p><br />
// 								</p>

// 								{/* <h2 style={{lineHeight:"1.5rem"}}>Students’<strong>Testimonials</strong></h2> */}



// 							</div>
// 						</div>
// 					</div>


// 				</div>

// 				{/* <Trained /> */}
// 				<Footer />
// 			</div>

// 		</div>
// 	)
// }

// export default Student_Testimonial


// import React, { useEffect, useState } from 'react';
// import Header from '../header/Header';
// import backgroundImage from '../../assets/images/444.png';
// import { Studentstestimonial } from "../../api/Global";
// import Footer from '../footer/Footer';

// const Student_Testimonial = () => {
// 	const [student, setStudent] = useState([]);
// 	const [isLoading, setIsLoading] = useState(false);

// 	useEffect(() => {
// 		const StudentTest = async () => {
// 			setIsLoading(true);
// 			try {
// 				const response = await Studentstestimonial();
// 				// setStudent(response?.data?.data || []);
// 				setStudent(response?.data?.data);
// 			} catch (error) {
// 				console.log("Error fetching testimonials:", error);
// 			} finally {
// 				setIsLoading(false); // Ensures loading stops
// 			}
// 		};

// 		StudentTest();
// 	}, []);

// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);

// 	return (
// 		<div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//       page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
//       e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
// 			<div id="page" className="site">
// 				<div className="content-wrapper">
// 					<Header />
// 					<div className="menu_space"></div>

// 					{/* Background Section */}
// 					<section className="about-breadcrumb breadcumb-bg"
// 						style={{
// 							backgroundImage: `url(${backgroundImage})`,
// 							backgroundSize: 'cover',
// 							backgroundPosition: 'center',
// 							width: '100%',
// 							height: '400px'
// 						}}>
// 						<div className="container">
// 							<div className="row">
// 								<div className="col-12">
// 									<div className="breadcrumb-content">
// 										<h2 className="heading-title">Students’ Testimonials</h2>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</section>

// 					<div className="stutbgs">
// 						<div className="container">
// 							<div className="row">
// 								<div className="col-md-12 text-center">
// 									<h2>Students’ <span className="text-blue">Testimonials</span></h2>
// 								</div>

							

// 								{isLoading ? (
// 									<div className="col-12 text-center">
// 										<p>Loading testimonials...</p>
// 									</div>
// 								) : (

// 									student.map((item) => (
// 										<div class="col-md-4 mb-4">
// 											<div className='card facebook_post border-0'>
// 												<div className='card-body p-0'>
// 											{item.title && <h5 className="text-center">{item.title}</h5>}

// 											<iframe
// 												src={item.link}
// 												width="100%" height="900px"
// 												style={{ border: "none", overflow: "hidden", }} scrolling="no" 
// 												frameborder="0" allowfullscreen="true"
// 												allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
				
// 										</div>
// 										</div>
// 										</div>
// 									)))
// 								}



// 							</div>
// 						</div>
// 					</div>

// 					<Footer />
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default Student_Testimonial;


// import React, { useEffect, useState } from 'react';
// import Header from '../header/Header';
// import backgroundImage from '../../assets/images/444.png';
// import { Studentstestimonial } from "../../api/Global";
// import Footer from '../footer/Footer';

// const Student_Testimonial = () => {
//   const [student, setStudent] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const StudentTest = async () => {
//       setIsLoading(true);
//       try {
//         const response = await Studentstestimonial();
//         setStudent(response?.data?.data || []);
//       } catch (error) {
//         console.log("Error fetching testimonials:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     StudentTest();
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//       page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
//       e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
//       <div id="page" className="site">
//         <div className="content-wrapper">
//           <Header />
//           <div className="menu_space"></div>

//           {/* Background Section */}
//           <section className="about-breadcrumb breadcumb-bg"
//             style={{
//               backgroundImage: `url(${backgroundImage})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               width: '100%',
//               height: '400px'
//             }}>
//             <div className="container">
//               <div className="row">
//                 <div className="col-12">
//                   <div className="breadcrumb-content">
//                     <h2 className="heading-title">Students’ Testimonials</h2>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>

//           <div className="stutbgs">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-12 text-center">
//                   <h2>Students’ <span className="text-blue">Testimonials</span></h2>
//                 </div>

//                 {/* Loading Indicator */}
//                 {isLoading ? (
//                   <div className="col-12 text-center">
//                     <p>Loading testimonials...</p>
//                   </div>
//                 ) : (
//                   student.length > 0 ? (
//                     student.map((item) => (
//                       <div className="col-md-6 col-lg-4 mb-4" key={item._id}>
//                         <div className="testimonial-card">
                        
//                           {item.title && <h5 className="text-center">{item.title}</h5>}
                          
                        
//                           <iframe
//                             src={item.link}
//                             width="100%"
//                             height="100%"
														
//                             style={{
//                               border: "none",
//                               overflow: "hidden",
//                               // minHeight: "700px",
//                               maxHeight: "1000px",
//                               objectFit: "cover",
// 															textAlign: "center"
//                             }}      
//                             allowFullScreen
//                             title={`Testimonial ${item._id}`}
//                           ></iframe>
//                         </div>
//                       </div>
//                     ))
//                   ) : (
//                     <div className="col-12 text-center">
//                       <p>No testimonials available.</p>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>
//           </div>

//           <Footer />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Student_Testimonial;


import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import backgroundImage from "../../assets/images/444.png";
import { Studentstestimonial } from "../../api/Global";
import Footer from "../footer/Footer";

const Student_Testimonial = () => {
  const [student, setStudent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      setIsLoading(true);
      try {
        const response = await Studentstestimonial();
        setStudent(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
     page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
     e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">

      <div id="page" className="site">
        <div className="content-wrapper">
          <Header />

          {/* Background Section */}
          <section
            className="about-breadcrumb breadcumb-bg"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "400px",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb-content">
                    <h2 className="heading-title">Students’ Testimonials</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Testimonials Section */}
          <div className="stutbgs">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2>
                    Students’ <span className="text-blue">Testimonials</span>
                  </h2>
                </div>

                {/* Loading Indicator */}
                {isLoading ? (
                  <div className="col-12 text-center">
                    <p>Loading testimonials...</p>
                  </div>
                ) : (
                  <>
                    {student.length > 0 ? (
                      student.map((item) => {
                        // Ensure 'show_text=true' is present in the URL
                        const modifiedUrl = item.link.includes("show_text")
                          ? item.link
                          : `${item.link}&show_text=true`;

                        return (
                          <div className="col-md-4 mt-3" key={item._id}>
                            <div className="video-card text-center">
                              {/* Video Frame */}
                              <iframe
															
                                src={modifiedUrl}
                                width="100%"
                                height="420px"
                                style={{
                                  border: "none",
                                  overflow: "hidden",
                                  objectFit: "cover",
                                }}
                                allowFullScreen
                                title={`Testimonial ${item._id}`}
                              ></iframe>

                              {/* Video Title Below */}
                              {item.title && (
                                <h5 className="mt-3">{item.title}</h5>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12 text-center">
                        <p>No testimonials available.</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Student_Testimonial;

