import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import '../src/assets/css/custome-style.css'
import '../src/assets/css/events-manager.css'
import '../src/assets/css/global.css'
import '../src/assets/css/post-22.css'
import '../src/assets/css/post-2585.css'
import '../src/assets/css/post-3005.css'
import '../src/assets/css/style.min.css'
import '../src/assets/css/tabs-css.css'
import '../src/assets/css/tutor-lms.min.css'
import '../src/assets/css/video-conferencing-zoom.min.css'
import '../src/assets/css/woocommerce.min.css'
import '../src/assets/fonts/awesome/css/fontawesome-all.min.css'
import '../src/assets/fonts/gordita/font-gordita.min.css'
import 'suneditor/dist/css/suneditor.min.css';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
// import "react-image-gallery/styles/css/image-gallery.css";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
