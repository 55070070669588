// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import backgroundImage from '../../assets/images/444.png';
// import { GetPlacementList } from '../../api/Global';
// import ReactHtmlParser from 'react-html-parser';
// import Header from '../header/Header';
// import { useNavigate } from 'react-router-dom';
// import { FaFilePdf } from 'react-icons/fa';
// // import Trained from '../Trained/Trained';
// import Footer from '../footer/Footer';
// import {PostOverTabsData} from '../../api/Global'


// const Placement = () => {
//   const navigate = useNavigate();
//   const [placementList, setPlacementList] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [placement, setPlacement] =useState([])
//   const [activeKey, setActiveKey] = useState("");
  

//   const HandleGetPlacementList = async () => {
//     setIsLoading(true);
//     try {
//       const response = await GetPlacementList();
//       console.log("HandleGetPlacementList",response?.data?.data?.tabs)
//       setPlacementList(response?.data?.data);
//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(true);
//     }
//   };
//   console.log("placementList",placementList)
  
//   const HandleActiveTabs = (id) => {
//     setActiveKey(id);
//     GetPlacementView(id)
// }
//   const GetPlacementView = async (id) => {
    
//     try {
//         const response = await PostOverTabsData(id);
//         setPlacement(response?.data?.data); 
//     }
//     catch (error) {
//         console.log("error", error);
//     }
// }
//   const handleClick = (overviewDoc, pdftitle) => {
//     localStorage.setItem('selectedDoc', overviewDoc);
//     localStorage.setItem('selectedDoc_title', pdftitle);
//     navigate('/More_Pdf_Open');
//   };
//   useEffect(() => {
//     HandleGetPlacementList();
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     GetPlacementView(activeKey);
// }, [activeKey])

//   useEffect(() => {
//     const savedActiveTabId = localStorage.getItem('ActiveTabs');
//     if (savedActiveTabId) {
//         setActiveKey(savedActiveTabId);
//     } else if (placementList && placementList?.tabs?.length > 0) {
//         setActiveKey(placementList?.tabs[0]?._id);
//     }
// }, [placementList]);
// const handleImageLoad = (index) => {
//   setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
// };

// const handleImageClick = (index) => {
//   // Preload the clicked image before opening the lightbox
//   const img = new Image();
//   img.src = images[index].galleryImage;

//   img.onload = () => {
//       setIsImageLoaded(true); // Mark image as fully loaded
//       setIsOpen(true);
//       setPhotoIndex(index);
//   };
// };
//   return (
//     <div>
//       {isLoading ? (
//         <div className="chat-window text-center">
//           <div
//             style={{
//               position: 'absolute',
//               top: 0,
//               bottom: 0,
//               margin: 'auto',
//               zIndex: '999',
//             }}
//             className="spinner-border"
//             role="status"
//           >
//             <span className="sr-only"></span>
//           </div>
//         </div>
//       ) : (
//         <div
//           className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//         page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
//         e--ua-chrome e--ua-webkit loaded"
//           data-elementor-device-mode="desktop">
//           <Header />
//           <section
//             className="about-breadcrumb breadcumb-bg"
//             style={{
//               backgroundImage: `url(${backgroundImage})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               width: '100%',
//               height: '400px',
//             }}
//           >
//             <div className="container">
//               <div className="row">
//                 <div className="col-12">
//                   <div className="breadcrumb-content">
//                     {/* <ul className="breadcrumb">
//                       <li className="breadcrumb-item">
//                         <Link to="/">Home</Link>
//                       </li>
//                       <li className="breadcrumb-item">
//                         <Link to="/">/</Link>
//                       </li>
//                       <li className="breadcrumb-item active" aria-current="page">
//                         Placement
//                       </li>
//                     </ul> */}
//                     <h2 className="heading-title">Training & Placement Cell</h2>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <div className='row text-center m-3'>
//           <div className='row text-center m-3'>
//                 {
//                     placementList?.tabs?.map((placementListResult, index) => {
//                         return (
//                             <div key={index} className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
//                                 <div className={`p-2 m-1 ${activeKey == placementListResult?._id ? "active-tab1" : "inactive-tab1"}`}
//                                     onClick={() => {
//                                         HandleActiveTabs(placementListResult?._id)
//                                         localStorage.setItem("ActiveTabs", placementListResult?._id)
//                                     }}>
//                                     <p>{placementListResult?.name}</p>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>

//             <div className="row bog_team">
//                         {placement?.gallery?.length > 0 && (
//                             placement?.gallery?.map((image, index) => (
//                                 <div className="card col-4 mt-3" key={index}>
//                                     <div className="card-image">
//                                         {
//                                             image?.mediaType === "video" ? (
//                                                 <>
//                                                     <video
//                                                         // autoPlay
//                                                         controls
//                                                         className="photo-item__video NewsDetailsVideos"
//                                                         muted
//                                                         // preload="auto"
//                                                         style={{
//                                                             width: "90%",
//                                                             // maxHeight: "500px",
//                                                             height: "500px",
//                                                             objectFit: "contain",
//                                                             display: "block",
//                                                             margin: "0 auto"
//                                                         }}
//                                                     >
//                                                         <source src={image?.galleryVideo} type="video/mp4" />
//                                                     </video>
//                                                     <h6>{image?.galleryName}</h6>
//                                                 </>

//                                             ) : (
//                                                 <>
//                                                     <Link to={image?.galleryImage} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
//                                                         <img src={image?.galleryImage} alt={image.alt || `Image ${index + 1}`}
//                                                             style={{ height: "50vh", width: "90%" }}
//                                                              />
//                                                     </Link>
//                                                     <h6>{image?.galleryName}</h6>
//                                                 </>
//                                             )
//                                         }
//                                         {/* <h4>{image?.galleryName}</h4> */}
//                                     </div>
//                                 </div>
//                             ))
//                         )
//                         }
//                     </div>
           
//             </div>
//           <div className="container">
//             {/* <div className="text-center mt-5">
//               <h1>Placement List</h1>
//             </div> */}


//             <div className="row bog_team">
//               {placementList?.gallery?.length > 0 &&
//                 placementList?.gallery?.map((image, index) => (
//                   <div
//                     className="card col-4 mt-3"
//                     key={index}
//                     style={{
//                       width: '23%',
//                       margin: '10px',
//                       padding: '10px',
//                     }}
//                   >
//                     <div className="card-image">
//                       <Link
//                         to={image?.galleryImage}
//                         data-fancybox="gallery"
//                         data-caption={`Caption Image ${index + 1}`}
//                       >
//                         <img
//                           src={image?.galleryImage}
//                           alt={image.alt || `Image ${index + 1}`}
//                           style={{ height: '50vh', width: '100%' }}
//                         />
//                       </Link>
//                       <h4>{image?.galleryName}</h4>
//                     </div>
//                   </div>
//                 ))}
//             </div>


            
//             <div>
//               {placementList?.blogs?.length > 0 && (
//                 <>
//                   {placementList?.blogs?.map((OverviewBlogDeatilsResult, index) => {
//                     return (
//                       <div key={index} className="blog-content-container">
//                         {ReactHtmlParser(OverviewBlogDeatilsResult?.content)}
//                       </div>
//                     );
//                   })}
//                 </>
//               )}
//             </div>

//             <div className="row bog_team">
//               {placementList?.BOG?.length > 0 && (
//                 <>
//                   {placementList?.BOG?.map((OverViewBlogDetails) => (
//                     <div className="col-md-4">
//                       <div className="team-card style2">
//                         <div className="team-img-wrap">
//                           <div className="team-img">
//                             <img src={OverViewBlogDetails?.imageLink} alt="Team" />
//                           </div>
//                         </div>
//                         <div className="team-content">
//                           <h3 className="team-title">{OverViewBlogDetails?.name}</h3>
//                           <p>{OverViewBlogDetails?.companyName}</p>
//                           <span className="team-desig text-blue">
//                             {OverViewBlogDetails?.designation}
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </>
//               )}
//             </div>

//             <div className="row initiative_box">
//               {placementList?.docs?.length > 0 && (
//                 <>
//                   <h4>Please click on the view button to view the messages:</h4>
//                   {placementList?.docs?.map((OverviewDocResult) => (
//                     <div className="col-lg-3 col-md-4 col-xs-12 col-sm-6">
//                       <div className="courses-item">
//                         <div className="content">
//                           <div className="d-flex justify-content-between align-items-center">
//                             <h5>{OverviewDocResult?.fileName}</h5>
//                             <FaFilePdf size={60} color="rgb(120 155 194)" />
//                           </div>
//                           <div
//                             onClick={() =>
//                               handleClick(
//                                 OverviewDocResult?.fileUrl,
//                                 OverviewDocResult?.fileName
//                               )
//                             }
//                           >
//                             <Link to="#" className="text-decoration-none pharmabtn">
//                               View
//                               <i className="fas fa-chevron-right"></i>
//                             </Link>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//       {/* <Trained /> */}
//       <Footer />
//     </div>
//   );
// };

// export default Placement;


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/444.png';
import { GetPlacementList } from '../../api/Global';
import ReactHtmlParser from 'react-html-parser';
import Header from '../header/Header';
import { useNavigate } from 'react-router-dom';
import { FaFilePdf } from 'react-icons/fa';
import Footer from '../footer/Footer';
import { PostOverTabsData } from '../../api/Global';
import Lightbox from 'react-image-lightbox'; // Import Lightbox
import 'react-image-lightbox/style.css'; // Import Lightbox CSS
import imagebaseurl from '../../config/imageurl';
import { TiArrowUp, TiArrowDown } from "react-icons/ti";
import LazyLoad from 'react-lazyload';


const Placement = () => {
  const navigate = useNavigate();
  const [placementList, setPlacementList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placement, setPlacement] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [loading,setLoading]=useState(false);

  const [showTopIcon, setShowTopIcon] = useState(false);

    // Scroll to top
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
    // Scroll to bottom
    const scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    };
  
    // Handle scroll to toggle visibility of buttons
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
  
      if (scrollTop === 0) {
        setShowTopIcon(false); // At the top, show scroll-down button
      } else if (scrollTop + clientHeight >= scrollHeight) {
        setShowTopIcon(true); // At the bottom, show scroll-up button
      } else {
        setShowTopIcon(scrollTop > 0); // Show scroll-up button when scrolling
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


  const HandleGetPlacementList = async () => {
    setIsLoading(true);
    try {
      const response = await GetPlacementList();
      console.log("HandleGetPlacementList", response?.data?.data?.tabs);
      setPlacementList(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
    }
  };

  const HandleActiveTabs = (id) => {
    setActiveKey(id);
    GetPlacementView(id);
  };

  const GetPlacementView = async (id) => {
    try {
      const response = await PostOverTabsData(id);
      setPlacement(response?.data?.data);
      setImages(response?.data?.data?.gallery || []); 
    } catch (error) {
      console.log("error", error);
    }
  };

  // const handleImageClick = (index) => {
  //   setIsOpen(true);
  //   setPhotoIndex(index);
  // };

  useEffect(() => {
    HandleGetPlacementList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    GetPlacementView(activeKey);
  }, [activeKey]);

  useEffect(() => {
    const savedActiveTabId = localStorage.getItem('ActiveTabs');
    if (savedActiveTabId) {
      setActiveKey(savedActiveTabId);
    } else if (placementList && placementList?.tabs?.length > 0) {
      setActiveKey(placementList?.tabs[0]?._id);
    }
  }, [placementList ,activeKey]);

  const handleImageClick = async (index) => {
    setLoading(true); // Set loading to true before preloading
    const imageToLoad = new Image();
    imageToLoad.src =`${imagebaseurl}/${images[index].galleryImage}` 

    // Wait for the image to load
    imageToLoad.onload = () => {
        setLoading(false); // Set loading to false after loading
        setPhotoIndex(index);
        setIsOpen(true);
    };

    // Handle image loading errors
    imageToLoad.onerror = () => {
        setLoading(false); // Stop loading on error
        console.error('Error loading image:', images[index].galleryImage);
        alert('Failed to load image, please try again later.');
    };
};

  
  return (
    <div>
      {isLoading ? (
        <div className="chat-window text-center">
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            margin: 'auto',
            zIndex: '999',
          }} className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
        e--ua-chrome e--ua-webkit loaded"
          data-elementor-device-mode="desktop">
          <Header />
          <section
            className="about-breadcrumb breadcumb-bg"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '400px',
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb-content">
                    <h2 className="heading-title">Training & Placement Cell</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='row text-center m-3'>
            <div className='row text-center m-3'>
              {placementList?.tabs?.map((placementListResult, index) => (
                <div key={index} className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
                  <div className={`p-2 m-1 ${activeKey === placementListResult?._id ? "active-tab1" : "inactive-tab1"}`}
                    onClick={() => {
                      HandleActiveTabs(placementListResult?._id);
                      localStorage.setItem("ActiveTabs", placementListResult?._id);
                    }}>
                    <p>{placementListResult?.name}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="row bog_team">
              {placement?.gallery?.length > 0 && (
                placement?.gallery?.map((image, index) => (
                  // <div className="card col-4 mt-3" key={index}>
                  <div className="col-12 col-md-4 col-lg-3 mt-4 d-flex " key={index}>
                    <LazyLoad className="card w-100">
                      <img
                        src={`${imagebaseurl}/${image?.galleryImage}`}
                        alt={image.alt || `Image ${index + 1}`}
                        // style={{ height: "50vh", width: "90%", cursor: "pointer" }}
                        onClick={() => handleImageClick(index)}
                      />
                      <h6>{image?.galleryName}</h6>
                    </LazyLoad>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Lightbox component */}
          {isOpen && (
        
            <Lightbox
              mainSrc={`${imagebaseurl}/${images[photoIndex]?.galleryImage}`}
              nextSrc={`${imagebaseurl}/${images[(photoIndex + 1) % images.length]?.galleryImage}`}
              prevSrc={`${imagebaseurl}/${images[(photoIndex + images.length - 1) % images.length]?.galleryImage}`}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
          )}
                {loading && (<div className="spinner">Loading...</div>)} {/* Display loading spinner */}


{showTopIcon ? (
        <Link
          id="scrollUp"
          to="#"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "2147483647",
            backgroundColor: "red",
            color: "#fff",
            padding: "10px",
            borderRadius: "50%",
            textAlign: "center",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          <TiArrowUp style={{ fontSize: "24px" }} />
        </Link>
      ) : (
        <Link
          id="scrollBottom"
          to="#"
          onClick={scrollToBottom}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "2147483647",
            backgroundColor: "red",
            color: "#fff",
            padding: "10px",
            borderRadius: "50%",
            textAlign: "center",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          <TiArrowDown style={{ fontSize: "24px" }} />
        </Link>
      )}

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Placement;





