import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/CDGI Logo.png";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { IoLogoInstagram } from "react-icons/io5";
import { CiYoutube } from "react-icons/ci";
import { FaLinkedinIn } from "react-icons/fa";
import "../../assets/css/style.min.css";
import {
  GetCategory,
  PostOverTabsData,
  GetSettingsAdd,
} from "../../api/Global";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FaBars } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import imagebaseurl from "../../config/imageurl";
// import { Button } from 'react-bootstrap';
// import { Button } from 'bootstrap';
const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  const [OverViewPageDetails, setOverViewPageDetails] = useState({});
  const [showSideDropdown, setShowSideDropdown] = useState(false);
  const [sideDropdownData, setSideDropdownData] = useState([]);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  // const [icons,setIcons] =useState([])
  // Fetch the Category List

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetSettingsAdd();
        if (response.data.success) {
          setData(response.data.data);
        } else {
          setError("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
        setError("Error fetching settings");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const HandleGetCatgoryList = async () => {
      setIsLoading(true);
      try {
        const response = await GetCategory();
        setCategoryList(response?.data?.data || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
        console.log("error", error);
      }
    };
    HandleGetCatgoryList();
  }, []);

  // Fetch Overview Data for Subcategory
  const GetOverDataDetails = async (id) => {
    try {
      const response = await PostOverTabsData(id);
      setOverViewPageDetails(response?.data?.data || {});
      setSideDropdownData(response?.data?.data?.subcategories || []);
      setActiveSubcategory(id);
      setShowSideDropdown(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   const StudentTest = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await Studentstestimonial();
  //       setCategoryList(response?.data?.data || []);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(true);
  //       console.log("error", error);
  //     }
  //   };
  //   StudentTest();
  // }, []);


  const handleLogoClick = () => {
    // localStorage.removeItem("College_id");
    // localStorage.removeItem("instituteIcon");

    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <div id="page-footer-wrapper" className="page-footer-wrapper">
        <div className="page-footer elementor-footer" id="page-footer">
          <div
            data-elementor-type="footer"
            data-elementor-id="3005"
            className="elementor elementor-3005 elementor-location-footer"
            data-elementor-post-type="elementor_library"
          >
            <div className="elementor-section-wrap">
              <section className="elementor-section elementor-top-section elementor-element elementor-element-b34f9e9 elementor-section-boxed elementor-section-gap-beside-yes elementor-section-height-default elementor-section-height-default elementor-section-column-vertical-align-stretch">
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b92deb7"
                    data-id="b92deb7"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <section
                          className="pt-5 elementor-section elementor-inner-section elementor-element elementor-element-487d45a elementor-section-gap-beside-no elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section-column-vertical-align-stretch"
                          data-id="487d45a"
                          data-element_type="section"
                        >
                          <div className="elementor-column">
                            <div className="elementor-spacer-inner"></div>
                          </div>
                          <div className="container">
                            <div className="row">
                              {/* <div className="elementor-element elementor-element-27fee3d elementor-widget elementor-widget-image"
                                                                    data-id="27fee3d" data-element_type="widget"
                                                                    data-widget_type="image.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-image" onClick={() => {localStorage.removeItem("College_id")
															                localStorage.removeItem("instituteIcon")}}>
                                                                            <Link to="/">
                                                                                <img style={{width:"100",height:"100"}}
                                                                                    src={logo}
                                                                                    className="Logo" alt="" /> </Link>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                              <div
                                className="elementor-column col-sm-6 col-md-6 col-lg-4 col-xl-2 elementor-inner-column elementor-element"
                                data-id="27fee3d"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-7711ffe elementor-widget elementor-widget-tm-heading"
                                      data-id="7711ffe"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          {/* <div className="heading-primary-wrap">
                                                                                            <h4
                                                                                                className="heading-primary elementor-heading-title">
                                                                                                Get in touch</h4>
                                                                                        </div> */}
                                          <div
                                            className="elementor-image pe-3 "
                                            onClick={handleLogoClick}

                                          //      onClick={() => {localStorage.removeItem("College_id")
                                          // localStorage.removeItem("instituteIcon")}}
                                          >
                                            <Link to="#">
                                              {/* <img
                                                                                            // src={logo}
                                                                                         //     className="Logo" width="150" alt="" /> </Link>  */}

                                              {data.footerLogo && (
                                                <img
                                                  src={`${imagebaseurl}/${data.footerLogo}`}
                                                  className="Logo"
                                                  width="150"
                                                  alt=""
                                                />
                                              )}
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="elementor-column col-sm-6 col-md-6 col-lg-4 col-xl-3 elementor-inner-column elementor-element elementor-element-dd3a368"
                                data-id="dd3a368"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-fdca4d1 elementor-widget elementor-widget-tm-heading"
                                      data-id="fdca4d1"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <h4 className="heading-primary elementor-heading-title">
                                              Quick Links
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-38c8b3a unicamp-list-layout-block elementor-widget elementor-widget-tm-list"
                                      data-id="38c8b3a"
                                      data-element_type="widget"
                                      data-widget_type="tm-list.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="unicamp-list">
                                          {/* <div
                                                                                            className="item elementor-repeater-item-de25e53">

                                                                                            <a className="link" href="#about/">
                                                                                                <div className="list-header">
                                                                                                    <div className="text-wrap">
                                                                                                        <div className="text">
                                                                                                            About Us</div>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </a>
                                                                                        </div> */}
                                          {/* {CategoryList?.map(
                                            (CategoryListResult, index) => {
                                              if (
                                                CategoryListResult?.is_footer_category ==
                                                true
                                              ) {
                                                return (
                                                  <>
                                                    {CategoryListResult?.type ===
                                                      "text" ||
                                                    CategoryListResult?.type ===
                                                      "both" ? (
                                                      <div
                                                        key={index}
                                                        className="item elementor-repeater-item-4f48939"
                                                      >
                                                        <Link
                                                          className="link"
                                                        //   to="https://cdgi.accsofterp.com/AccSoft_CDGI/StudentLogin.aspx"
                                                        to={`/Overview/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                        >
                                                          <div className="list-header">
                                                            <div className="text-wrap">
                                                              <div className="text">
                                                                {
                                                                  CategoryListResult?.name
                                                                }
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Link>
                                                      </div>
                                                    ) : <Link to={`/Pdf_Open/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                     >
                                                {CategoryListResult?.name}
                                                                                                                                                                                                    </Link>}
                                                  </>
                                                );
                                              }
                                              return null; 
                                            }
                                          )} */}
                                          {CategoryList?.filter(
                                            (item) =>
                                              item?.is_footer_category === true
                                          )
                                            .slice(0, 5) // ✅ Slicing after filtering
                                            .map(
                                              (CategoryListResult, index) => (
                                                <div
                                                  key={index}
                                                  className="footer-category-item"
                                                >
                                                  {/* ✅ Text & Both condition */}
                                                  {CategoryListResult?.type ===
                                                    "text" ||
                                                    CategoryListResult?.type ===
                                                    "both" ? (
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <Link
                                                        className="link"
                                                        to={`/Overview/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ) : CategoryListResult?.type ===
                                                    "pdf" ? (
                                                    /* ✅ PDF Type condition */
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <Link
                                                        className="link"
                                                        to={`/Pdf_Open/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ) : CategoryListResult?.type ===
                                                    "link" &&
                                                    CategoryListResult?.url ? (
                                                    /* ✅ External Link condition */
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <a
                                                        className="link"
                                                        href={
                                                          CategoryListResult.url
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              )
                                            )}

                                          {/* <div
                                                                                        className="item elementor-repeater-item-4f48939">

                                                                                        <Link className="link" to="https://cdgi.accsofterp.com/AccSoft_CDGI/StudentLogin.aspx">
                                                                                            <div className="list-header">
                                                                                                <div className="text-wrap">
                                                                                                    <div className="text">
                                                                                                        Online Fee
                                                                                                        Payment</div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </Link>
                                                                                    </div>
                                                                                    <div
                                                                                        className="item elementor-repeater-item-882f063">

                                                                                        <Link className="link" to="/Ugc_2f">
                                                                                            <div className="list-header">
                                                                                                <div className="text-wrap">
                                                                                                    <div className="text">
                                                                                                        UGC 2(f)
                                                                                                        Certificate
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </Link>
                                                                                    </div>
                                                                                    <div
                                                                                        className="item elementor-repeater-item-9052523">

                                                                                        <Link className="link" to="/AntiRangging">
                                                                                            <div className="list-header">
                                                                                                <div className="text-wrap">
                                                                                                    <div className="text">
                                                                                                        Anti-Ragging
                                                                                                        Cell</div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </Link>
                                                                                    </div>
                                                                                    <div
                                                                                        className="item elementor-repeater-item-450b61a">

                                                                                        <Link className="link" to="/Student_Testimonial">
                                                                                            <div className="list-header">
                                                                                                <div className="text-wrap">
                                                                                                    <div className="text">
                                                                                                        Student
                                                                                                        Testimonial
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </Link>
                                                                                    </div>
                                                                                    <div
                                                                                        className="item elementor-repeater-item-450b61a">

                                                                                        <Link className="link" to="https://alumni.cdgi.edu.in/">
                                                                                            <div className="list-header">
                                                                                                <div className="text-wrap">
                                                                                                    <div className="text">
                                                                                                        CDGI Alumni
                                                                                                        Portal</div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </Link>
                                                                                    </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column col-sm-6 col-md-6 col-lg-4 col-xl-3  elementor-inner-column elementor-element elementor-element-fabdef2"
                                data-id="fabdef2"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-dd385b4 elementor-widget elementor-widget-tm-heading"
                                      data-id="dd385b4"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <h4 className="heading-primary elementor-heading-title">
                                              Links
                                            </h4>
                                          </div>
                                          <div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-030c78b unicamp-list-layout-block elementor-widget elementor-widget-tm-list"
                                      data-id="030c78b"
                                      data-element_type="widget"
                                      data-widget_type="tm-list.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="unicamp-list">
                                          {CategoryList?.filter(
                                            (item) =>
                                              item?.is_footer_category === true
                                          )
                                            .slice(5) // ✅ Slicing after filtering
                                            .map(
                                              (CategoryListResult, index) => (
                                                <div
                                                  key={index}
                                                  className="footer-category-item"
                                                >
                                                  {/* ✅ Text & Both condition */}
                                                  {CategoryListResult?.type ===
                                                    "text" ||
                                                    CategoryListResult?.type ===
                                                    "both" ? (
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <Link
                                                        className="link"
                                                        to={`/Overview/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ) : CategoryListResult?.type ===
                                                    "pdf" ? (
                                                    /* ✅ PDF Type condition */
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <Link
                                                        className="link"
                                                        to={`/Pdf_Open/${CategoryListResult?._id}/${CategoryListResult?.slug}`}
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ) : CategoryListResult?.type ===
                                                    "link" &&
                                                    CategoryListResult?.url ? (
                                                    /* ✅ External Link condition */
                                                    <div className="item elementor-repeater-item-4f48939">
                                                      <a
                                                        className="link"
                                                        href={
                                                          CategoryListResult.url
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <div className="list-header">
                                                          <div className="text-wrap">
                                                            <div className="text">
                                                              {
                                                                CategoryListResult?.name
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              )
                                            )}
                                          {/* <div className="item elementor-repeater-item-de25e53">
                                            <Link className="link" to="/Wdc">
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Women Development Centre
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                          <div className="item elementor-repeater-item-4f48939">
                                            <Link className="link" to="/Bus">
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Bus Route
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                          <div className="item elementor-repeater-item-882f063">
                                            <Link
                                              className="link"
                                              to="/Mandatory_Disclosures"
                                            >
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Mandatory Disclosures
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                         
                                          <div className="item elementor-repeater-item-450b61a">
                                            <Link className="link" to="/Icc">
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Internal Complaints
                                                    Committee
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div> */}

                                          <div className="item elementor-repeater-item-450b61a">
                                            <Link
                                              className="link"
                                              to="/Privacypolicymobile"
                                            >
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Privacy & Policy
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>

                                          {/* <div className="item elementor-repeater-item-450b61a"> */}
                                            <Link
                                              className="link"
                                              to="/Terms_condition"
                                            >
                                              <div className="list-header">
                                                <div className="text-wrap">
                                                  <div className="text">
                                                    Terms & Conditions
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                      <div className="item elementor-repeater-item-450b61a">
                                        <Link className="link" to="/Student_Testimonial">
                                          <div className="list-header">
                                            <div className="text-wrap">
                                              <div className="text">
                                                Student
                                                Testimonial
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column col-sm-6 col-md-6 col-lg-4 col-xl-2 elementor-inner-column elementor-element"
                                data-id="71aded4"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-7711ffe elementor-widget elementor-widget-tm-heading"
                                      data-id="7711ffe"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          {/* <div className="heading-primary-wrap">
                                                                                            <h4
                                                                                                className="heading-primary elementor-heading-title">
                                                                                                Get in touch</h4>
                                                                                        </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-14ae182 unicamp-link-animate-border-02 elementor-widget elementor-widget-tm-heading"
                                      data-id="14ae182"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <div className="heading-primary elementor-heading-title">
                                              <a href="tel:+917314243600">
                                                {/* <mark>+91
                                                                                                7314243600</mark> */}
                                                <mark> {data.contactNo}</mark>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-abb5679 elementor-widget elementor-widget-tm-heading"
                                      data-id="abb5679"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <div className="heading-primary elementor-heading-title">
                                              {/* Village Umrikheda, Khandwa
                                                                                            Road, Indore, Madhya
                                                                                            Pradesh, Pin: */}
                                              {data.Address},
                                              {/* <div className="heading-primary elementor-heading-title">Pin: {data.pinNo}</div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-817af07 elementor-widget elementor-widget-tm-button"
                                      data-id="817af07"
                                      data-element_type="widget"
                                      data-widget_type="tm-button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-button-wrapper">
                                          <Link
                                            to="/Contact_us"
                                            className="tm-button-link tm-button style-border tm-button-custom"
                                            role="button"
                                          >
                                            {/* <div className="button-content-wrapper"> */}

                                            <span className="button-text">
                                              Get directions
                                            </span>
                                            {/* <Button className='button-text' >Get directions</Button> */}

                                            {/* </div> */}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column col-sm-6 col-md-6 col-lg-4 col-xl-2 elementor-inner-column elementor-element"
                                data-id="93649cc"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-8a6dd9d elementor-widget elementor-widget-tm-heading"
                                      data-id="8a6dd9d"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <h4 className="heading-primary elementor-heading-title">
                                              Get in touch
                                            </h4>
                                            <br />
                                            <br />
                                            {/* <p><strong>Tel</strong>+917314243600</p> */}
                                            <p>
                                              <strong>Tel</strong>{" "}
                                              {data.contactNo}
                                            </p>
                                            <p>
                                              <strong> Fax:</strong>
                                              +917314243620
                                            </p>
                                            {/* <p style={{ display: "flex" }}><strong>E-mail:</strong><a */}
                                            <p>
                                              <strong>E-mail:</strong>
                                              {/* <a
                                                                                            href="mailto:info@cdgi.edu.in?subject=Application%20for%20Job&body=I%20am%20interested%20in%20applying%20for%20a%20job."
                                                                                        >
                                                                                            info@cdgi.edu.in </a> */}

                                              <a
                                                className="social-link"
                                                href={`mailto:${data.email}`}
                                                target="_blank"
                                                rel="nofollow"
                                              >
                                                <i className="far fa-envelope"></i>{" "}
                                                {data.email}
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="elementor-element elementor-element-5e4c490 elementor-widget elementor-widget-tm-heading"
                                                                                data-id="5e4c490" data-element_type="widget"
                                                                                data-widget_type="tm-heading.default">
                                                                                <div className="elementor-widget-container">
                                                                                    <div className="tm-modern-heading">

                                                                                        <div className="heading-primary-wrap">
                                                                                            <div
                                                                                                className="heading-primary elementor-heading-title">
                                                                                                Get the latest UniCamp news
                                                                                                delivered to you inbox</div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="elementor-element elementor-element-7c883dc unicamp-mailchimp-form-style-02 unicamp-button-align-stretch elementor-widget elementor-widget-tm-mailchimp-form"
                                                                                data-id="7c883dc" data-element_type="widget"
                                                                                data-widget_type="tm-mailchimp-form.default">
                                                                                <div className="elementor-widget-container">
                                                                                    <div className="unicamp-mailchimp-form">

                                                                                        <form id="mc4wp-form-1"
                                                                                            className="mc4wp-form mc4wp-form-1259"
                                                                                            method="post" data-id="1259"
                                                                                            data-name="Subscribe">
                                                                                            <div className="mc4wp-form-fields">
                                                                                                <div style={{ display: 'flex' }} className="form-item-wrap">
                                                                                                    <div
                                                                                                        className="form-group form-item-email">
                                                                                                        <input type="email"
                                                                                                            name="EMAIL"
                                                                                                            placeholder="Your e-mail"
                                                                                                            required
                                                                                                            className="form-input" />
                                                                                                    </div>

                                                                                                    <div className="form-submit">
                                                                                                        <button
                                                                                                            className="button-submit"
                                                                                                            type="submit">
                                                                                                            <span
                                                                                                                className="button-text" style={{ Size: '30px' }}>Subscribe</span>
                                                                                                            <span
                                                                                                                className="button-icon form-icon"></span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    <div
                                      className="elementor-element elementor-element-423417a elementor-widget elementor-widget-tm-social-networks"
                                      data-id="423417a"
                                      data-element_type="widget"
                                      data-widget_type="tm-social-networks.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-social-networks style-large-icons layout-inline">
                                          <ul
                                            className="list"
                                            style={{
                                              display: "flex",
                                              fontSize: "40px",
                                            }}
                                          >
                                            {/* <li
                                                                                            className="item elementor-repeater-item-a4ebc41">
                                                                                            <Link className="link hint--bounce hint--top hint--white"
                                                                                                aria-label="Twitter"
                                                                                                to="https://x.com/CdipSmc" target="_blank"
                                                                                                rel="nofollow">
                                                                                            	
                                                                                                 

                                                                                            </Link>
                                                                                        </li> */}
                                            {/* <li
                                                                                            className="item elementor-repeater-item-3a62d63">
                                                                                            <Link className="link hint--bounce hint--top hint--white"
                                                                                                aria-label="Facebook"
                                                                                                to="https://www.facebook.com/cdgiindore" target="_blank"
                                                                                                rel="nofollow">
                                                                                                <i
                                                                                                    className><FiFacebook /></i>

                                                                                            </Link>
                                                                                        </li>
                                                                                        <li
                                                                                            className="item elementor-repeater-item-5840c98">
                                                                                            <Link className="link hint--bounce hint--top hint--white"
                                                                                                aria-label="Instagram"
                                                                                                to="https://www.instagram.com/cdgi_indore" target="_blank"
                                                                                                rel="nofollow"
                                                                                            >
                                                                                                <i
                                                                                                    className><IoLogoInstagram /></i>

                                                                                            </Link>
                                                                                        </li>
                                                                                        <li
                                                                                            className="item elementor-repeater-item-ecbdfc2">
                                                                                            <Link className="link hint--bounce hint--top hint--white"
                                                                                                aria-label="Youtube Channel"
                                                                                                to="https://www.youtube.com/@socialcdgi" target="_blank"
                                                                                                rel="nofollow"
                                                                                            >
                                                                                                <i
                                                                                                    className><CiYoutube /></i>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li
                                                                                            className="item elementor-repeater-item-ecbdfc2">
                                                                                            <Link className="link hint--bounce hint--top hint--white"
                                                                                                aria-label="Linkdin Channel"
                                                                                                to="https://www.linkedin.com/company/chameli-devi-group-of-institutions" target="_blank"
                                                                                                rel="nofollow"
                                                                                            >
                                                                                                <i
                                                                                                    className><FaLinkedinIn /></i>
                                                                                            </Link>
                                                                                        </li> */}

                                            {data.twitterLink && (
                                              <li className="item">
                                                <Link
                                                  className="link hint--bounce hint--top hint--white"
                                                  aria-label="Twitter"
                                                  to={data.twitterLink}
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <FiTwitter />
                                                </Link>
                                              </li>
                                            )}
                                            {data.fbLink && (
                                              <li className="item">
                                                <Link
                                                  className="link hint--bounce hint--top hint--white"
                                                  aria-label="Facebook"
                                                  to={data.fbLink}
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <FiFacebook />
                                                </Link>
                                              </li>
                                            )}
                                            {data.instaLink && (
                                              <li className="item">
                                                <Link
                                                  className="link hint--bounce hint--top hint--white"
                                                  aria-label="Instagram"
                                                  to={data.instaLink}
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <IoLogoInstagram />
                                                </Link>
                                              </li>
                                            )}
                                            {data.youtubeLink && (
                                              <li className="item">
                                                <Link
                                                  className="link hint--bounce hint--top hint--white"
                                                  aria-label="Youtube Channel"
                                                  to={data.youtubeLink}
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <CiYoutube />
                                                </Link>
                                              </li>
                                            )}
                                            {data.linkedinLink && (
                                              <li className="item">
                                                <Link
                                                  className="link hint--bounce hint--top hint--white"
                                                  aria-label="LinkedIn Channel"
                                                  to={data.linkedinLink}
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <FaLinkedinIn />
                                                </Link>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-773f903 elementor-widget elementor-widget-spacer"
                                      data-id="773f903"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="elementor-section py-3 border-top mt-4">
                          <div className="elementor-container elementor-column-gap-extended">
                            <div className="elementor-row">
                              <div
                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-719f960"
                                data-id="719f960"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-d1a0172 elementor-widget elementor-widget-tm-heading"
                                      data-id="d1a0172"
                                      data-element_type="widget"
                                      data-widget_type="tm-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">
                                          <div className="heading-primary-wrap">
                                            <div className="heading-primary elementor-heading-title">
                                              Gram Umrikheda, Near Toll Naka,
                                              Khandwa Road, Indore, Madhya
                                              Pradesh Pin:- 452020 | All Rights
                                              Reserved. (C) Chameli Devi Group
                                              of Institutions
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
