import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/444.png'
// import Trained from '../Trained/Trained'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import { ApplyHere } from '../../api/Global'

const Apply_for_job = () => {
	const [postAppliedFor, setPostAppliedFor] = useState("");
	const [department, setDepartment] = useState("");
	const [firstName, setFirstName] = useState("");
	const [surname, setSurname] = useState("");
	const [gender, setGender] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [addressForCorrespondence, setAddressForCorrespondence] = useState("");
	const [telephoneNo, setTelephoneNo] = useState("");
	const [cellNo, setCellNo] = useState("");
	const [alternateCellNo, setAlternateCellNo] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [alternateEmail, setAlternateEmail] = useState("");
	const [educationalQualification, setEducationalQualification] = useState({
		ug: {
			yearOfPassing: '',
			passingPercentage: '',
			divisionOfPassing: '',
			fieldOfSpecialization: '',
		},
		pg: {
			yearOfPassing: '',
			passingPercentage: "",
			divisionOfPassing: "",
			fieldOfSpecialization: ""
		},
		mphil: {
			yearOfPassing: "",
			passingPercentage: "",
			divisionOfPassing: "",
			fieldOfSpecialization: ""
		},
		phd: {
			yearOfPassing: "",
			passingPercentage: "",
			divisionOfPassing: "",
			fieldOfSpecialization: ""
		}
	});

	const [nationalStateLevelExamination, setNationalStateLevelExamination] = useState({
		qualifiedExamName: "",
		qualifyingYear: ""
	});
	const [workExperience, setWorkExperience] = useState({
		teaching: "",
		industry: "",
		research: ""
	});
	const [resume, setResume] = useState("");

	const HandleEducationalQualification = (e, level) => {
		const { name, value } = e.target;
	
		setEducationalQualification((prevState) => ({
			...prevState,
			[level]: {
				...prevState[level],
				[name]: value,  // Dynamically update the field (in this case, yearOfPassing)
			}
		}));
	};
	

	// Handle change for National State Level Examination inputs
	const handleNationalExamChange = (e) => {
		const { name, value } = e.target;
	
		setNationalStateLevelExamination((prevState) => ({
			...prevState,
				[name]: value,
		}));
	};
	

	// Handle change for National State Level Examination inputs
	const handleWorkExperienceChange = (e) => {
		const { name, value } = e.target;

		setWorkExperience((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const HandleApplyHere = async () => {
		const formdata = new FormData();
		formdata.append("postAppliedFor", postAppliedFor)
		formdata.append("department", department)
		formdata.append("firstName", firstName)
		formdata.append("surname", surname)
		formdata.append("gender", gender)
		formdata.append("dateOfBirth", dateOfBirth)
		formdata.append("addressForCorrespondence", addressForCorrespondence)
		formdata.append("telephoneNo", telephoneNo)
		formdata.append("cellNo", cellNo)
		formdata.append("alternateCellNo", alternateCellNo)
		formdata.append("emailAddress", emailAddress)
		formdata.append("alternateEmail", alternateEmail)
		formdata.append("educationalQualification[ug][yearOfPassing]", educationalQualification.ug.yearOfPassing)
		formdata.append("educationalQualification[pg][yearOfPassing]", educationalQualification.pg.yearOfPassing)

		formdata.append("educationalQualification[mphil][yearOfPassing]", educationalQualification.mphil.yearOfPassing)
		formdata.append("educationalQualification[phd][yearOfPassing]", educationalQualification.phd.yearOfPassing)
		// formdata.append("educationalQualification[pg][yearOfPassing]", educationalQualification.pg.yearOfPassing)
		// formdata.append("educationalQualification[pg][yearOfPassing]", educationalQualification.pg.yearOfPassing)
		
		
		formdata.append("educationalQualification[ug][passingPercentage]", educationalQualification.ug.passingPercentage)
		formdata.append("educationalQualification[pg][passingPercentage]", educationalQualification.pg.passingPercentage)
		formdata.append("educationalQualification[mphil][passingPercentage]", educationalQualification.mphil.passingPercentage)
		formdata.append("educationalQualification[ug][divisionOfPassing]", educationalQualification.ug.divisionOfPassing)
		formdata.append("educationalQualification[pg][divisionOfPassing]", educationalQualification.pg.divisionOfPassing)
		formdata.append("educationalQualification[mphil][divisionOfPassing]", educationalQualification.mphil.divisionOfPassing)

		formdata.append("educationalQualification[ug][fieldOfSpecialization]", educationalQualification.ug.fieldOfSpecialization)
		formdata.append("educationalQualification[pg][fieldOfSpecialization]", educationalQualification.pg.fieldOfSpecialization)
		formdata.append("educationalQualification[mphil][fieldOfSpecialization]", educationalQualification.mphil.fieldOfSpecialization)
		formdata.append("educationalQualification[phd][fieldOfSpecialization]", educationalQualification.phd.fieldOfSpecialization)

		formdata.append("nationalStateLevelExamination[qualifiedExamName]", nationalStateLevelExamination.qualifiedExamName)
		formdata.append("nationalStateLevelExamination[qualifyingYear]", nationalStateLevelExamination.qualifyingYear)
		formdata.append("workExperience[teaching]", workExperience.teaching);
		formdata.append("workExperience[industry]", workExperience.industry);
		formdata.append("workExperience[research]", workExperience.research);
		formdata.append("resume", resume)
		try {
			const response = await ApplyHere(formdata);
			console.log("HandleApplyHere_formdata", response)
			if(response?.data?.status == true){
				alert("Application form created successfully")
				setEducationalQualification({
					ug: {
					  yearOfPassing: '',
					  passingPercentage: '',
					  divisionOfPassing: '',
					  fieldOfSpecialization: '',
					},
					pg: {
					  yearOfPassing: '',
					  passingPercentage: '',
					  divisionOfPassing: '',
					  fieldOfSpecialization: ''
					},
					mphil: {
					  yearOfPassing: '',
					  passingPercentage: '',
					  divisionOfPassing: '',
					  fieldOfSpecialization: ''
					},
					phd: {
					  yearOfPassing: '',
					  passingPercentage: '',
					  divisionOfPassing: '',
					  fieldOfSpecialization: ''
					}
				  });
				  setPostAppliedFor('');
				  setDepartment('');
				  setFirstName('');
				  setSurname('');
				  setGender('');
				  setDateOfBirth('');
				  setAddressForCorrespondence('');
				  setTelephoneNo('');
				  setCellNo('');
				  setAlternateCellNo('');
				  setEmailAddress('');
				  setAlternateEmail('');
				  setNationalStateLevelExamination({
					qualifiedExamName: '',
					qualifyingYear: ''
				  });
				  setWorkExperience({
					teaching: '',
					industry: '',
					research: ''
				  });
				  setResume(null);
			}

		} catch (error) {
       console.log("error",error)
		}
	}
	
	return (
		<div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
			<Header />
			< section className="about-breadcrumb breadcumb-bg"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					width: '100%',
					height: '400px'
				}}>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="breadcrumb-content">
								<ul className="breadcrumb">
									{/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link >/</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Application Form</li> */}
								</ul>
								<h2 className="heading-title">Application Form</h2>
							</div>
						</div>
					</div>
				</div>
			</section >
			<div className="apply_jobs">
				<div className="container custome_container">
					<div className="row">
						<div className="col-md-12">
							<div className="text12" style={{ marginLeft: '20px' }}>
								<form action="#" onSubmit={HandleApplyHere} method="post" enctype="#" name="application-form"
									target="upload_target">
									<table width="100%" border="0" cellspacing="0" cellpadding="10">
										<tbody>
											<tr bgcolor="#F0F0F0">
												<td width="21%"> Post Applied for: <span className="style1">*</span> </td>
												<td width="33%"><label>
													<select className="form-control" name='postAppliedFor'
														onChange={(e) => setPostAppliedFor(e.target.value)}>
														<option value="">Select Post</option>
														<option value="Principal">Principal</option>
														<option value="Professor">Professor</option>
														<option value="Associate Professor">Associate Professor</option>
														<option value="Assistant Professor">Assistant Professor</option>
														<option value="Lecturer">Lecturer</option>
														<option value="Librarian">Librarian</option>
														<option value="Sports Officer">Sports Officer</option>
														<option value="Laboratory Technician">Laboratory Technician</option>
														<option value="Book Lifter">Book Lifter</option>
														<option value="Senior-Manager">Senior-Manager</option>
														<option value="Trainer-Technical">Trainer-Technical</option>
														<option value="Trainer-Apptitude">Trainer-Apptitude</option>
														<option value="Trainer-SoftSkill">Trainer-SoftSkill</option>
														<option value="Store Incharge">Store Incharge</option>
														<option value="Receptionist">Receptionist</option>
														<option value="Office Superintendent">Office Superintendent</option>
														<option value="Office Clerks">Office Clerks</option>
													</select>
												</label></td>
												<td width="18%">Department: <span className="style1">*</span> </td>
												<td width="28%">
													<select className="form-control" name='department'
														onChange={(e) => setDepartment(e.target.value)}>
														<option value="">Select Department</option>
														<option value="UG-General Management">UG-General Management</option>
														<option value="UG-Accounts">UG-Accounts</option>
														<option value="UG-Finance">UG-Finance</option>
														<option value="UG-Economics">UG-Economics</option>
														<option value="UG-Physics">UG-Physics</option>
														<option value="UG-Mathematics">UG-Mathematics</option>
														<option value="UG-Computer Science">UG-Computer Science</option>
														<option value="UG-Electronics">UG-Electronics</option>
														<option value="UG-OR &amp; QT">UG-OR &amp; QT</option>
														<option value="UG-Hindi">UG-Hindi</option>
														<option value="UG-English">UG-English</option>
														<option value="UG-Library Science">UG-Library Science</option>
														<option value="UG-Physical Education">UG-Physical Education</option>
														<option value="Electronics &amp; Communication">Electronics &amp; Communication</option>
														<option value="Electrical &amp; Electronics">Electrical &amp; Electronics</option>
														<option value="Mechanical Engineering">Mechanical Engineering</option>
														<option value="Information Technology">Information Technology</option>
														<option value="Computer Science &amp; Engg">Computer Science &amp; Engg</option>
														<option value="Civil Engineering">Civil Engineering</option>
														<option value="Physics">Physics</option>
														<option value="Mathematics">Mathematics</option>
														<option value="English">English</option>
														<option value="MBA-HR">MBA-HR</option>
														<option value="MBA-Marketing">MBA-Marketing</option>
														<option value="MBA-Finance">MBA-Finance</option>
														<option value="MBA-IT">MBA-IT</option>
														<option value="MBA - General Management">MBA - General Management</option>
														<option value="Chemistry">Chemistry</option>
														<option value="Technical-Trainer">Technical-Trainer</option>
														<option value="Trainer-SoftSkill">Trainer-SoftSkill</option>
														<option value="Trainer-Apptitude">Trainer-Apptitude</option>
														<option value="Placement">Placement</option>
														<option value="B PHARMA/D PHARMA -Pharmaceutical Chemistry">B PHARMA/D PHARMA -Pharmaceutical Chemistry
														</option>
														<option value="B PHARMA/D PHARMA -Pharmacology">B PHARMA/D PHARMA -Pharmacology</option>
														<option value="B PHARMA/D PHARMA -Pharmacognosy">B PHARMA/D PHARMA -Pharmacognosy</option>
														<option value="B PHARMA/D PHARMA -Pharmaceutics">B PHARMA/D PHARMA -Pharmaceutics</option>
														<option value="B PHARMA/D PHARMA - Non teaching">B PHARMA/D PHARMA - Non teaching</option>
														<option value="B PHARMA/D PHARMA - Non teaching">B PHARMA/D PHARMA - Non teaching</option>
														<option value="LAW-LLB/BBALLB/BALLB-Management">LAW-LLB/BBALLB/BALLB-Management</option>
														<option value="LAW-LLB/BBALLB/BALLB-History">LAW-LLB/BBALLB/BALLB-History</option>
														<option value="LAW-LLB/BBALLB/BALLB-Political Science">LAW-LLB/BBALLB/BALLB-Political Science
														</option>
														<option value="LAW-LLB/BBALLB/BALLB-Economic">LAW-LLB/BBALLB/BALLB-Economic</option>
														<option value="LAW-LLB/BBALLB/BALLB-English">LAW-LLB/BBALLB/BALLB-English</option>
														<option value="LAW-LLB/BBALLB/BALLB-Hindi">LAW-LLB/BBALLB/BALLB-Hindi</option>
													</select>
												</td>
											</tr>
											<tr>
												<td>First Name: <span className="style1">*</span></td>
												<td><input name="firstName" value={firstName}
													onChange={(e) => setFirstName(e.target.value)} type="text" id="first_name" size="25"
												/></td>
												<td>Surname: <span className="style1">*</span> </td>
												<td><input name="surname" value={surname} onChange={(e) => setSurname(e.target.value)} type="text" id="last_name" size="25" /></td>
											</tr>
											<tr bgcolor="#F0F0F0">
												<td>Gender: <span className="style1">*</span> </td>
												<td><label>
													<input type="radio" name="gender" value="Male" onChange={(e) => setGender(e.target.value)} id="RadioGroup1_0" />
													Male</label>
													<label>
														<input type="radio" name="gender" value="FeMale" onChange={(e) => setGender(e.target.value)} id="RadioGroup1_1" />
														Female</label>
												</td>
												<td>Date of Birth: <span className="style1">*</span></td>
												<input name="dateOfBirth" value={dateOfBirth} type="date" className="textbox form-control" id="dob"
													onChange={(e) => setDateOfBirth(e.target.value)}
													size="25" />

											</tr>
											<tr>
												<td>Address for Correspondence:<span className="style1">*</span></td>
												<td colspan="3"><label>
													<textarea name="addressForCorrespondence" value={addressForCorrespondence}
														onChange={(e) => setAddressForCorrespondence(e.target.value)} id="address" cols="50"
														rows="3"></textarea>
												</label></td>
											</tr>
											<tr bgcolor="#F0F0F0">
												<td>Telephone No.<span className="style1">*</span></td>
												<td colspan="3"><label></label>
													<label>
														<input className='form-control' name="telephoneNo" value={telephoneNo}
															onChange={(e) => setTelephoneNo(e.target.value)} type="number" id="telephone" size="25" />
													</label>
												</td>
											</tr>
											<tr>
												<td>Cell No.<span className="style1">*</span></td>
												<td><input name="cellNo" type="number" value={cellNo}
													onChange={(e) => setCellNo(e.target.value)} id="cell1" size="25" /></td>
												<td>Alternate Cell No.</td>
												<td><label></label><label>
													<input type="text" value={alternateCellNo}
														onChange={(e) => setAlternateCellNo(e.target.value)} id="cell2" size="25" />
												</label></td>
											</tr>
											<tr bgcolor="#F0F0F0">
												<td>Email Address:<span className="style1">*</span></td>
												<td><input name="emailAddress" type="text" value={emailAddress}
													onChange={(e) => setEmailAddress(e.target.value)} id="emailid1" size="25" /></td>
												<td>Alternate Email</td>
												<td><label></label><label>
													<input value={alternateEmail}
														onChange={(e) => setAlternateEmail(e.target.value)} type="text" id="emailid2" size="25" />
												</label></td>
											</tr>
											<tr bgcolor="#ECE9D8">
												<td colspan="4"><strong>Educational Qualification : -</strong></td>
											</tr>
											<tr>
												<td colspan="4">

													<table width="100%" border="0" cellspacing="0" cellpadding="10">
														<tbody>
															<tr bgcolor="#64CDFF">
																<th width="29%" scope="col">&nbsp;</th>
																<th width="19%" scope="col">UG</th>
																<th width="19%" scope="col">PG</th>
																<th width="17%" scope="col">M.Phil</th>
																<th width="16%" scope="col">Ph.D</th>
															</tr>
															<tr>
																<th scope="row">
																	<div align="left">Year of Passing: <span
																		className="style1">*</span></div>
																</th>
																<td>
																	<select className="form-control" name="yearOfPassing"
																		value={educationalQualification.ug.yearOfPassing}
																		onChange={(e) => HandleEducationalQualification(e, 'ug')}>
																		<option value="0">Select</option>
																		<option value="1950">1950</option>
																		<option value="1951">1951</option>
																		<option value="1952">1952</option>
																		<option value="1953">1953</option>
																		<option value="1954">1954</option>
																		<option value="1955">1955</option>
																		<option value="1956">1956</option>
																		<option value="1957">1957</option>
																		<option value="1958">1958</option>
																		<option value="1959">1959</option>
																		<option value="1960">1960</option>
																		<option value="1961">1961</option>
																		<option value="1962">1962</option>
																		<option value="1963">1963</option>
																		<option value="1964">1964</option>
																		<option value="1965">1965</option>
																		<option value="1966">1966</option>
																		<option value="1967">1967</option>
																		<option value="1968">1968</option>
																		<option value="1969">1969</option>
																		<option value="1970">1970</option>
																		<option value="1971">1971</option>
																		<option value="1972">1972</option>
																		<option value="1973">1973</option>
																		<option value="1974">1974</option>
																		<option value="1975">1975</option>
																		<option value="1976">1976</option>
																		<option value="1977">1977</option>
																		<option value="1978">1978</option>
																		<option value="1979">1979</option>
																		<option value="1980">1980</option>
																		<option value="1981">1981</option>
																		<option value="1982">1982</option>
																		<option value="1983">1983</option>
																		<option value="1984">1984</option>
																		<option value="1985">1985</option>
																		<option value="1986">1986</option>
																		<option value="1987">1987</option>
																		<option value="1988">1988</option>
																		<option value="1989">1989</option>
																		<option value="1990">1990</option>
																		<option value="1991">1991</option>
																		<option value="1992">1992</option>
																		<option value="1993">1993</option>
																		<option value="1994">1994</option>
																		<option value="1995">1995</option>
																		<option value="1996">1996</option>
																		<option value="1997">1997</option>
																		<option value="1998">1998</option>
																		<option value="1999">1999</option>
																		<option value="2000">2000</option>
																		<option value="2001">2001</option>
																		<option value="2002">2002</option>
																		<option value="2003">2003</option>
																		<option value="2004">2004</option>
																		<option value="2005">2005</option>
																		<option value="2006">2006</option>
																		<option value="2007">2007</option>
																		<option value="2008">2008</option>
																		<option value="2009">2009</option>
																		<option value="2010">2010</option>
																		<option value="2011">2011</option>
																		<option value="2012">2012</option>
																		<option value="2013">2013</option>
																		<option value="2014">2014</option>
																		<option value="2015">2015</option>
																		<option value="2016">2016</option>
																		<option value="2017">2017</option>
																		<option value="2018">2018</option>
																		<option value="2019">2019</option>
																		<option value="2020">2020</option>
																		<option value="2021">2021</option>
																		<option value="2022">2022</option>
																		<option value="2023">2023</option>
																		<option value="2024">2024</option>
																		<option value="2025">2025</option>
																	</select>
																</td>
																<td><select className="form-control" name="yearOfPassing"
																	value={educationalQualification.pg.yearOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'pg')}>
																	<option value="0">Select</option>
																	<option value="1950">1950</option>
																	<option value="1951">1951</option>
																	<option value="1952">1952</option>
																	<option value="1953">1953</option>
																	<option value="1954">1954</option>
																	<option value="1955">1955</option>
																	<option value="1956">1956</option>
																	<option value="1957">1957</option>
																	<option value="1958">1958</option>
																	<option value="1959">1959</option>
																	<option value="1960">1960</option>
																	<option value="1961">1961</option>
																	<option value="1962">1962</option>
																	<option value="1963">1963</option>
																	<option value="1964">1964</option>
																	<option value="1965">1965</option>
																	<option value="1966">1966</option>
																	<option value="1967">1967</option>
																	<option value="1968">1968</option>
																	<option value="1969">1969</option>
																	<option value="1970">1970</option>
																	<option value="1971">1971</option>
																	<option value="1972">1972</option>
																	<option value="1973">1973</option>
																	<option value="1974">1974</option>
																	<option value="1975">1975</option>
																	<option value="1976">1976</option>
																	<option value="1977">1977</option>
																	<option value="1978">1978</option>
																	<option value="1979">1979</option>
																	<option value="1980">1980</option>
																	<option value="1981">1981</option>
																	<option value="1982">1982</option>
																	<option value="1983">1983</option>
																	<option value="1984">1984</option>
																	<option value="1985">1985</option>
																	<option value="1986">1986</option>
																	<option value="1987">1987</option>
																	<option value="1988">1988</option>
																	<option value="1989">1989</option>
																	<option value="1990">1990</option>
																	<option value="1991">1991</option>
																	<option value="1992">1992</option>
																	<option value="1993">1993</option>
																	<option value="1994">1994</option>
																	<option value="1995">1995</option>
																	<option value="1996">1996</option>
																	<option value="1997">1997</option>
																	<option value="1998">1998</option>
																	<option value="1999">1999</option>
																	<option value="2000">2000</option>
																	<option value="2001">2001</option>
																	<option value="2002">2002</option>
																	<option value="2003">2003</option>
																	<option value="2004">2004</option>
																	<option value="2005">2005</option>
																	<option value="2006">2006</option>
																	<option value="2007">2007</option>
																	<option value="2008">2008</option>
																	<option value="2009">2009</option>
																	<option value="2010">2010</option>
																	<option value="2011">2011</option>
																	<option value="2012">2012</option>
																	<option value="2013">2013</option>
																	<option value="2014">2014</option>
																	<option value="2015">2015</option>
																	<option value="2016">2016</option>
																	<option value="2017">2017</option>
																	<option value="2018">2018</option>
																	<option value="2019">2019</option>
																	<option value="2020">2020</option>
																	<option value="2021">2021</option>
																	<option value="2022">2022</option>
																	<option value="2023">2023</option>
																	<option value="2024">2024</option>
																	<option value="2025">2025</option>
																</select></td>
																<td><select className="form-control" name="yearOfPassing"
																	value={educationalQualification.mphil.yearOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'mphil')}>
																	<option value="0">Select</option>
																	<option value="1950">1950</option>
																	<option value="1951">1951</option>
																	<option value="1952">1952</option>
																	<option value="1953">1953</option>
																	<option value="1954">1954</option>
																	<option value="1955">1955</option>
																	<option value="1956">1956</option>
																	<option value="1957">1957</option>
																	<option value="1958">1958</option>
																	<option value="1959">1959</option>
																	<option value="1960">1960</option>
																	<option value="1961">1961</option>
																	<option value="1962">1962</option>
																	<option value="1963">1963</option>
																	<option value="1964">1964</option>
																	<option value="1965">1965</option>
																	<option value="1966">1966</option>
																	<option value="1967">1967</option>
																	<option value="1968">1968</option>
																	<option value="1969">1969</option>
																	<option value="1970">1970</option>
																	<option value="1971">1971</option>
																	<option value="1972">1972</option>
																	<option value="1973">1973</option>
																	<option value="1974">1974</option>
																	<option value="1975">1975</option>
																	<option value="1976">1976</option>
																	<option value="1977">1977</option>
																	<option value="1978">1978</option>
																	<option value="1979">1979</option>
																	<option value="1980">1980</option>
																	<option value="1981">1981</option>
																	<option value="1982">1982</option>
																	<option value="1983">1983</option>
																	<option value="1984">1984</option>
																	<option value="1985">1985</option>
																	<option value="1986">1986</option>
																	<option value="1987">1987</option>
																	<option value="1988">1988</option>
																	<option value="1989">1989</option>
																	<option value="1990">1990</option>
																	<option value="1991">1991</option>
																	<option value="1992">1992</option>
																	<option value="1993">1993</option>
																	<option value="1994">1994</option>
																	<option value="1995">1995</option>
																	<option value="1996">1996</option>
																	<option value="1997">1997</option>
																	<option value="1998">1998</option>
																	<option value="1999">1999</option>
																	<option value="2000">2000</option>
																	<option value="2001">2001</option>
																	<option value="2002">2002</option>
																	<option value="2003">2003</option>
																	<option value="2004">2004</option>
																	<option value="2005">2005</option>
																	<option value="2006">2006</option>
																	<option value="2007">2007</option>
																	<option value="2008">2008</option>
																	<option value="2009">2009</option>
																	<option value="2010">2010</option>
																	<option value="2011">2011</option>
																	<option value="2012">2012</option>
																	<option value="2013">2013</option>
																	<option value="2014">2014</option>
																	<option value="2015">2015</option>
																	<option value="2016">2016</option>
																	<option value="2017">2017</option>
																	<option value="2018">2018</option>
																	<option value="2019">2019</option>
																	<option value="2020">2020</option>
																	<option value="2021">2021</option>
																	<option value="2022">2022</option>
																	<option value="2023">2023</option>
																	<option value="2024">2024</option>
																	<option value="2025">2025</option>
																</select></td>
																<td><select className="form-control" name="yearOfPassing"
																	value={educationalQualification.phd.yearOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'phd')}>
																	<option value="0">Select</option>
																	<option value="1950">1950</option>
																	<option value="1951">1951</option>
																	<option value="1952">1952</option>
																	<option value="1953">1953</option>
																	<option value="1954">1954</option>
																	<option value="1955">1955</option>
																	<option value="1956">1956</option>
																	<option value="1957">1957</option>
																	<option value="1958">1958</option>
																	<option value="1959">1959</option>
																	<option value="1960">1960</option>
																	<option value="1961">1961</option>
																	<option value="1962">1962</option>
																	<option value="1963">1963</option>
																	<option value="1964">1964</option>
																	<option value="1965">1965</option>
																	<option value="1966">1966</option>
																	<option value="1967">1967</option>
																	<option value="1968">1968</option>
																	<option value="1969">1969</option>
																	<option value="1970">1970</option>
																	<option value="1971">1971</option>
																	<option value="1972">1972</option>
																	<option value="1973">1973</option>
																	<option value="1974">1974</option>
																	<option value="1975">1975</option>
																	<option value="1976">1976</option>
																	<option value="1977">1977</option>
																	<option value="1978">1978</option>
																	<option value="1979">1979</option>
																	<option value="1980">1980</option>
																	<option value="1981">1981</option>
																	<option value="1982">1982</option>
																	<option value="1983">1983</option>
																	<option value="1984">1984</option>
																	<option value="1985">1985</option>
																	<option value="1986">1986</option>
																	<option value="1987">1987</option>
																	<option value="1988">1988</option>
																	<option value="1989">1989</option>
																	<option value="1990">1990</option>
																	<option value="1991">1991</option>
																	<option value="1992">1992</option>
																	<option value="1993">1993</option>
																	<option value="1994">1994</option>
																	<option value="1995">1995</option>
																	<option value="1996">1996</option>
																	<option value="1997">1997</option>
																	<option value="1998">1998</option>
																	<option value="1999">1999</option>
																	<option value="2000">2000</option>
																	<option value="2001">2001</option>
																	<option value="2002">2002</option>
																	<option value="2003">2003</option>
																	<option value="2004">2004</option>
																	<option value="2005">2005</option>
																	<option value="2006">2006</option>
																	<option value="2007">2007</option>
																	<option value="2008">2008</option>
																	<option value="2009">2009</option>
																	<option value="2010">2010</option>
																	<option value="2011">2011</option>
																	<option value="2012">2012</option>
																	<option value="2013">2013</option>
																	<option value="2014">2014</option>
																	<option value="2015">2015</option>
																	<option value="2016">2016</option>
																	<option value="2017">2017</option>
																	<option value="2018">2018</option>
																	<option value="2019">2019</option>
																	<option value="2020">2020</option>
																	<option value="2021">2021</option>
																	<option value="2022">2022</option>
																	<option value="2023">2023</option>
																	<option value="2024">2024</option>
																	<option value="2025">2025</option>
																</select></td>
															</tr>
															<tr bgcolor="#CAEEFF">
																<th scope="row">
																	<div align="left">Passing Percentage: <span
																		className="style1">*</span></div>
																</th>
																<td><select className="form-control" name="passingPercentage"
																	value={educationalQualification.ug.passingPercentage}
																	onChange={(e) => HandleEducationalQualification(e, 'ug')}>
																	<option value="">Select</option>
																	<option value="50">50</option>
																	<option value="51">51</option>
																	<option value="52">52</option>
																	<option value="53">53</option>
																	<option value="54">54</option>
																	<option value="55">55</option>
																	<option value="56">56</option>
																	<option value="57">57</option>
																	<option value="58">58</option>
																	<option value="59">59</option>
																	<option value="60">60</option>
																	<option value="61">61</option>
																	<option value="62">62</option>
																	<option value="63">63</option>
																	<option value="64">64</option>
																	<option value="65">65</option>
																	<option value="66">66</option>
																	<option value="67">67</option>
																	<option value="68">68</option>
																	<option value="69">69</option>
																	<option value="70">70</option>
																	<option value="71">71</option>
																	<option value="72">72</option>
																	<option value="73">73</option>
																	<option value="74">74</option>
																	<option value="75">75</option>
																	<option value="76">76</option>
																	<option value="77">77</option>
																	<option value="78">78</option>
																	<option value="79">79</option>
																	<option value="80">80</option>
																	<option value="81">81</option>
																	<option value="82">82</option>
																	<option value="83">83</option>
																	<option value="84">84</option>
																	<option value="85">85</option>
																	<option value="86">86</option>
																	<option value="87">87</option>
																	<option value="88">88</option>
																	<option value="89">89</option>
																	<option value="90">90</option>
																	<option value="91">91</option>
																	<option value="92">92</option>
																	<option value="93">93</option>
																	<option value="94">94</option>
																	<option value="95">95</option>
																	<option value="96">96</option>
																	<option value="97">97</option>
																	<option value="98">98</option>
																	<option value="99">99</option>
																	<option value="100">100</option>
																</select></td>
																<td><select className="form-control" name="passingPercentage"
																	value={educationalQualification.pg.passingPercentage}
																	onChange={(e) => HandleEducationalQualification(e, 'pg')}>
																	<option value="">Select</option>
																	<option value="50">50</option>
																	<option value="51">51</option>
																	<option value="52">52</option>
																	<option value="53">53</option>
																	<option value="54">54</option>
																	<option value="55">55</option>
																	<option value="56">56</option>
																	<option value="57">57</option>
																	<option value="58">58</option>
																	<option value="59">59</option>
																	<option value="60">60</option>
																	<option value="61">61</option>
																	<option value="62">62</option>
																	<option value="63">63</option>
																	<option value="64">64</option>
																	<option value="65">65</option>
																	<option value="66">66</option>
																	<option value="67">67</option>
																	<option value="68">68</option>
																	<option value="69">69</option>
																	<option value="70">70</option>
																	<option value="71">71</option>
																	<option value="72">72</option>
																	<option value="73">73</option>
																	<option value="74">74</option>
																	<option value="75">75</option>
																	<option value="76">76</option>
																	<option value="77">77</option>
																	<option value="78">78</option>
																	<option value="79">79</option>
																	<option value="80">80</option>
																	<option value="81">81</option>
																	<option value="82">82</option>
																	<option value="83">83</option>
																	<option value="84">84</option>
																	<option value="85">85</option>
																	<option value="86">86</option>
																	<option value="87">87</option>
																	<option value="88">88</option>
																	<option value="89">89</option>
																	<option value="90">90</option>
																	<option value="91">91</option>
																	<option value="92">92</option>
																	<option value="93">93</option>
																	<option value="94">94</option>
																	<option value="95">95</option>
																	<option value="96">96</option>
																	<option value="97">97</option>
																	<option value="98">98</option>
																	<option value="99">99</option>
																	<option value="100">100</option>
																</select></td>
																<td>
																	<select className="form-control" name="passingPercentage"
																		value={educationalQualification.mphil.passingPercentage}
																		onChange={(e) => HandleEducationalQualification(e, 'mphil')}>
																		<option value="">Select</option>
																		<option value="50">50</option>
																		<option value="51">51</option>
																		<option value="52">52</option>
																		<option value="53">53</option>
																		<option value="54">54</option>
																		<option value="55">55</option>
																		<option value="56">56</option>
																		<option value="57">57</option>
																		<option value="58">58</option>
																		<option value="59">59</option>
																		<option value="60">60</option>
																		<option value="61">61</option>
																		<option value="62">62</option>
																		<option value="63">63</option>
																		<option value="64">64</option>
																		<option value="65">65</option>
																		<option value="66">66</option>
																		<option value="67">67</option>
																		<option value="68">68</option>
																		<option value="69">69</option>
																		<option value="70">70</option>
																		<option value="71">71</option>
																		<option value="72">72</option>
																		<option value="73">73</option>
																		<option value="74">74</option>
																		<option value="75">75</option>
																		<option value="76">76</option>
																		<option value="77">77</option>
																		<option value="78">78</option>
																		<option value="79">79</option>
																		<option value="80">80</option>
																		<option value="81">81</option>
																		<option value="82">82</option>
																		<option value="83">83</option>
																		<option value="84">84</option>
																		<option value="85">85</option>
																		<option value="86">86</option>
																		<option value="87">87</option>
																		<option value="88">88</option>
																		<option value="89">89</option>
																		<option value="90">90</option>
																		<option value="91">91</option>
																		<option value="92">92</option>
																		<option value="93">93</option>
																		<option value="94">94</option>
																		<option value="95">95</option>
																		<option value="96">96</option>
																		<option value="97">97</option>
																		<option value="98">98</option>
																		<option value="99">99</option>
																		<option value="100">100</option>
																	</select>
																</td>

															</tr>
															<tr>
																<th scope="row">
																	<div align="left">Division of Passing: <span
																		className="style1">*</span></div>
																</th>
																<td><select className="form-control" name="divisionOfPassing"
																	value={educationalQualification.ug.divisionOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'ug')}>
																	<option value="">Select</option>
																	<option value="1">Ist</option>
																	<option value="2">IInd</option>
																	<option value="3">IIIrd</option>
																</select></td>
																<td><select className="form-control" name="divisionOfPassing"
																	value={educationalQualification.pg.divisionOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'pg')}>
																	<option value="">Select</option>
																	<option value="1">Ist</option>
																	<option value="2">IInd</option>
																	<option value="3">IIIrd</option>
																</select></td>
																<td><select className="form-control" name="divisionOfPassing"
																	value={educationalQualification.mphil.divisionOfPassing}
																	onChange={(e) => HandleEducationalQualification(e, 'mphil')}>
																	<option value="">Select</option>
																	<option value="1">Ist</option>
																	<option value="2">IInd</option>
																	<option value="3">IIIrd</option>
																</select></td>

															</tr>
															<tr bgcolor="#CAEEFF">
																<th scope="row">
																	<div align="left">Field of Specialization: <span
																		className="style1">*</span></div>
																</th>
																<td><label>
																	<input type="text" id="ug_4" name="fieldOfSpecialization"
																		value={educationalQualification.ug.fieldOfSpecialization}
																		onChange={(e) => HandleEducationalQualification(e, 'ug')}
																		size="10" />
																</label></td>
																<td><input type="text" id="pg_4" size="10" name="fieldOfSpecialization"
																	value={educationalQualification.pg.fieldOfSpecialization}
																	onChange={(e) => HandleEducationalQualification(e, 'pg')} />
																</td>
																<td><input type="text" id="mphil_4" name="fieldOfSpecialization"
																	value={educationalQualification.mphil.fieldOfSpecialization}
																	onChange={(e) => HandleEducationalQualification(e, 'mphil')}
																	size="10" /></td>
																<td><input name="fieldOfSpecialization"
																	value={educationalQualification.phd.fieldOfSpecialization}
																	onChange={(e) => HandleEducationalQualification(e, 'phd')} type="text" id="phd_4"
																	size="10" /></td>
															</tr>


															<tr>
																<th scope="row">
																	<div align="left">Qualified Any National/State Level
																		Examination: <span className="style1"></span></div>
																</th>
																<select className="form-control" name="qualifiedExamName"
																	value={nationalStateLevelExamination.qualifiedExamName}
																	onChange={(e) => handleNationalExamChange(e, 'qualifiedExamName')}>
																	<option value="">SELECT</option>
																	<option value="NET">NET</option>
																	<option value="GATE">GATE</option>
																	<option value="SET">SET</option>
																</select>
																<td><b>Qualifying Year:</b>&nbsp;&nbsp;&nbsp; 
																{/* <input
																	name="qualifyingYear"
																	type="text"
																	id="qualifyingYear"
																	size="10"
																	value={nationalStateLevelExamination.qualifyingYear}
																	onChange={(e) => handleNationalExamChange(e, 'qualifyingYear')}
																/> */}
																<select className="form-control" name="qualifyingYear"
																		value={nationalStateLevelExamination.qualifyingYear}
																		onChange={(e) => handleNationalExamChange(e, 'qualifyingYear')}>
																		<option value="0">Select</option>
																		<option value="1950">1950</option>
																		<option value="1951">1951</option>
																		<option value="1952">1952</option>
																		<option value="1953">1953</option>
																		<option value="1954">1954</option>
																		<option value="1955">1955</option>
																		<option value="1956">1956</option>
																		<option value="1957">1957</option>
																		<option value="1958">1958</option>
																		<option value="1959">1959</option>
																		<option value="1960">1960</option>
																		<option value="1961">1961</option>
																		<option value="1962">1962</option>
																		<option value="1963">1963</option>
																		<option value="1964">1964</option>
																		<option value="1965">1965</option>
																		<option value="1966">1966</option>
																		<option value="1967">1967</option>
																		<option value="1968">1968</option>
																		<option value="1969">1969</option>
																		<option value="1970">1970</option>
																		<option value="1971">1971</option>
																		<option value="1972">1972</option>
																		<option value="1973">1973</option>
																		<option value="1974">1974</option>
																		<option value="1975">1975</option>
																		<option value="1976">1976</option>
																		<option value="1977">1977</option>
																		<option value="1978">1978</option>
																		<option value="1979">1979</option>
																		<option value="1980">1980</option>
																		<option value="1981">1981</option>
																		<option value="1982">1982</option>
																		<option value="1983">1983</option>
																		<option value="1984">1984</option>
																		<option value="1985">1985</option>
																		<option value="1986">1986</option>
																		<option value="1987">1987</option>
																		<option value="1988">1988</option>
																		<option value="1989">1989</option>
																		<option value="1990">1990</option>
																		<option value="1991">1991</option>
																		<option value="1992">1992</option>
																		<option value="1993">1993</option>
																		<option value="1994">1994</option>
																		<option value="1995">1995</option>
																		<option value="1996">1996</option>
																		<option value="1997">1997</option>
																		<option value="1998">1998</option>
																		<option value="1999">1999</option>
																		<option value="2000">2000</option>
																		<option value="2001">2001</option>
																		<option value="2002">2002</option>
																		<option value="2003">2003</option>
																		<option value="2004">2004</option>
																		<option value="2005">2005</option>
																		<option value="2006">2006</option>
																		<option value="2007">2007</option>
																		<option value="2008">2008</option>
																		<option value="2009">2009</option>
																		<option value="2010">2010</option>
																		<option value="2011">2011</option>
																		<option value="2012">2012</option>
																		<option value="2013">2013</option>
																		<option value="2014">2014</option>
																		<option value="2015">2015</option>
																		<option value="2016">2016</option>
																		<option value="2017">2017</option>
																		<option value="2018">2018</option>
																		<option value="2019">2019</option>
																		<option value="2020">2020</option>
																		<option value="2021">2021</option>
																		<option value="2022">2022</option>
																		<option value="2023">2023</option>
																		<option value="2024">2024</option>
																		<option value="2025">2025</option>
																	</select>
																</td>

															</tr>


														</tbody>
													</table>
												</td>
											</tr>
											<tr bgcolor="#ECE9D8">
												<td colspan="4"><strong>Work Experience with number of years of
													experience :</strong></td>
											</tr>
											<tr>
												<td colspan="4">
													<table width="100%" border="0" cellspacing="1" cellpadding="10">
														<tbody>
															<tr>
																<td width="13%" bgcolor="#CAEEFF">
																	<strong>Teaching</strong>
																</td>
																<td width="13%"><select className="form-control" name="teaching"
																	value={workExperience.teaching}
																	onChange={handleWorkExperienceChange}>
																	<option value="0">Select</option>
																	<option value="0">0</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																	<option value="6">6</option>
																	<option value="7">7</option>
																	<option value="8">8</option>
																	<option value="9">9</option>
																	<option value="10">10</option>
																	<option value="11">11</option>
																	<option value="12">12</option>
																	<option value="13">13</option>
																	<option value="14">14</option>
																	<option value="15">15</option>
																	<option value="16">16</option>
																	<option value="17">17</option>
																	<option value="18">18</option>
																	<option value="19">19</option>
																	<option value="20">20</option>
																	<option value="21">21</option>
																	<option value="22">22</option>
																	<option value="23">23</option>
																	<option value="24">24</option>
																	<option value="25">25</option>
																	<option value="26">26</option>
																	<option value="27">27</option>
																	<option value="28">28</option>
																	<option value="29">29</option>
																	<option value="30">30</option>
																	<option value="31">31</option>
																	<option value="32">32</option>
																	<option value="33">33</option>
																	<option value="34">34</option>
																	<option value="35">35</option>
																	<option value="36">36</option>
																	<option value="37">37</option>
																	<option value="38">38</option>
																	<option value="39">39</option>
																	<option value="40">40</option>
																	<option value="41">41</option>
																	<option value="42">42</option>
																	<option value="43">43</option>
																	<option value="44">44</option>
																	<option value="45">45</option>
																	<option value="46">46</option>
																	<option value="47">47</option>
																	<option value="48">48</option>
																	<option value="49">49</option>
																	<option value="50">50</option>
																</select></td>
																<td width="20%" bgcolor="#CAEEFF">
																	<strong>Industry</strong>
																</td>
																<td width="17%"><select className="form-control" name="industry"
																	value={workExperience.industry}
																	onChange={handleWorkExperienceChange}>
																	<option value="0">Select</option>
																	<option value="0">0</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																	<option value="6">6</option>
																	<option value="7">7</option>
																	<option value="8">8</option>
																	<option value="9">9</option>
																	<option value="10">10</option>
																	<option value="11">11</option>
																	<option value="12">12</option>
																	<option value="13">13</option>
																	<option value="14">14</option>
																	<option value="15">15</option>
																	<option value="16">16</option>
																	<option value="17">17</option>
																	<option value="18">18</option>
																	<option value="19">19</option>
																	<option value="20">20</option>
																	<option value="21">21</option>
																	<option value="22">22</option>
																	<option value="23">23</option>
																	<option value="24">24</option>
																	<option value="25">25</option>
																	<option value="26">26</option>
																	<option value="27">27</option>
																	<option value="28">28</option>
																	<option value="29">29</option>
																	<option value="30">30</option>
																	<option value="31">31</option>
																	<option value="32">32</option>
																	<option value="33">33</option>
																	<option value="34">34</option>
																	<option value="35">35</option>
																	<option value="36">36</option>
																	<option value="37">37</option>
																	<option value="38">38</option>
																	<option value="39">39</option>
																	<option value="40">40</option>
																	<option value="41">41</option>
																	<option value="42">42</option>
																	<option value="43">43</option>
																	<option value="44">44</option>
																	<option value="45">45</option>
																	<option value="46">46</option>
																	<option value="47">47</option>
																	<option value="48">48</option>
																	<option value="49">49</option>
																	<option value="50">50</option>
																</select></td>
																<td width="17%" bgcolor="#CAEEFF">
																	<strong>Research</strong>
																</td>
																<td width="20%"><select className="form-control" name="research"
																	value={workExperience.research}
																	onChange={handleWorkExperienceChange}>
																	<option value="0">Select</option>
																	<option value="0">0</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																	<option value="6">6</option>
																	<option value="7">7</option>
																	<option value="8">8</option>
																	<option value="9">9</option>
																	<option value="10">10</option>
																	<option value="11">11</option>
																	<option value="12">12</option>
																	<option value="13">13</option>
																	<option value="14">14</option>
																	<option value="15">15</option>
																	<option value="16">16</option>
																	<option value="17">17</option>
																	<option value="18">18</option>
																	<option value="19">19</option>
																	<option value="20">20</option>
																	<option value="21">21</option>
																	<option value="22">22</option>
																	<option value="23">23</option>
																	<option value="24">24</option>
																	<option value="25">25</option>
																	<option value="26">26</option>
																	<option value="27">27</option>
																	<option value="28">28</option>
																	<option value="29">29</option>
																	<option value="30">30</option>
																	<option value="31">31</option>
																	<option value="32">32</option>
																	<option value="33">33</option>
																	<option value="34">34</option>
																	<option value="35">35</option>
																	<option value="36">36</option>
																	<option value="37">37</option>
																	<option value="38">38</option>
																	<option value="39">39</option>
																	<option value="40">40</option>
																	<option value="41">41</option>
																	<option value="42">42</option>
																	<option value="43">43</option>
																	<option value="44">44</option>
																	<option value="45">45</option>
																	<option value="46">46</option>
																	<option value="47">47</option>
																	<option value="48">48</option>
																	<option value="49">49</option>
																	<option value="50">50</option>
																</select></td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>



											<tr bgcolor="#ECE9D8">
												<td colspan="4"><strong>Please Attach your Resume in MS Word or PDF
													format</strong></td>
											</tr>
											<tr>
												<td colspan="4">
													<table width="100%" border="0" cellspacing="0" cellpadding="10">

														<tbody>
															<tr bgcolor="#64CDFF">
																<td width="16%"><strong>Resume: <span
																	className="style1">*</span></strong></td>

																<td width="84%" bgcolor="#CAEEFF"><strong>
																	<input name="resume" 
																		onChange={(e) => setResume(e.target.files[0])} type="file"
																		style={{ width: '180px' }} />

																</strong></td>
																<td width="84%" bgcolor="#CAEEFF"><iframe
																	id="upload_target" name="upload_target" src="#"
																	style={{
																		width: '0', height: '0', border: '0px', solid: ' #fff', background: '#CAEEFF'
																	}}></iframe></td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
											<tr bgcolor="#ECE9D8">
												<td colspan="4">
													<div align="right">
														<input type="submit" id="submitform"  value="Submit Application"/>

													</div>
												</td>
											</tr>
											<tr>
												<td colspan="4">Note: Fields marked with&nbsp;asterisk( <span
													className="style1">*</span> ) are mandatory. </td>
											</tr>
										</tbody>
									</table>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Trained /> */}
			<Footer />
		</div>
	)
}

export default Apply_for_job
