import React, { useState, useEffect } from 'react'
import { GetHomePageData } from '../../api/Global';
import Header from '../header/Header';
import backgroundImage from '../../assets/images/444.png';
import Footer from '../footer/Footer';
import ReactHtmlParser from "react-html-parser";
import imagebaseurl from '../../config/imageurl';

const About_Institutions = () => {
    const [AboutInstitutionsList, setAboutInstitutionsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const GetAboutInstitutionsList = async () => {
        setIsLoading(true)
        try {
            const response = await GetHomePageData();
            setAboutInstitutionsList(response?.data?.data?.aboutUs)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(true)
            console.log("error", error)
        }
    }
    console.log("CampusLifeList", AboutInstitutionsList)
    useEffect(() => {
        GetAboutInstitutionsList();
    }, [])
    return (
        <div>
            {
                isLoading ? (
                    <>
                        <div className="chat-window text-center">
                            <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                zIndex: "999"
                            }} className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded"
                            data-elementor-device-mode="desktop">
                            <Header />
                            <div class="menu_space"></div>
                            <section class="about-breadcrumb breadcumb-bg"
                                style={{
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '400px'
                                }}>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="breadcrumb-content">
                                                <ul class="breadcrumb">
                                                    {/* <li class="breadcrumb-item active" aria-current="page">About Us Institutions</li> */}
                                                </ul>
                                                <h2 class="heading-title">About Us </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className='container'>
                                <div className='row'>
                                    {
                                        AboutInstitutionsList?.map((AboutInstitutionsListResult, index) => {
                                            return (
                                                <>
                                                    <div className="row">
                                                        {
                                                            index % 2 == 0 ? (
                                                                <>
                                                                    <div className='col-6 mt-5 col-sm-12 col-md-6'>
                                                                        <img src={`${imagebaseurl}/${AboutInstitutionsListResult?.images}`}  alt='' />
                                                                    </div>
                                                                    <div className='col-6 mt-5 col-sm-12 col-md-6'>
                                                                        {/* <h1>{AboutInstitutionsListResult?.title}</h1> */}
                                                                        <p>{ReactHtmlParser(AboutInstitutionsListResult?.content)}</p>
                                                                    </div>
                                                                </>

                                                            ) : (
                                                                <>
                                                                    <div className='col-6 mt-5 col-sm-12 col-md-6'>
                                                                        {/* <h1>{AboutInstitutionsListResult?.title}</h1> */}
                                                                        <p>{ReactHtmlParser(AboutInstitutionsListResult?.content)}</p>
                                                                    </div>
                                                                    <div className='col-6 mt-5 col-sm-12 col-md-6 mb-5'>
                                                                        <img src={`${imagebaseurl}/${AboutInstitutionsListResult?.images}`}  alt='' />
                                                                    </div>

                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div>
                                <Footer />
                            </div>
                        </div>
                    </>
                )
            }

        </div>

    )
}

export default About_Institutions