import React,{useState} from 'react'
import { FaArrowRight } from "react-icons/fa";
import { FeedbackForm } from '../../api/Global';

const EmployerFeedback = () => {
	const [userInfo, setUserInfo] = useState({
		userName: '',
		email: '',
		organization: '',
		designation: '',
	});
	const [ratings, setRatings] = useState({
		par1: 0,
		par2: 0,
		par3: 0,
		par4: 0,
		par5: 0,
		par6: 0,
		par7: 0,
		par8: 0,
		par9: 0,
	});

	const questions = [
		{ id: 'par1', text: 'Curriculum is well defined and the courses are logically structured' },
		{ id: 'par2', text: 'Curriculum includes both theory and applied aspects of the subject' },
		{ id: 'par3', text: 'Curriculum offers a range ofoptionals/electives/value-added courses' },
		{ id: 'par4', text: 'Curriculum incorporates technical and communication skills' },
		{ id: 'par5', text: 'Curriculum incorporates computer skills and other soft skills needed for employment' },
		{ id: 'par6', text: 'Curriculum includes project/dissertation/in-plant training/field visit for real-life experiential Learning' },
		{ id: 'par7', text: 'Curriculum provides scope for acquiring employable and entrepreneurship skills' },
		{ id: 'par8', text: 'Curriculum includes courses having a balance of human values and ethics' },
		{ id: 'par9', text: 'Curriculum has good balance between theory and application to bridge the Industry-Academia gap' }
	];

	// Handle personal info change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({
			...userInfo,
			[name]: value
		});
	};

	// Handle radio button change for ratings
	const handleRatingChange = (parameter, value) => {
		setRatings({
			...ratings,
			[parameter]: value,
		});
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		const atLeastOneRated = questions.some(question => ratings[question.id] !== undefined && ratings[question.id] !== 0);

		if (!atLeastOneRated) {
			alert("Please rate at least one question before submitting.");
			return;
		}
		const filteredRatings = questions
			.filter(question => ratings[question.id] !== undefined && ratings[question.id] !== 0)
			.map(question => ({
				parameter: question.text,
				rating: ratings[question.id]
			}));

		const payload = {
			formType: 'employer',
			...userInfo,
			ratings: filteredRatings
		};

		try {
			const response = await FeedbackForm(payload);
			console.log("response", response);
			if (response?.data?.status == true) {
				alert("Feedback created successfully")
				setUserInfo({
					userName: '',
					email: '',
					organization: '',
					designation: '',
				});

				setRatings({
					par1: 0,
					par2: 0,
					par3: 0,
					par4: 0,
					par5: 0,
					par6: 0,
					par7: 0,
					par8: 0,
					par9: 0,
				});
			}
		} catch (error) {
			console.log("error", error);
		}
	};
	return (
		<div>
			<div  className="overview-area aboutus-vi">
				<div  className="container">
					<div  className="bg-ligh">
						<form action="#" method="post" onSubmit={handleSubmit}  className="wpcf7-form init">
							<div  className="row">
								<div  className="col-md-12">
									<div  className="elementor-widget-container">
										<div  className="tm-modern-heading">

											<div  className="heading-primary-wrap">
												<h2  className="heading-primary elementor-heading-title">Feedback on
													Curriculum
													(Employer)
												</h2>
											</div>

											<div  className="heading-divider"></div>

											<div  className="heading-description-wrap">
												<div  className="heading-description">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div  className="elementor-widget-container">
									<div  className="unicamp-contact-form-7">
										<div  className="row">
											<div  className="col-md-3 col-lg-3">
												<div  className="form-group">
													<label>User Name</label>
													<input  className="form-control border border-gray" placeholder="User Name" value={userInfo.userName} required
														type="text" name="userName" onChange={handleInputChange} />
												</div>
											</div>
											<div  className="col-md-3 col-lg-3">
												<div  className="form-group">
													<label>Organization</label>
													<input  className="form-control border border-gray" placeholder="Organization" required
														value={userInfo.organization}
														type="text" name="organization" onChange={handleInputChange} />
												</div>
											</div>
											<div  className="col-md-3 col-lg-3">
												<div  className="form-group">
													<label>Email ID</label>
													<input  className="form-control border border-gray" placeholder="Email ID" value={userInfo.email}
														onChange={handleInputChange}
														required
														type="email" name="email" />
												</div>
											</div>
											<div  className="col-md-3 col-lg-3">
												<div  className="form-group">
													<label>Designation</label>
													<input  className="form-control border border-gray" placeholder="Designation"
													value={userInfo.designation}
													 onChange={handleInputChange} required
														type="text" name="designation" />
												</div>
											</div>

											<div  className="col-md-12">
												<div  className="form-group table-responsive">
													<table  className="table table-hover sportstbl">
														<thead>
															<tr>
																<th>
																	<p>Parameter</p>
																</th>
																<th>
																	<p>Strongly Agree</p>
																</th>
																<th>
																	<p>Agree</p>
																</th>
																<th>
																	<p>Neutral</p>
																</th>
																<th>
																	<p>Disagree</p>
																</th>
																<th>
																	<p>Strongly Disagree</p>
																</th>
															</tr>
														</thead>
														<tbody>
                                                            {questions?.map((question) => (
                                                                <tr key={question.id}>
                                                                    <td>
                                                                        <p>{question.text}</p>
                                                                    </td>
                                                                    {[5, 4, 3, 2, 1].map((ratingValue) => (
                                                                        <td key={ratingValue}>
                                                                            <input
                                                                                type="radio"
                                                                                name={question.id}
                                                                                value={ratingValue}
                                                                                checked={ratings[question.id] === ratingValue}
                                                                                onChange={() => handleRatingChange(question.id, ratingValue)}
                                                                            />
                                                                            {ratingValue}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
													</table>
												</div>
											</div>
											<div  className="col-md-12 text-end">
												<div  className="form-groups">
													<div  className="form-submitss">
														<button  className="100%" type="submit">
															Submit
															<span  className="button-icon"><FaArrowRight />
															</span>
														</button>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</form>

					</div>
				</div>
			</div>
		</div>
	)
}

export default EmployerFeedback