import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GetHomePageData } from '../../api/Global';
import 'react-image-lightbox/style.css'; // Import lightbox CSS
import Lightbox from 'react-image-lightbox'; // Import Lightbox
import imagebaseurl from '../../config/imageurl';
import LazyLoad from 'react-lazyload';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loading, setLoading] = useState(false); // New loading state

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await GetHomePageData();
                console.log('API Response:', response.data.data.galleryImages); 
                if (Array.isArray(response.data.data.galleryImages)) {
                    setImages(response.data.data.galleryImages.slice(0, 6)); // Limit to first 6 images
                } 
            } catch (error) {
                console.error('Error fetching images:', error);
                setError('Error fetching images');
            }
        };
        fetchImages();
    }, []);

    const handleImageClick = async (index) => {
        setLoading(true); // Set loading to true before preloading
        const imageToLoad = new Image();
        imageToLoad.src = `${imagebaseurl}/${images[index].galleryImage}`;
        

        // Wait for the image to load
        imageToLoad.onload = () => {
            setLoading(false); // Set loading to false after loading
            setPhotoIndex(index);
            setIsOpen(true);
        };

        // Handle image loading errors
        imageToLoad.onerror = () => {
            setLoading(false); // Stop loading on error
            console.error('Error loading image:', images[index].galleryImage);
            alert('Failed to load image, please try again later.');
        };
    };
    console.log('isopen',isOpen );
    

    return (
        <div>
            <div className="heading-primary-wrap text-center pt-5">
                <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-3e09a4c animated unicampFadeInUp">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                    <div className="tm-modern-heading text-start">
                                        <div className="heading-primary-wrap">
                                            <h2 className="heading-primary elementor-heading-title">
                                                Gallery
                                            </h2>
                                        </div>
                                        <div className="heading-description-wrap">
                                            <div className="heading-description">
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-end">
                                    <Link to="/Gallery_More" className="mt-5 tm-button-link tm-button style-flat tm-button-nm icon-right">
                                        <div className="list-header">
                                            <div className="button-content-wrapper">
                                                <span className="button-text">View More</span>
                                                <span className="button-icon">
                                                    <i className="fas fa-long-arrow-right"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main lightGalleryddd">
                <div className="container">
                    {images.length > 0 ? (
                        images.map((image, index) => (
                            <div className="card" key={index}>
                                <LazyLoad className="card-image">
                                    <img
                                        src={`${imagebaseurl}/${image.galleryImage}`}
                                        alt={image.alt || `Image ${index + 1}`}
                                        // style={{ width: "100%", height: "300px", borderRadius: "10px", cursor: "pointer" }}
                                        onClick={() => handleImageClick(index)} 
                                    />
                                </LazyLoad>
                            </div>
                        ))
                    ) : (
                        <p>No images available</p>
                    )}
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={`${imagebaseurl}/${images[photoIndex]?.galleryImage}`}
                        nextSrc={`${imagebaseurl}/${photoIndex < images.length - 1 ? images[photoIndex + 1]?.galleryImage : undefined}`}
                        prevSrc={`${imagebaseurl}/${photoIndex > 0 ? images[photoIndex - 1]?.galleryImage : undefined} `}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => {
                            if (photoIndex > 0) {
                                setPhotoIndex(photoIndex - 1);
                            }
                        }}
                        onMoveNextRequest={() => {
                            if (photoIndex < images.length - 1) {
                                setPhotoIndex(photoIndex + 1);
                            }
                        }}
                    />
                )}
                {loading && <div className="spinner">Loading...</div>} 
            </div>
     {/* <div className="main lightGalleryddd">
            <div className="container">
                {images.length > 0 ? (
                    images.map((image, index) => (
                        <div className="card" key={index}>
                            <div className="card-image">
                                <img
                                    src={`${imagebaseurl}/${image.galleryImage}`}
                                    alt={image.alt || `Image ${index + 1}`}
                                    style={{ width: "100%", height: "300px", borderRadius: "10px", cursor: "pointer" }}
                                    onClick={() => handleImageClick(index)}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No images available</p>
                )}
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={`${imagebaseurl}/${images[photoIndex]?.galleryImage}`}
                    nextSrc={photoIndex < images.length - 1 ? `${imagebaseurl}/${images[photoIndex + 1]?.galleryImage}` : null}
                    prevSrc={photoIndex > 0 ? `${imagebaseurl}/${images[photoIndex - 1]?.galleryImage}` : null}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => {
                        if (photoIndex > 0) setPhotoIndex(photoIndex - 1);
                    }}
                    onMoveNextRequest={() => {
                        if (photoIndex < images.length - 1) setPhotoIndex(photoIndex + 1);
                    }}
                />
            )}

            {loading && <div className="spinner">Loading...</div>} 
        </div> */}
        </div>
    );
};

export default Gallery;
