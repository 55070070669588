

// import React, { useState, useEffect } from "react";
// import Header from "../header/Header";
// import { Getalllegaldoc } from "../../api/Global";
// import backgroundImage from "../../assets/images/444.png";
// import ReactHtmlParser from "react-html-parser";
// import Footer from "../footer/Footer";

// function Terms_condition() {
//   const [termsData, setTermsData] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const fetchLegalDocs = async () => {
//     setIsLoading(true);
//     try {
//       const response = await Getalllegaldoc();
//       console.log("API Response:", response?.data?.data);
//       setTermsData(response?.data?.terms || null); // Now fetching 'terms' instead of 'privacy'
//     } catch (error) {
//       console.error("Error fetching Terms & Conditions:", error);
//     }
//     setIsLoading(false);
//   };

//   useEffect(() => {
//     fetchLegalDocs();
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div
//       className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//       page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
//       e--ua-chrome e--ua-webkit loaded"
//       data-elementor-device-mode="desktop"
//     >
//       <Header />
//       {/* Breadcrumb Section */}
//       <section
//         className="about-breadcrumb breadcumb-bg"
//         style={{
//           backgroundImage: `url(${backgroundImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           width: "100%",
//           height: "400px",
//         }}
//       >
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="breadcrumb-content">
//                 <h2 className="heading-title">Terms And Conditions</h2>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div className="container mt-5">
//         {isLoading ? (
//           <p>Loading...</p>
//         ) : termsData ? (
//           <div>
//             <h2>Terms and Conditions</h2>
//             <div>{ReactHtmlParser(termsData)}</div>
//           </div>
//         ) : (
//           <p>Terms and Conditions are not available.</p>
//         )}
//       </div>

//       <Footer />
//     </div>
//   );
// }

// export default Terms_condition;
import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { Getalllegaldoc } from "../../api/Global";
import backgroundImage from "../../assets/images/444.png";
import ReactHtmlParser from "react-html-parser";
import Footer from "../footer/Footer";

function Terms_condition() {
  const [termsData, setTermsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLegalDocs = async () => {
    setIsLoading(true);
    try {
      const response = await Getalllegaldoc();
      console.log("API Response:", response?.data?.data);
      
      // Correcting the path to fetch 'terms' data
      setTermsData(response?.data?.data?.terms || null);
      
    } catch (error) {
      console.error("Error fetching Terms & Conditions:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLegalDocs();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
      page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
      e--ua-chrome e--ua-webkit loaded"
      data-elementor-device-mode="desktop"
    >
      <Header />

      {/* Breadcrumb Section */}
      <section
        className="about-breadcrumb breadcumb-bg"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="heading-title">Terms And Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-5">
        {isLoading ? (
          <p>Loading...</p>
        ) : termsData ? (
          <div>
            <h2>{termsData?.title}</h2>
            <div>{ReactHtmlParser(termsData?.content)}</div>
            <p><strong>Last Updated:</strong> {new Date(termsData?.updatedAt).toLocaleDateString()}</p>
          </div>
        ) : (
          <p>Terms and Conditions are not available.</p>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Terms_condition;
