import React, { useState } from 'react'
import Header from '../header/Header'
import backgroundImage from '../../assets/images/444.png'
import { Link } from 'react-router-dom'
import StudentFeedback from './StudentFeedback'
import TeacherFeedback from './TeacherFeedback'
import EmployerFeedback from './EmployerFeedback'
import AlumniFeedback from './AlumniFeedback'
import ParentsFeedback from './ParentsFeedback'
import StudentInfraStructure from './StudentInfraStructure'
import TeacherInfraStructure from './TeacherInfraStructure'
import EmployInfraStructure from './EmployInfraStructure'
import AlumniInfraStructure from './AlumniInfraStructure'
import ParentsInfraStructure from './ParentsInfraStructure'

const Feedback = () => {
    const [showStudentFeedback, setShowStduentFeedback] = useState(true)
    const [showTeacherFeedback, setShowTeacherFeedback] = useState(false)
    const [showEmpFeedback, setShowEmpFeedback] = useState(false)
    const [showAlumniFeedback, setShowAlumniFeedback] = useState(false)
    const [showParentsFeedback, setShowParentsFeedback] = useState(false)
    const [showStudentInfraFeedback, setShowStudentInfraFeedback] = useState(false)
    const [showTeacherInfraFeedback, setShowTeacherInfraFeedback] = useState(false)
    const [showEmployerInfraFeedback, setShowEmployerInfraFeedback] = useState(false)
    const [showAlumniInfraFeedback, setShowAlumniInfraFeedback] = useState(false)
    const [showParentsInfraFeedback, setShowParentsInfraFeedback] = useState(false)
    const [showActiveTabs, setshowActiveTabs] = useState("Student")
    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
     e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
            <Header />
            < section className="about-breadcrumb breadcumb-bg"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '400px'
                }}>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <ul className="breadcrumb">
                                    {/* <li className="breadcrumb-item active" aria-current="page">Feedback
                                    </li> */}
                                </ul>
                                <h2 className="heading-title">Feedback</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div className='mt-5'>
                <ul className='feedback_tabs'>
                    <li className={showActiveTabs == 'Student' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowParentsFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowStduentFeedback(true)
                        setshowActiveTabs("Student")

                    }}>Feedback on Curriculum-ENGG (Student)</li>

                    <li className={showActiveTabs == 'Teacher' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowTeacherFeedback(true)
                        setshowActiveTabs("Teacher")
                    }}>Feedback on Curriculum-MBA (Teacher)</li>

                    <li className={showActiveTabs == 'Employer' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowEmpFeedback(true)
                        setshowActiveTabs("Employer")
                    }}>Feedback on Curriculum-MBA (Employer)</li>

                    <li className={showActiveTabs == 'Alumni' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowParentsFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowAlumniFeedback(true)
                        setshowActiveTabs("Alumni")
                    }}>Feedback on Curriculum-MBA (Alumni)</li>

                    <li className={showActiveTabs == 'Parents' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowParentsFeedback(true)
                        setshowActiveTabs("Parents")
                    }}>Feedback on Curriculum-MBA (Parents)</li>


                </ul>
                <ul className='feedback_tabs'>
                    <li className={showActiveTabs == 'StudentInfrastructure' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowParentsFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(true)
                        setshowActiveTabs("StudentInfrastructure")

                    }}>Feedback on Infrastructure Facility (Student)</li>

                    <li className={showActiveTabs == 'TeacherInfrastructure' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(true)
                        setshowActiveTabs("TeacherInfrastructure")
                    }}>Feedback on Infrastructure Facility (Teacher)</li>

                    <li className={showActiveTabs == 'EmployerInfrastructure' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowStduentFeedback(false)
                        setShowParentsFeedback(false)
                        setShowEmpFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowEmployerInfraFeedback(true)
                        setshowActiveTabs("EmployerInfrastructure")
                    }}>Feedback on Infrastructure Facility (Employer)</li>

                    <li className={showActiveTabs == 'AlumniInfrastructure' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowParentsFeedback(false)
                        setShowStduentFeedback(false)
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowParentsInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowAlumniInfraFeedback(true)
                        setshowActiveTabs("AlumniInfrastructure")
                    }}>Feedback on Infrastructure Facility (Alumni)</li>

                    <li className={showActiveTabs == 'ParentsInfrastructure' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                        setShowAlumniFeedback(false)
                        setShowEmpFeedback(false)
                        setShowTeacherFeedback(false)
                        setShowStduentFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowEmpFeedback(false)
                        setShowParentsFeedback(false)
                        setShowAlumniInfraFeedback(false)
                        setShowEmployerInfraFeedback(false)
                        setShowTeacherInfraFeedback(false)
                        setShowStudentInfraFeedback(false)
                        setShowParentsInfraFeedback(true)
                        setshowActiveTabs("ParentsInfrastructure")
                    }}>Feedback on Infrastructure Facility (Parents)</li>


                </ul>
                <div className='container'>
                    <div className='row'>
                        <Link to="https://www.aicte-india.org/feedback/students.php">
                            <ul className='feedback_tabs'>
                                <li className={showActiveTabs == 'Parents1' ? 'active-tab' : 'inactive-tab'} onClick={() => {
                                    setShowAlumniFeedback(false)
                                    setShowEmpFeedback(false)
                                    setShowTeacherFeedback(false)
                                    setShowStduentFeedback(false)
                                    setShowParentsFeedback(true)
                                    setshowActiveTabs("Parents1")
                                }}>AICTE Portal Link For Feedback Facility</li>
                            </ul>

                        </Link>
                    </div>
                </div>

                {
                    showStudentFeedback && (
                        <StudentFeedback />
                    )
                }
                {
                    showTeacherFeedback && (
                        <TeacherFeedback />
                    )
                }
                {
                    showEmpFeedback && (
                        <EmployerFeedback />
                    )
                }
                {
                    showAlumniFeedback && (
                        <AlumniFeedback />
                    )
                }
                {
                    showParentsFeedback && (
                        <ParentsFeedback />
                    )
                }
                {
                    showStudentInfraFeedback && (
                        <StudentInfraStructure />
                    )
                }
                {
                    showTeacherInfraFeedback && (
                        <TeacherInfraStructure />
                    )
                }
                {
                    showEmployerInfraFeedback && (
                        <EmployInfraStructure />
                    )
                }
                {
                    showAlumniInfraFeedback && (
                        <AlumniInfraStructure />
                    )
                }
                {
                    showParentsInfraFeedback && (
                        <ParentsInfraStructure />
                    )
                }
            </div>
        </div>
    )
}

export default Feedback