import React, { useEffect } from 'react'
import Header from "../../components/header/Header";
import backgroundImage from '../../assets/images/444.png'
import aboutus3 from "../../assets/images/abouto.jpg"
// import Trained from '../Trained/Trained';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';
const AntiRangging = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
         e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
            <div id="page" class="site">
                <div class="content-wrapper">
                    <Header />

                    <div class="menu_space"></div>

                    {/* <!-- about bg --> */}
                    <section class="about-breadcrumb breadcumb-bg"

                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '400px'
                        }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="breadcrumb-content">
                                        <ul class="breadcrumb">
                                            {/* <li class="breadcrumb-item"><Link href="index.html">Home</Link></li>
                                            <li class="breadcrumb-item"><a>/</a></li> */}
                                            <li class="breadcrumb-item active" aria-current="page">Anti Ragging Cell</li>
                                        </ul>
                                        <h2 class="heading-title">Anti Ragging Cell</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="overview-area aboutus-vi">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="overview-content">
                                        <div class="image">
                                            <img src={aboutus3}
                                                alt="overview-image" />
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="overview-image aboutover">
                                        <div class="title">
                                            <h2 class="d-flex align-items-center">
                                                Anti-Ragging Cell
                                            </h2>

                                            <p>Ragging of students in any form is strictly prohibited within and outside the campus. An anti-ragging cell is constituted under the overall charge of the Dean. Students are advised to report cases of ragging (either on the college campus or hostel) to the Dean or Registrar or Senior Academic Head or Academic Head or Hostel Warden. Anyone found guilty of ragging shall be liable for punishment in accordance with the All India Council for Technical Education (Prevention and Prohibition of Ragging in Technical Institutions, Universities including Deemed to be Universities imparting technical education) Regulations 2009.</p>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>CDGI ANTI-RAGGING COMMITTEE</h4>
                                    <div class="table-responsive">
                                        <table class="table table_hover sportstbl">
                                            <thead>
                                                <tr><th width="55">
                                                    <p><strong>Sl. No.</strong></p>
                                                </th>
                                                    <th width="183">
                                                        <p><strong>Name</strong></p>
                                                    </th>
                                                    <th width="166">
                                                        <p><strong>Designation</strong></p>
                                                    </th>
                                                    <th width="219">
                                                        <p><strong>&nbsp;Position</strong></p>
                                                    </th>
                                                </tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td width="55">
                                                        <p>1</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Dr. Joy Banerjee</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Group Director</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Chairperson</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>2</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Dr. Manish Shrivastava</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Principal, CDGI</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>3</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Dr. Jalaj Katare</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Dean, MBA</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>4</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Vishal Sharma</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Registrar</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>5</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Shailendra Kumar Mishra</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Head Incharge, Anti ragging Cell</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Coordinator</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>6</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Deepak Phalke</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Academic Head, 1 Yr.</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>7</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Dr. Mohan Rawat</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, ESH</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>8</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Manish Gome</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, ME</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>9</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. C.A. Patil</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, EC</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>10</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Dr. Anish Kumar Choudhary</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, CSE</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>11</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Amit Shrivastava</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, IT</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>12</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Deepesh Kumar Shrivas</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, AD</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>13</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Ms. Rupanshi Patidar</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, CI</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>14</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Arpit Jaiswal</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>HOD, CE</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td width="55">
                                                        <p>15</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>SDM, Juni Indore</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Civil Administration</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>16</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>T.I. Tejaji Nagar</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>T.I. Police Administration</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>17</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Ms. Vasudha Khargonkar</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>NGO - Representative</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>18</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Sanjay Bankda</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Media Representative</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>19</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Nimish Thakkar</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Parents Representative</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td width="55">
                                                        <p>20</p>
                                                    </td>
                                                    <td width="183">
                                                        <p>Mr. Sudhir Lambhate</p>
                                                    </td>
                                                    <td width="166">
                                                        <p>Parents Representative</p>
                                                    </td>
                                                    <td width="219">
                                                        <p>Member</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <h4>CDGI ANTI-RAGGING SQUAD</h4>
                                    <div className='table-responsive'>
                                        <p><br />
                                        </p>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div>S.No.</div>
                                                    </th>
                                                    <th>
                                                        <div>Name of Member</div>
                                                    </th>
                                                    <th>
                                                        <div>Designation</div>
                                                    </th>
                                                    <th>
                                                        <div>Position</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>1</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Shailendra Kumar Mishra</div>
                                                    </td>
                                                    <td>
                                                        <div>Head Incharge, Anti ragging Cell</div>
                                                    </td>
                                                    <td>
                                                        <div>Coordinator</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>2</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Manish Gome</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, ME</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>3</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. C.A. Patil</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, EC</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>4</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Amit Shrivastava</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, IT</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>5</div>
                                                    </td>
                                                    <td>
                                                        <div>Dr. Anish Kumar Choudhary</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, CSE</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>6</div>
                                                    </td>
                                                    <td>
                                                        <div>Ms. Rupanshi Patidar</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, CI</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>7</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Deepesh Kumar Shrivas</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, AD</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>8</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Arpit Jaiswal</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, CE</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>9</div>
                                                    </td>
                                                    <td>
                                                        <div>Dr. Mohan Rawat</div>
                                                    </td>
                                                    <td>
                                                        <div>HOD, ESH</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>10</div>
                                                    </td>
                                                    <td>
                                                        <div>Dr. Jalaj Katare</div>
                                                    </td>
                                                    <td>
                                                        <div>Dean, MBA</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>11</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Deepak Phalke</div>
                                                    </td>
                                                    <td>
                                                        <div>Academic Head First Year</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>12</div>
                                                    </td>
                                                    <td>
                                                        <div>Dr. Manoj Agrawal</div>
                                                    </td>
                                                    <td>
                                                        <div>Associate Professor, CSE</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>13</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Nimish Thakkar</div>
                                                    </td>
                                                    <td>
                                                        <div>Accounts Assistant</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>14</div>
                                                    </td>
                                                    <td>
                                                        <div>Dr. Jitendra Sheetlani</div>
                                                    </td>
                                                    <td>
                                                        <div>Professor</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>15</div>
                                                    </td>
                                                    <td>
                                                        <div>Mr. Jamil Khan</div>
                                                    </td>
                                                    <td>
                                                        <div>Sports Officer</div>
                                                    </td>
                                                    <td>
                                                        <div>Member</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <p><br/>
                                    </p>

                                    <p><strong>STUDENT REPRESENTATIVES FOR ANTI RAGGING COMMITTEE</strong></p>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div>S.No.</div>
                                                </th>
                                                <th>
                                                    <div>Name</div>
                                                </th>
                                                <th>
                                                    <div>Class &amp; Section</div>
                                                </th>
                                                <th>
                                                    <div>Position</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div>1</div>
                                                </td>
                                                <td>
                                                    <div>Tushar Kala</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. CSE – 5 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Coordinator</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>2</div>
                                                </td>
                                                <td>
                                                    <div>Ramesh Dudwe</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. CS/IT – 3 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>3</div>
                                                </td>
                                                <td>
                                                    <div>Sawan Nayak</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. EC – 3 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>4</div>
                                                </td>
                                                <td>
                                                    <div>Krishna Sharma</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. IT – 3 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>5</div>
                                                </td>
                                                <td>
                                                    <div>Nakul Sameriya</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. ME – 3 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>6</div>
                                                </td>
                                                <td>
                                                    <div>Ankit Kumar</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. CS/IT – 3 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>7</div>
                                                </td>
                                                <td>
                                                    <div>Akshay Singh Solanki</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. CSE – 1 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>8</div>
                                                </td>
                                                <td>
                                                    <div>Akshat Shukla</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. CSE – 1 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>9</div>
                                                </td>
                                                <td>
                                                    <div>Gyanendra Kushwaha</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. AI&amp;DS – 1 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>10</div>
                                                </td>
                                                <td>
                                                    <div>Asad Shah Khan</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. IT – 1 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>11</div>
                                                </td>
                                                <td>
                                                    <div>Akshat Soni</div>
                                                </td>
                                                <td>
                                                    <div>B.Tech. EC – 1 Sem</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>12</div>
                                                </td>
                                                <td>
                                                    <div>Alfiza</div>
                                                </td>
                                                <td>
                                                    <div>MBA FT</div>
                                                </td>
                                                <td>
                                                    <div>Member</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <Trained /> */}
                <Footer />
                {/* <!-- footer section end --> */}

            </div>



            <a class="page-scroll-up" id="page-scroll-up">
                <i class="arrow-top fal fa-long-arrow-up"></i>
                <i class="arrow-bottom fal fa-long-arrow-up"></i>
            </a>
        </div>
    )
}

export default AntiRangging