import React, { useEffect } from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useParams } from 'react-router-dom'
import imagebaseurl from '../../config/imageurl'


const More_Pdf_Open = () => {
    const { _id } = useParams();
    const selectedDoc = localStorage.getItem("selectedDoc");
    const selectedDoc_title = localStorage.getItem("selectedDoc_title");
    useEffect(() => {
        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        const disablePrintShortcut = (e) => {
            if ((e.ctrlKey && e.key === 'p') || (e.metaKey && e.key === 'p')) {
                e.preventDefault();
                alert("Printing is disabled.");
            }
        };

        // Attach the event listeners
        document.addEventListener('contextmenu', disableContextMenu);
        document.addEventListener('keydown', disablePrintShortcut);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('contextmenu', disableContextMenu);
            document.removeEventListener('keydown', disablePrintShortcut);
        };
    }, [_id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded"
            data-elementor-device-mode="desktop">
            <Header />
            <div className="container text-center">
                {
                    selectedDoc?.length > 0 && (
                        <div style={{ marginTop: '100px'}}>
                            <h3>{selectedDoc_title}</h3>
                            <iframe
                                src={`${imagebaseurl}/${selectedDoc}#toolbar=0&navpanes=0&scrollbar=0`}
                                type="application/pdf"
                                width="80%"
                                height="800px"
                                style={{
                                    width: '100%',
                                    border: 'none'
                                }}
                                title="PDF Viewer"
                            />
                        </div>
                    )
                }

            </div>
            <div>
                {/* <Trained /> */}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default More_Pdf_Open