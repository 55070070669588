// import React, { useState, useEffect } from 'react'
// import Header from '../header/Header'
// import { GetHomePageData } from '../../api/Global';
// import { Link } from 'react-router-dom';
// // import Trained from '../Trained/Trained';
// import Footer from '../footer/Footer';
// const Gallery = () => {
//     const [images, setImages] = useState([]);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchImages = async () => {
//             try {
//                 const response = await GetHomePageData();
//                 console.log('API Response:', response.data.data.galleryImages);
//                 if (Array.isArray(response.data.data.galleryImages)) {
//                     setImages(response.data.data.galleryImages);
//                 }
//             } catch (error) {
//                 console.error('Error fetching images:', error);
//                 setError('Error fetching images');
//             }
//         };
//         fetchImages();
//     }, []);

//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []);
      
//     return (
//         <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
//     page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
//     e--ua-chrome e--ua-webkit loaded"
//             data-elementor-device-mode="desktop">
//             <Header />

//             <div className="main lightGalleryddd mt-5">
//                 <div className="heading-primary-wrap text-center mt-5">
//                     <h2 className="heading-primary elementor-heading-title">
//                         Gallery
//                     </h2>
//                 </div>
//                 <div className="heading-divider"></div>
//                 <div className="heading-description-wrap text-center">
//                     <div className="heading-description">
//                         {/* <p className="p1">
//                             <span className="s1">A thriving community of creativity and innovation</span>
//                         </p> */}
//                     </div>
//                 </div>
               
//             </div>
//             <div className="container-fluid">
//                     <div className='row g-4'>
//                             {images.length > 0 ? (
//                                 images?.map((image, index) => (
//                                     <div className="card col-md-4 col-sm-12 col-4" key={index} style={{border:"none"}}>
//                                         <div className="card-image" >
//                                             <Link to={image.galleryImage} data-fancybox="gallery" data-caption={`Caption Image ${index + 1}`}>
//                                                 <img src={image.galleryImage} alt={image.alt || `Image ${index + 1}`} style={{width:"100%",height:"300px",borderRadius:"10px"}} />
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 ))
//                             ) :
//                                 (
//                                     <p>No images available</p>
//                                 )
//                             }
//                     </div>

//                 </div>
//             <div>
//                 {/* <Trained /> */}
//             </div>
//             <div><br/>
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// export default Gallery






import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import { GetHomePageData } from '../../api/Global';
import Footer from '../footer/Footer';
import 'react-image-lightbox/style.css'; // Import lightbox CSS
import Lightbox from 'react-image-lightbox'; // Import Lightbox
import imagebaseurl from '../../config/imageurl';
import LazyLoad from 'react-lazyload';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loadedImages, setLoadedImages] = useState({}); // Track loaded images
    const [isImageLoaded, setIsImageLoaded] = useState(false); // Track if the clicked image is loaded

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await GetHomePageData();
                console.log('API Response:', response.data.data.galleryImages);
                if (Array.isArray(response.data.data.galleryImages)) {
                    setImages(response.data.data.galleryImages);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
                setError('Error fetching images');
            }
        };
        fetchImages();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleImageLoad = (index) => {
        setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
    };

    const handleImageClick = (index) => {
        // Preload the clicked image before opening the lightbox
        const img = new Image();
        img.src = `${imagebaseurl}/${images[index].galleryImage}`;

        img.onload = () => {
            setIsImageLoaded(true); // Mark image as fully loaded
            setIsOpen(true);
            setPhotoIndex(index);
        };
    };

    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded"
            data-elementor-device-mode="desktop">
            <Header />

            <div className="main lightGalleryddd mt-5">
                <div className="heading-primary-wrap text-center mt-5">
                    <h2 className="heading-primary elementor-heading-title">
                        Gallery
                    </h2>
                </div>
                <div className="heading-divider"></div>
            </div>
            <div className="container-fluid">
                <div className='row g-4'>
                    {images.length > 0 ? (
                        images.map((image, index) => (
                            <div className="card col-md-4 col-sm-12 col-4" key={index} style={{ border: "none" }}>
                                <LazyLoad className="card-image">
                                    <img
                                        src={`${imagebaseurl}/${image.galleryImage}`}
                                        alt={image.alt || `Image ${index + 1}`}
                                        style={{ width: "100%", height: "300px", borderRadius: "10px", cursor: "pointer" }}
                                        onClick={() => handleImageClick(index)}
                                        onLoad={() => handleImageLoad(index)} // Ensure image is loaded
                                    />
                                </LazyLoad>
                            </div>
                        ))
                    ) : (
                        <p>No images available</p>
                    )}
                </div>
            </div>

            {/* Lightbox component */}
            {isOpen && isImageLoaded && (
                <Lightbox
                    mainSrc={`${imagebaseurl}/${images[photoIndex].galleryImage}`}
                    nextSrc={`${imagebaseurl}/${images[(photoIndex + 1) % images.length]?.galleryImage}`}
                    prevSrc={`${imagebaseurl}/${images[(photoIndex + images.length - 1) % images.length]?.galleryImage}`}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}

            <Footer />
        </div>
    );
}

export default Gallery;
