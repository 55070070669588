import React, { useState } from 'react'
import { FaArrowRight } from "react-icons/fa";
import { FeedbackForm } from '../../api/Global';
const StudentFeedback = () => {
    const [userInfo, setUserInfo] = useState({
        userName: '',
        email: '',
        mobileNo: '',
        Branch: '',
        Year: ''
    });
    const [ratings, setRatings] = useState({
        par1: 0,
        par2: 0,
        par3: 0,
        par4: 0,
        par5: 0,
        par6: 0,
        par7: 0,
        par8: 0,
        par9: 0,
        par10: 0,
        par11: 0,
        par12: 0
    });

    const questions = [
        { id: 'par1', text: 'Rate the syllabus of the courses in relation to the competencies expected from the course' },
        { id: 'par2', text: 'Rate the relevance of the units in Syllabus relevant to the course' },
        { id: 'par3', text: 'Rate the content of course and relevance of the Units' },
        { id: 'par4', text: 'Rate the offering of the electives in terms of their relevance to the specialization streams' },
        { id: 'par5', text: 'Rate the electives offered in relation to the need of industry' },
        { id: 'par6', text: 'Rate the applicability/relevance of the curriculum with respect to current industry standards' },
        { id: 'par7', text: 'Usefulness of the course in terms of knowledge, concepts, vocational skills, analytical abilities and broadening perspectives' },
        { id: 'par8', text: 'Rate the percentage of courses having market exposure/industry exposure' },
        { id: 'par9', text: 'Rate the appropriateness of the sequence of courses provided in the curriculum' },
        { id: 'par10', text: 'Rate the depth of syllabus of the course in relation to the competencies expected by the Industry' },
        { id: 'par11', text: 'Rate the design of course with respect to self-learning' },
        { id: 'par12', text: 'Rate the Curriculum of the course in the accordance to the Current Trends' }
    ];

    

    // Handle personal info change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        });
       
    };

    // Handle radio button change for ratings
    const handleRatingChange = (parameter, value) => {
        setRatings({
            ...ratings,
            [parameter]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const atLeastOneRated = questions.some(question => ratings[question.id] !== undefined && ratings[question.id] !== 0);

        if (!atLeastOneRated) {
            alert("Please rate at least one question before submitting.");
            return;
        }
        
        const filteredRatings = questions
            .filter(question => ratings[question.id] !== undefined && ratings[question.id] !== 0)
            .map(question => ({
                parameter: question.text,
                rating: ratings[question.id]
            }));

        const payload = {
            formType: 'student',
            ...userInfo,
            ratings: filteredRatings
        };

        try {
            const response = await FeedbackForm(payload);
            console.log("response", response);
            if (response?.data?.status === true) {
                alert("Feedback created successfully");
                // Reset userInfo and ratings
                setUserInfo({
                    userName: '',
                    email: '',
                    mobileNo: '',
                    Branch: '',
                    Year: ''
                });

                setRatings({
                    par1: 0,
                    par2: 0,
                    par3: 0,
                    par4: 0,
                    par5: 0,
                    par6: 0,
                    par7: 0,
                    par8: 0,
                    par9: 0,
                    par10: 0,
                    par11: 0,
                    par12: 0
                });
            } else {
                alert("Failed to create feedback. Please try again.");
            }
        } catch (error) {
            console.log("error", error);
            alert("An error occurred while submitting the feedback. Please try again.");
        }
    };

    return (
        <div>
            <div className="overview-area aboutus-vi">
                <div className="container">
                    <div className="bg-ligh">
                        <form action="#" method="post" onSubmit={handleSubmit} className="wpcf7-form init">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">

                                            <div className="heading-primary-wrap">
                                                <h2 className="heading-primary elementor-heading-title">Feedback on
                                                    Curriculum (Student)
                                                </h2>
                                            </div>

                                            <div className="heading-divider"></div>

                                            <div className="heading-description-wrap">
                                                <div className="heading-description">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-widget-container">
                                    <div className="unicamp-contact-form-7">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>User Name</label>
                                                    <input
                                                        className="form-control border border-gray"  // Add border classes
                                                        placeholder="User Name"
                                                        value={userInfo.userName}
                                                        required
                                                        type="text"
                                                        name="userName"
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>Email ID</label>
                                                    <input className="form-control border border-gray" placeholder="Email ID" value={userInfo.email} required
                                                        type="email" name="email" onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>Enrollment</label>
                                                    <input className="form-control border border-gray" placeholder="Enrollment" value={userInfo.enrollment} required
                                                        type="text" name="enrollment " onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label>Branch</label>
                                                    <select className="form-control border-gray" required
                                                        type="text" name="Branch" onChange={handleInputChange}>
                                                        <option value="">-- Select --</option>
                                                        <option value="Computer Science & Engineering">Computer Science & Engineering </option>
                                                        <option value="Information Technology">Information Technology</option>
                                                        <option value="Electronics and Communication">Electronics and Communication</option>
                                                        <option value="Mechanical Engineering">Mechanical Engineering</option>
                                                        <option value="Civil Engineering">Civil Engineering</option>
                                                        <option value="Artificial Intelligence and Data Science">Artificial Intelligence and Data Science</option>
                                                        <option value="Computer Science and Information Technology">Computer Science and Information Technology</option>
                                                        <option value="Master of Computer Application">Master of Computer Application </option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>year</label>
                                                    <select className="form-control border-gray" required
                                                        name="Year" onChange={handleInputChange}>
                                                        <option value="">-- Select --</option>
                                                        <option value="First Year">I</option>
                                                        <option value="Second Year">II</option>
                                                        <option value="Third Year">III</option>
                                                        <option value="Fourth Year">IV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group  table-responsive">
                                                    <table className="table table-hover sportstbl ">
                                                    {/* // "table table-hover sportstbl"> */}
                                                        <thead>
                                                            <tr>

                                                                <th>
                                                                    <p>Parameter</p>
                                                                </th>
                                                                <th>
                                                                    <p>5(High)</p>
                                                                </th>
                                                                <th>
                                                                    <p>4</p>
                                                                </th>
                                                                <th>
                                                                    <p>3</p>
                                                                </th>
                                                                <th>
                                                                    <p>2</p>
                                                                </th>
                                                                <th>
                                                                    <p>1(Low)</p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {questions?.map((question) => (
                                                                <tr key={question.id}>
                                                                    <td>
                                                                        <p>{question.text}</p>
                                                                    </td>
                                                                    {[5, 4, 3, 2, 1].map((ratingValue) => (
                                                                        <td key={ratingValue}>
                                                                            <input
                                                                                type="radio"
                                                                                name={question.id}
                                                                                value={ratingValue}
                                                                                checked={ratings[question.id] === ratingValue}
                                                                                onChange={() => handleRatingChange(question.id, ratingValue)}
                                                                            />
                                                                            {ratingValue}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-end">
                                                <div className="form-groups">
                                                    <div className="form-submitss">
                                                        <button className="100%" type="submit" >
                                                            Submit
                                                            <span className="button-icon"><FaArrowRight />
                                                            </span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentFeedback