import React, { useState } from 'react'
import { FaArrowRight } from "react-icons/fa";
import { FeedbackForm } from '../../api/Global';
const StudentInfraStructure = () => {
    const [userInfo, setUserInfo] = useState({
        userName: '',
        email: '',
        mobileNo: '',
        Branch: '',
        Year: ''
    });
    const [ratings, setRatings] = useState({
        par1: 0,
        par2: 0,
        par3: 0,
        par4: 0,
        par5: 0,
        par6: 0,
        par7: 0,
        par8: 0
    });

    const questions = [
        { id: 'par1', text: 'The institute has well equipped classroom with projector facility' },
        { id: 'par2', text: 'The institute has well equipped laboratories' },
        { id: 'par3', text: 'The institute has the adequate sports facilities' },
        { id: 'par4', text: 'The institute provides a safe and caring environment' },
        { id: 'par5', text: 'The institute has adequate hostel facility' },
        { id: 'par6', text: 'The institute has adequate canteen facility' },
        { id: 'par7', text: 'The institute has safe & secure transport facility' },
        { id: 'par8', text: 'Would you like to recommend this institute to other students?' }
    ];

    

    // Handle personal info change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        });
       
    };

    // Handle radio button change for ratings
    const handleRatingChange = (parameter, value) => {
        setRatings({
            ...ratings,
            [parameter]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const atLeastOneRated = questions.some(question => ratings[question.id] !== undefined && ratings[question.id] !== 0);

        if (!atLeastOneRated) {
            alert("Please rate at least one question before submitting.");
            return;
        }
        
        const filteredRatings = questions
            .filter(question => ratings[question.id] !== undefined && ratings[question.id] !== 0)
            .map(question => ({
                parameter: question.text,
                rating: ratings[question.id]
            }));

        const payload = {
            formType: 'student',
            ...userInfo,
            ratings: filteredRatings
        };

        try {
            const response = await FeedbackForm(payload);
            console.log("response", response);
            if (response?.data?.status === true) {
                alert("Feedback created successfully");
                // Reset userInfo and ratings
                setUserInfo({
                    userName: '',
                    email: '',
                    mobileNo: '',
                    Branch: '',
                    Year: ''
                });

                setRatings({
                    par1: 0,
                    par2: 0,
                    par3: 0,
                    par4: 0,
                    par5: 0,
                    par6: 0,
                    par7: 0,
                    par8: 0
                });
            } else {
                alert("Failed to create feedback. Please try again.");
            }
        } catch (error) {
            console.log("error", error);
            alert("An error occurred while submitting the feedback. Please try again.");
        }
    };

    return (
        <div>
            <div className="overview-area aboutus-vi">
                <div className="container">
                    <div className="bg-ligh">
                        <form action="#" method="post" onSubmit={handleSubmit} className="wpcf7-form init">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="elementor-widget-container">
                                        <div className="tm-modern-heading">

                                            <div className="heading-primary-wrap">
                                                <h2 className="heading-primary elementor-heading-title">Feedback on Infrastructure Facility (Student)
                                                </h2>
                                            </div>

                                            <div className="heading-divider"></div>

                                            <div className="heading-description-wrap">
                                                <div className="heading-description">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-widget-container">
                                    <div className="unicamp-contact-form-7">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>User Name</label>
                                                    <input
                                                        className="form-control border border-gray"  // Add border classes
                                                        placeholder="User Name"
                                                        value={userInfo.userName}
                                                        required
                                                        type="text"
                                                        name="userName"
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>Email ID</label>
                                                    <input className="form-control border border-gray" placeholder="Email ID" value={userInfo.email} required
                                                        type="email" name="email" onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>Enrollment</label>
                                                    <input className="form-control border border-gray" placeholder="Enrollment" value={userInfo.enrollment} required
                                                        type="text" name="enrollment " onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label>Branch</label>
                                                    <select className="form-control border-gray" required
                                                        type="text" name="Branch" onChange={handleInputChange}>
                                                        <option value="">-- Select --</option>
                                                        <option value="B.TECH">B.TECH</option>
                                                        <option value="BBA">BBA</option>
                                                        <option value="BCA">BCA</option>
                                                        <option value="B COM">B COM</option>
                                                        <option value="MBA">MBA</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>year</label>
                                                    <select className="form-control border-gray" required
                                                        name="Year" onChange={handleInputChange}>
                                                        <option value="">-- Select --</option>
                                                        <option value="First Year">I</option>
                                                        <option value="Second Year">II</option>
                                                        <option value="Third Year">III</option>
                                                        <option value="Third Year">IV</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group table-responsive ">
                                                    <table className="table table-hover sportstbl">
                                                        <thead>
                                                            <tr>

                                                                <th>
                                                                    <p>Parameter</p>
                                                                </th>
                                                                <th>
                                                                    <p>5(High)</p>
                                                                </th>
                                                                <th>
                                                                    <p>4</p>
                                                                </th>
                                                                <th>
                                                                    <p>3</p>
                                                                </th>
                                                                <th>
                                                                    <p>2</p>
                                                                </th>
                                                                <th>
                                                                    <p>1(Low)</p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {questions?.map((question) => (
                                                                <tr key={question.id}>
                                                                    <td>
                                                                        <p>{question.text}</p>
                                                                    </td>
                                                                    {[5, 4, 3, 2, 1].map((ratingValue) => (
                                                                        <td key={ratingValue}>
                                                                            <input
                                                                                type="radio"
                                                                                name={question.id}
                                                                                value={ratingValue}
                                                                                checked={ratings[question.id] === ratingValue}
                                                                                onChange={() => handleRatingChange(question.id, ratingValue)}
                                                                            />
                                                                            {ratingValue}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 text-end">
                                                <div className="form-groups">
                                                    <div className="form-submitss">
                                                        <button className="100%" type="submit" >
                                                            Submit
                                                            <span className="button-icon"><FaArrowRight />
                                                            </span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentInfraStructure