import React, { useState, useEffect } from 'react';
import { PostOverTabsData } from '../../api/Global';
import { useParams } from 'react-router-dom';
import Header from '../header/Header';
// import Trained from '../Trained/Trained';
import Footer from '../footer/Footer';
import imagebaseurl from '../../config/imageurl';


const Pdf_Open = () => {
    const { _id } = useParams();
    const [OverViewPageDetails, setOverViewPageDetails] = useState({});

    // Fetch the PDF details
    const GetOverDataDetails = async (Overview_id) => {
        try {
            const response = await PostOverTabsData(Overview_id);
            setOverViewPageDetails(response?.data?.data?.docs);
        } catch (error) {
            console.log("error", error);
        }
    };

    // Add event listeners for preventing right-click and printing
    useEffect(() => {
        GetOverDataDetails(_id);

        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        const disablePrintShortcut = (e) => {
            if ((e.ctrlKey && e.key === 'p') || (e.metaKey && e.key === 'p')) {
                e.preventDefault();
                alert("Printing is disabled.");
            }
        };

        // Attach the event listeners
        document.addEventListener('contextmenu', disableContextMenu);
        document.addEventListener('keydown', disablePrintShortcut);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('contextmenu', disableContextMenu);
            document.removeEventListener('keydown', disablePrintShortcut);
        };
    }, [_id]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
        page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
        e--ua-chrome e--ua-webkit loaded"
            data-elementor-device-mode="desktop">
            <Header />
            <div className="container text-center">
                {OverViewPageDetails.length > 0 ? (
                    <div style={{ marginTop: '100px' }}>
                        {/* Display the PDF title */}
                        <h3>{OverViewPageDetails[0]?.fileName}</h3>

                        {/* Embed the PDF */}
                        <iframe
                            src={`${imagebaseurl}/${OverViewPageDetails[0]?.fileUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                            type="application/pdf"
                            width="80%"
                            height="800px"
                            style={{
                                width: '100%',
                                border: 'none'
                            }}
                            title="PDF Viewer"
                        />
                    </div>
                ) : (
                    <p>Loading PDF...</p>
                )}


            </div>
            <div>
                {/* <Trained /> */}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default Pdf_Open;
