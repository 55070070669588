import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/444.png'
// import { Card } from 'react-bootstrap'
import Header from '../header/Header'
import { ContactUs } from '../../api/Global'
import Footer from '../footer/Footer'
import axios from 'axios'
import BaseUrl from '../../config/Config'
import { FaWhatsapp } from 'react-icons/fa'

const Contact_us = () => {
	const [name,setName] = useState([])
	const [email,setEmail] = useState([])
	const [mobileNumber,setMobile] = useState([])
	const [message,setMessage] = useState([])
	const [contactDetails,setContactDetails]=useState([])
	const HandleContactUs = async () => {
		const data = {
			name,
			email,
			mobileNumber,
			message
		}
		try {
			const response = await ContactUs(data);
			console.log("HandleApplyHere_formdata", data)
			if(response?.data?.code == "201"){
				alert("Contact message submitted successfully")
				setName("")
			setEmail("")
			setMobile("")
			setMessage("")
			}
			
		} catch (error) {
       console.log("error",error)
		}
	}
	
	 
	  useEffect(() => {
		const fetchContactDetails = async () => {
		  try {
			const response = await axios.get(`${BaseUrl}/get-contact-us-address`);  
			setContactDetails(response.data.data);
			console.log('Contact Us',response.data);
			
		  } catch (error) {
			console.error('Error fetching contact details:', error);
		  }
		};
	
		fetchContactDetails();
		window.scrollTo(0, 0);
	  }, []);
	  const handleWhatsAppClick = () => {
		const phoneNumber = "9109998199"; 
		const message = "Hello! I have a question.";
		const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
		window.open(whatsappLink, "_blank", "noopener,noreferrer");
	  };
	
	
	// useEffect(() => {
    //     window.scrollTo(0, 0);
    //   }, []);
	  
	return (
		<div className="page-template-default page page-id-2585 desktop desktop-menu mobileNumber-menu-push-to-left header-sticky-both wide 
	page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
	e--ua-chrome e--ua-webkit loaded">
			<Header />
			<section className="about-breadcrumb breadcumb-bg"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					width: '100%',
					height: '400px'
				}}>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="breadcrumb-content">
								<ul className="breadcrumb">
									{/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item">/</li> */}
									{/* <li className="breadcrumb-item active" aria-current="page">Contact us</li> */}
								</ul>
								<h2 className="heading-title">Contact us</h2>
							</div>
						</div>
					</div>
				</div>
			</section >
			<section className="contact_sec">
				<div className="container">
					<div className="contact_top" >
						<div className="row">
							{contactDetails?.map((contact, index)=>(

							<div className="col-md-6 col-lg-4 col-sm-6">
									<div className="elementor-widget-wrap company__address bg-white p-0 mb-4">
										<div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
											<div className="unicamp-box unicamp-contact-box">
												{contact.icon &&(
													<img src={contact.icon}alt=''/>
												)}
												<h4 className="contact-box-title">{contact.title}</h4>
												<div className="contact-box-info">
												
												<div className="contact-box-info-item youtube">
												{contact.virtualTourLink && (
                                                    <a href={contact.virtualTourLink} target="_blank" rel="noopener noreferrer">
                                                        Visit {contact.title} Virtual Tour
                                                    </a>
                                                )}	</div>
                                                 <hr/>
												
													<div className="contact-box-info-item phone-number">
													<span className="meta-label">Address:</span>
														<span className="meta-value" >{contact.address}</span>													
													</div>
													<hr/>
													<div className="contact-box-info-item phone-number">
														<span className="meta-label">Mobile: <span className='meta-value'>{contact.mob_no}</span></span>
													   {contact.phone_no &&(

													    <span className="meta-label">Phone number:
															 <span className='meta-value'>{contact.phone_no}</span></span>
													   )}
												        <span className="meta-label">Fax: <span className='meta-value'>{contact.fax}</span></span>

														{/* <span className="meta-value">+91 7312538874, +91 7312531388</span> */}
													</div>
													<hr/>
													{ contact.email &&(
	                                                <div className="contact-box-info-item email-address">
														<span className="meta-label">E-mail:  </span>
														<span className="meta-value"> {contact.email}</span>
														{/* <span className="meta-value">info@gmail.com</span> */}
													</div>
													)}
												
												</div>

											</div>
										</div>

									</div>
							</div>
							))}
							{/* <div className="col-md-6 col-lg-4 col-sm-6">
									<div className="elementor-widget-wrap">
										<div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
											<div className="unicamp-box unicamp-contact-box">
												<h4 className="contact-box-title">CDGI</h4>
												<div className="contact-box-info">
													<div className="contact-box-info-item phone-number">
														<span className="meta-label">Phone number</span>
														<span className="meta-value">+91 7312538874, +91 7312531388</span>
													</div>

													<div className="contact-box-info-item email-address">
														<span className="meta-label">Email</span>
														<span className="meta-value">info@gmail.com</span>
													</div>
													<div className="contact-box-info-item address">
														<span className="meta-label">Address</span>
														<span className="meta-value">Agarwal House, 2nd Floor, 5, Yeshwant
															Colony, Indore</span>
													</div>
												</div>

											</div>
										</div>
									</div>
							</div>
							<div className="col-md-6 col-lg-4 col-sm-6">
									<div className="elementor-widget-wrap" style={{ fontFamily: 'Gordita' }}>
										<div className="unicamp-contact-box-style-01  animated unicampFadeInUp">
											<div className="unicamp-box unicamp-contact-box">
												<h4 className="contact-box-title">CDGI</h4>
												<div className="contact-box-info">
													<div className="contact-box-info-item phone-number">
														<span className="meta-label">Phone number</span>
														<span className="meta-value">+91 7312538874, +91 7312531388</span>
													</div>

													<div className="contact-box-info-item email-address">
														<span className="meta-label">Email</span>
														<span className="meta-value">info@gmail.com</span>
													</div>
													<div className="contact-box-info-item address">
														<span className="meta-label">Address</span>
														<span className="meta-value">Agarwal House, 2nd Floor, 5, Yeshwant
															Colony, Indore</span>
													</div>
												</div>

											</div>
										</div>

									</div>
							</div> */}

						</div>
						<div className="row mt-5">

							<div className="col-lg-6">
								<div className="googleMap">
									<iframe
										src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d117869.75914176971!2d75.87244208478276!3d22.600413462170483!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fb28a5660d8b%3A0x2a7a0698a930c80f!2sChameli+Devi+Group+Of+Institutions!5e0!3m2!1sen!2s!4v1401125378696"
										width="100%" height="100%" frameborder="0" style={{ border: '0', height: '650px' }}></iframe>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="bg-white">
									<div className="elementor-widget-container">
										<div className="tm-modern-heading">
											<div className="heading-primary-wrap">
												<h2 className="heading-primary elementor-heading-title">GET IN TOUCH
												</h2>
											</div>
											<div className="heading-divider"></div>
											<div className="heading-description-wrap">
												<div className="heading-description">
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-widget-container">
										<div className="unicamp-contact-form-7">

											<div className="wpcf7">
												<form action="#"   className="wpcf7-form init">
													<div className="form-group">
														<lable>Your Name</lable>
														<input className="form-control" placeholder="Name" value={name} name='name'
														onChange={(e)=>setName(e.target.value)} type="text"/>
													</div>
													<div className="form-group">
														<lable>Your Email</lable>
														<input className="form-control" placeholder="Email ID" value={email} name="email"
														onChange={(e)=>setEmail(e.target.value)}
															type="text"  />
													</div>
													<div className="form-group">
														<label>mobileNumber Number</label>
														<input className="form-control" placeholder="phone" type="text" 
														onChange={(e)=>setMobile(e.target.value)}
															name="mobileNumber" value={mobileNumber} />
													</div>
													<div className="form-group">
														<label>Message</label>
														<textarea onChange={(e)=>setMessage(e.target.value)}
															name="message" value={message} id="InputMessage" className="form-control"
															rows="5" required="" placeholder=""></textarea>
													</div>
													<div className="form-group">
														<div className="form-submit">
															<button type="button" onClick={HandleContactUs}>
																Submit
															</button>
														</div>
													</div>

												</form>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<button
                onClick={handleWhatsAppClick}
                style={{
                  position: "fixed",
                  bottom: "20px",
                  right: "80px",
                  zIndex: "9999",
                  backgroundColor: "#25D366",
                  color: "white",
                  border: "none",
                  outline: "none",
                  padding: "10px 15px",
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <FaWhatsapp size={40} />
                Chat for Admission Enquiry
              </button>
			<Footer />
		</div>
	)
}

export default Contact_us
