import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";
import ReactHtmlParser from "react-html-parser";
import { GetHomePageData } from '../../api/Global';
import imagebaseurl from '../../config/imageurl';

const Campus = () => {
    const [CampusList, setCampusList] = useState([])

    useEffect(() => {
        const FetchCampusList = async () => {
            try {
                const response = await GetHomePageData()
                setCampusList(response.data.data.campusLife)
                console.log(response.data.data.campusLife)
            } catch (error) {
                console.log(error);

            }
        }
        FetchCampusList()
    }, [])
    return (
        <div>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-44a8a64 elementor-section-boxed elementor-section-gap-beside-yes elementor-section-height-default elementor-section-height-default elementor-section-column-vertical-align-stretch" data-id="44a8a64" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-row">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e30916b" data-id="e30916b" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-45c243c elementor-section-gap-beside-no elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section-column-vertical-align-stretch" data-id="45c243c" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-extended">
                                            <div className="elementor-row">
                                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1ab3777" data-id="1ab3777" data-element_type="column">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <div className="elementor-widget-wrap">
                                                            <div className="elementor-element elementor-element-3e09a4c elementor-invisible elementor-widget elementor-widget-tm-heading" data-id="3e09a4c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}" data-widget_type="tm-heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="tm-modern-heading" style={{ textAlign: 'center' }}>

                                                                        <div className="heading-primary-wrap">
                                                                            <h2 className="heading-primary elementor-heading-title">
                                                                            Life @ CDGI
                                                                            </h2>
                                                                        </div>

                                                                        <div className="heading-divider">
                                                                        </div>

                                                                        <div className="heading-description-wrap">
                                                                            <div className="heading-description">
                                                                                <p className="p1">
                                                                                    <span className="s1">A
                                                                                        thriving
                                                                                        community
                                                                                        of
                                                                                        creativity
                                                                                        and
                                                                                        innovation</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-dd3701f
                                     elementor-section-gap-beside-no elementor-section-boxed elementor-section-height-default elementor-section-height-default
                                      elementor-section-column-vertical-align-stretch" data-id="dd3701f" data-element_type="section">
                                        <div className="row text-center" style={{ textAlign: 'center' }}>
                                            {CampusList?.map((CampusResult, index) => (
                                               
                                                    <div key={index} className="col-md-6 col-lg-4 col-sm-6"
                                                     data-id={`column-${index}`} data-element_type="column">
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                            <div className="elementor-widget-wrap">
                                                                <div className="elementor-element elementor-element-unique-widget unicamp-animation-zoom-in unicamp-graphic-position-top elementor-widget" data-id={`widget-${index}`} data-element_type="widget" data-settings='{&quot;_animation&quot;:&quot;unicampFadeInUp&quot;}' data-widget_type="tm-image-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <Link className="tm-image-box unicamp-box style-04 link-secret" to={`Campus_Life_Details/${CampusResult?._id}`}>
                                                                            <div className="unicamp-graphic-box content-wrap">
                                                                                <div className="unicamp-graphic-element image-wrap">
                                                                                    <div className="unicamp-image image">
                                                                                        <img src={`${imagebaseurl}/${CampusResult?.images}`}  alt={CampusResult.title} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="unicamp-graphic-content box-caption-wrap">
                                                                                    <div className="box-caption">
                                                                                        <h3 className="title"> {ReactHtmlParser(CampusResult?.title)}</h3>
                                                                                        <div className="description"> {ReactHtmlParser(CampusResult?.content)}</div>
                                                                                        <div className="tm-button-wrapper">
                                                                                            <div className="tm-button style-text tm-button-nm icon-right">
                                                                                                <div className="button-content-wrapper">
                                                                                                    <span className="button-text">Learn more</span>
                                                                                                    <span className="button-icon">
                                                                                                       <FaArrowRightLong />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                            ))}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Campus
