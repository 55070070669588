import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/444.png'
import aboutus from '../../assets/images/about-us.png'
import IIT_Indore from '../../assets/images/cdil/IIT_Indore.jpg'
import apa469 from '../../assets/images/cdil/apa469.jpg'
import apa467 from '../../assets/images/cdil/apa467.jpg'
import apa465 from '../../assets/images/cdil/apa465.jpg'
import apa466 from '../../assets/images/cdil/apa466.jpg'
import apa464 from '../../assets/images/cdil/apa464.jpg'
import apa470 from '../../assets/images/cdil/apa470.jpg'
import apa457 from '../../assets/images/cdil/apa457.jpg'
import Header from '../header/Header'
// import Trained from '../Trained/Trained'
import Footer from '../footer/Footer'
import { FaPlus, FaMinus } from 'react-icons/fa';
import IIT_indore from "../../assets/images/cdil/IIT_Indore.jpg"
import appa457 from "../../assets/images/cdil/apa457.jpg"
// import apa464 from "../../assets/images/cdil/apa464.jpg"
import wdc from "../../assets/images/wdc.JPG"
const Wdc = () => {
	const [activeAccordion, setActiveAccordion] = useState(null);
	const [activeKey, setActiveKey] = useState(0);

	const toggleAccordion = (id) => {
		setActiveAccordion(activeAccordion === id ? null : id);
	};

	const events = [
		{
			id: 1,
			image: IIT_Indore,
			title: 'IIT Experts Lecture Series at CDGI Campus',
			date: '10 August 2024',
			time: '05:30 PM',
			images: [IIT_Indore]
		},
		{
			id: 2,
			image: apa469,
			title: 'CDIL: MOOT Court Inauguration & Intra Level MOOT Court Competition',
			date: '10 August 2024',
			time: '05:30 PM',
			images: [apa469, apa470]
		},
		{
			id: 3,
			image: apa467,
			title: 'CDIL organized Two-days International Conference on "Gender Justice & Social Transformation"',
			date: '09-10 August 2024',
			time: '05:30 PM',
			images: [apa464, apa465, apa466, apa467]
		},
		{
			id: 4,
			image: apa457,
			title: 'A Seminar on CPBFI Program conducted by Bajaj Finserv for students of MBA to explain and encourage students for career in Financial services',
			date: '22 July 2024',
			time: '01:30 PM',
			images: [apa457]
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
	page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
	e--ua-chrome e--ua-webkit loaded"
			data-elementor-device-mode="desktop">
			<Header />
			< section className="about-breadcrumb breadcumb-bg"
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					width: '100%',
					height: '400px'
				}}>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="breadcrumb-content">
								<ul className="breadcrumb">
									{/* <li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link >/</Link></li> */}
									<li className="breadcrumb-item active" aria-current="page">Women Development Centre</li>
								</ul>
								<h2 className="heading-title">Women Development Centre</h2>
							</div>
						</div>
					</div>
				</div>
			</section >
			<div className='row text-center m-3'>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
					<div className={`p-2 m-1 ${activeKey == 0 ? "active-tab1" : "inactive-tab1"}`}
						onClick={() => {
							setActiveKey(0)
						}}>
						<p>About Women Development Centre(WDC)</p>
					</div>
				</div>
				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-0 customeps'>
					<div className={`p-2 m-1 ${activeKey == 1 ? "active-tab1" : "inactive-tab1"}`}
						onClick={() => {
							setActiveKey(1)
						}}>
						<p>WDC Activities</p>
					</div>
				</div>

			</div>
			<div className='mt-5'>
				{
					activeKey == 0 && (
						<div class="overview-area aboutus-vi">
							{/* <div class="container">
								<div class="row">
									<div class="col-lg-6">
										<div class="overview-content">
											<div class="image">
												<img src={wdc}
													alt="overview-image" />
											</div>

										</div>
									</div>
									<div class="col-lg-6">
										<div class="overview-image aboutover">
											<div class="title">
												<h2>
													Women Development Centre (WDC)
												</h2>

												<p>The Department of Training and Placement (T & P) at CDGI is
													vibrant and strives to place the final year students of MBA and
													BE. The department of T & P is known for extending full time
													services to the students by providing proper guidelines to get
													placed in campus interviews. The team at T & P department
													strives hard to contact company officials and invite them to
													visit the institute for recruiting our eligible students.</p>
												<p><strong>Every year several companies are approached and a good
													number of students get placed.</strong></p>

											</div>

										</div>
									</div>
								</div>

							</div> */}
							<p><br />
							</p>

							<h2  style={{lineHeight:"1.5em"}}><strong>Women’s Development Centre</strong></h2>
							<div></div>
							<ul>
								<li  style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Concept</strong></div>
								</li>
							</ul>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>Women play an important role in the society. They are the epicenter of various activities that buzz around them. It is important that women who take up challenging roles in career are encouraged and provided with opportunities to develop professionally.</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>CDGI is steadily growing group of Institutes, which has facilitated Girls to progress professionally, providing higher technical education to be at par with the highly competitive world. It is imperative that the Girls Students and Lady Faculty are given adequate support to meet up the challenges for them to cope up with work –life –balance as well as grow professionally as competent human resources.</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>A<span style={{backgroundColor:"rgb(255, 255, 0)"}}><strong>Women’s Development Centre</strong></span>at CDGI is planned to benefit women from this platform. The WDC aims to provide professional guidance to women and girl students of CDGI in building up their leadership qualities, Professional ethics, stress management, dealing with health, hygiene and diet issues to be successful professional.</p>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div 
									style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Constitution of the Centre</strong></div>
								</li>
							</ul>

							<table>
								<tbody>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Advisor –Ms. Bonani Dhar, Adviser Faculty &amp; Students Development Center Development Sociologist, Gender &amp; Training Specialist Ex-World Bank &amp; UN</strong></p>
										</td>
										<div class="se-component se-image-container __se__float- __se__float-none">
											<figure>
												<img src="https://cdgi.edu.in/images/bdi.jpg"
													style={{boxSizing:"border-box",margin:"0px",padding:"0px",border:"0px",verticalAlign:"middle"}}
													alt="" data-proportion="true" data-align="none" data-index="0" data-file-name="bdi.jpg"
													data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex=""
													data-rotatey="" width="" height="" data-percentage="auto,auto" />
											</figure>
										</div>
										<td rowspan="2"></td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}>Bonani Dhar is a Development Sociologist and has 28 years of National and International experience in various development sectors and particularly Training and Human Resource Development, working with the World Bank, United Nations and various other Public Sector Organizations, like NTPC, Coal India, IFFCO and Administrative Staff College, Hyderabad.</p>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Activities promoting Women’s Development, Awareness &amp; Empowerment</strong></div>
								</li>
							</ul>

							<ul>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Women Friendly Policy</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Awareness on Burning issues impacting women’s lives</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Development of Management abilities as women</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Professional Ethics</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Leadership and Confidence Building</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Teaching Ethics</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Awareness on Challenges and issues impacting women’s lives</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Women Faculty and Girl Students’ holistic development;</p>
								</li>
							</ul>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Professional Growth</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Work-Life Balance</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Health, Hygiene issues</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Child Care</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Self-Defense</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Dress code in work place</p>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■ Management and Multi Tasking</p>

							<ul>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Grievance Redressal</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Sexual harassment Policy</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Awareness about forms of Domestic Violence</p>
								</li>
							</ul>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Method</strong></div>
								</li>
							</ul>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>Lady Faculty Members and Girl Students will be encouraged to understand the issues and challenges impacting their professional growth and ways and means of overcoming them skillfully. Women prove to be good managers if their awareness levels are raised. Hence,</p>

							<ul>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Orientation programs;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Training Programs on Professional Ethics, Management abilities, leadership Qualities, Stress Management;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Symposiums, debates etc.</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Seminars will be organized;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Inviting dignitaries from various sectors;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Opportunities in the Media and Public Platform to enable our Girl Students to gain confidence;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Sports, Gymnasium, games, swimming, Marathon Running etc. will be facilitated;</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Projects for women’s benefits through Social Corporate Responsibilities; connecting Industries, Corporate Houses and Institution,</p>
								</li>
								<li>
									<p style={{lineHeight:"26px",textAlign:"justify"}}>► Interactions with Women Leaders, Women IAS Officers &amp; Police Officers, Women in Navy, Air Force, Army and Banking Sector;</p>
								</li>
							</ul>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Visibility on E-Campus &amp; Website</strong></div>
								</li>
							</ul>

							<p style={{lineHeight:"26px",textAlign:"justify"}}>The WDC activities will be visible in a vibrant manner to all the students internally and the visitors to the website.</p>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Conclusion</strong></div>
								</li>
							</ul>

							<table>
								<tbody>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}>Through the Women’s Development Centre, all the CDGI, CDIPS and CDIP Institutions will come under one platform to facilitate Women’s Development. It will be a step towards institutionalizing the process and take the crusade to higher levels.</p>
										</td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Conceptualized &amp; Written in Consultation with Team WDC by:</strong></p>
										</td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Bonani Dhar</strong></p>
										</td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Adviser Faculty &amp; Students Development Cell (FSDC), CDGI</strong></p>
										</td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Development Sociologist, Gender &amp; Training Specialist</strong></p>
										</td>
									</tr>
									<tr>
										<td>
											<p style={{lineHeight:"26px",textAlign:"justify"}}><strong>Ex-World Bank &amp; UN</strong></p>
										</td>
									</tr>
								</tbody>
							</table>

							<ul>
								<li style={{fontSize:"16px",color:"rgb(101, 101, 101)"}}><em class="fa fa-angle-double-right"></em>
									<div style={{boxSizing:"border-box",margin:"0px",padding:"0px",overflow:"hidden"}}><strong>Women Development Cell Committee Members</strong></div>
								</li>
							</ul>

							<table>
								<thead>
									<tr>
										<th>
											S.No.</th>
										<th>
											Name</th>
										<th>
											Designation</th>
										<th>
											Position</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											1</td>
										<td>
											Ms. Bonani Dhar</td>
										<td>
											Adviser</td>
										<td>
											Chairperson</td>
									</tr>
									<tr>
										<td>
											2</td>
										<td>
											Ms. Priyanka Soni</td>
										<td>
											Associate Professor, Pharmacy</td>
										<td>
											Head Coordinator</td>
									</tr>
									<tr>
										<td>
											3</td>
										<td>
											Ms. Ankita Pal</td>
										<td>
											Asst. Prof., Law</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											4</td>
										<td>
											Ms. Swati Upadhyay</td>
										<td>
											Asst. Prof., ESH</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											5</td>
										<td>
											Ms. Srushti Patidar</td>
										<td>
											Asst. Prof., IT</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											6</td>
										<td>
											Ms. Shainee Solomon</td>
										<td>
											Asst. Prof., MBA</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											7</td>
										<td>
											Ms. Rajni Ahirwar</td>
										<td>
											Asst. Prof., MBA</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											8</td>
										<td>
											Ms. Surbhi Jain</td>
										<td>
											Asst. Prof., ESH</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											9</td>
										<td>
											Ms. Ayushi Arzare</td>
										<td>
											Asst. Prof., CSE</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											10</td>
										<td>
											Ms. Olinka Panhalkar</td>
										<td>
											Asst. Prof., MBA</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											11</td>
										<td>
											Ms. Tanvee Nema</td>
										<td>
											Asst. Prof., AD</td>
										<td>
											Member</td>
									</tr>
									<tr>
										<td>
											12</td>
										<td>
											Ms. Nikita Upadhyay</td>
										<td>
											Asst. Prof., Pharmacy</td>
										<td>
											Member</td>
									</tr>
								</tbody>
							</table>

							<hr />

						</div>
					)
				}

				{
					activeKey == 1 && (
						<div>
							<div class="overview-area aboutus-vi">
								<div class="container">
									<div class="row">
										<div class="accordion-container">

											<div class="accordion_view">
												<a>
													<div class="dddddd">
														<div class="unicamp-boxlink-secret">

															<div class="event-thumbnail-image">
																<div class="card cardevents">
																	<div class="card-image">
																		<div href="include/main/wp-content/themes/unicamp/assets/images/cdil/IIT_Indore.jpg"
																			data-fancybox="gallery" data-caption="Caption Images 1">
																			<img src={IIT_indore}
																				alt="Image Gallery" />
																		</div>
																	</div>
																</div>
															</div>

															<div class="event-info">
																<div class="event-caption">
																	<h5>IIT Experts Lecture Series at CDGI Campus</h5>
																	<div class="event-meta">
																		<div class="meta-list">
																			<div class="metaevent-time">05:30 Pm</div>
																			<div class="metaevent-time">10 August 2024
																			</div>


																		</div>
																	</div>
																</div>
															</div>

														</div>


													</div>
													{/* <i class="fa fa-plus"></i> */}
												</a>
												<div class="content">
													<div class="row">
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/IIT_Indore.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src={appa457}
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>

											<div class="accordion_view">
												<a>
													<div class="dddddd">
														<div class="unicamp-boxlink-secret">

															<div class="event-thumbnail-image">
																<div class="card cardevents">
																	<div class="card-image">
																		<div href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa469.jpg"
																			data-fancybox="gallery" data-caption="Caption Images 1">
																			<img src={apa464}
																				alt="Image Gallery" />
																		</div>
																	</div>
																</div>
															</div>

															<div class="event-info">
																<div class="event-caption">
																	<h5>CDIL: MOOT Court Inauguration & Intra Level MOOT Court Competition
																	</h5>
																	<div class="event-meta">
																		<div class="meta-list">
																			<div class="metaevent-time">05:30 Pm</div>
																			<div class="metaevent-time">10 August 2024
																			</div>


																		</div>
																	</div>
																</div>
															</div>

														</div>


													</div>
													{/* <i class="fa fa-plus"></i> */}
												</a>
												<div class="content">
													<div class="row">
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa469.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src={apa469}
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa469.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src={apa469}
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
											<div class="accordion_view">
												<a>
													<div class="dddddd">
														<div class="unicamp-boxlink-secret">

															<div class="event-thumbnail-image">
																<div class="card cardevents">
																	<div class="card-image">
																		<div href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa467.jpg"
																			data-fancybox="gallery" data-caption="Caption Images 1">
																			<img src={apa467}
																				alt="Image Gallery" />
																		</div>
																	</div>
																</div>
															</div>

															<div class="event-info">
																<div class="event-caption">
																	<h5>CDIL organized Two-days International Conference on "Gender Justice
																		& Social Transformation"</h5>
																	<div class="event-meta">
																		<div class="meta-list">
																			<div class="metaevent-time">05:30 Pm</div>
																			<div class="metaevent-time"> 09-10 August 2024
																			</div>


																		</div>
																	</div>
																</div>
															</div>

														</div>


													</div>
													{/* <i class="fa fa-plus"></i> */}
												</a>
												<div class="content">
													<div class="row">
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa464.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src={apa464}
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa465.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src="include/main/wp-content/themes/unicamp/assets/images/cdil/apa465.jpg"
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa466.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src="include/main/wp-content/themes/unicamp/assets/images/cdil/apa466.jpg"
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa467.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src="include/main/wp-content/themes/unicamp/assets/images/cdil/apa467.jpg"
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
											<div class="accordion_view">
												<a>
													<div class="dddddd">
														<div class="unicamp-boxlink-secret">

															<div class="event-thumbnail-image">
																<div class="card cardevents">
																	<div class="card-image">
																		<div href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa457.jpg"
																			data-fancybox="gallery" data-caption="Caption Images 1">
																			<img src={apa457}
																				alt="Image Gallery" />
																		</div>
																	</div>
																</div>
															</div>

															<div class="event-info">
																<div class="event-caption">
																	<h5>A Seminar on CPBFI Program conducted by Bajaj Finserv for students
																		of MBA to explain and encourage students for career in Financial
																		services</h5>
																	<div class="event-meta">
																		<div class="meta-list">
																			<div class="metaevent-time">01:30 Pm</div>
																			<div class="metaevent-time"> 22 July 2024
																			</div>


																		</div>
																	</div>
																</div>
															</div>

														</div>


													</div>
													{/* <i class="fa fa-plus"></i> */}
												</a>
												<div class="content">
													<div class="row">
														<div class="col-md-3">
															<div class="card gallery_event">
																<div class="card-image">
																	<a href="include/main/wp-content/themes/unicamp/assets/images/cdil/apa457.jpg"
																		data-fancybox="gallery" data-caption="Caption Images 1">
																		<img src="include/main/wp-content/themes/unicamp/assets/images/cdil/apa457.jpg"
																			alt="Image Gallery" />
																	</a>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>


										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
			{/* <Trained /> */}
			<Footer />
		</div>
	)
}

export default Wdc
