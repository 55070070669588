import React, { useEffect, useState } from "react";
import lightlogo from "../../assets/images/logo/light-logo.png";
import { Link } from "react-router-dom";
import Navbar1 from "../navbar/Navbar";
import "./header.css";
import { GetCategory, PostOverTabsData ,GetSettingsAdd} from "../../api/Global";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FaBars } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation } from "react-router-dom";
import imagebaseurl from "../../config/imageurl";

const Header = () => {
	const location = useLocation();
	const isHomepage = location.pathname === "/";
	const [isLoading, setIsLoading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [CategoryList, setCategoryList] = useState([]);
	const [OverViewPageDetails, setOverViewPageDetails] = useState({});
	const [showSideDropdown, setShowSideDropdown] = useState(false);
	const [sideDropdownData, setSideDropdownData] = useState([]);
	const [activeSubcategory, setActiveSubcategory] = useState(null);
	const [instituteIcon, setInstituteIcon] = useState(
		localStorage.getItem("instituteIcon")
	);
	const [isHovered, setIsHovered] = useState(false); // State to manage hover
	const [isClicked, setIsClicked] = useState(false)
	console.log("instituteIcon", instituteIcon);
	// const instituteIcon = localStorage.getItem("instituteIcon");
	const Second_instituteIcon = localStorage.getItem("Second_instituteIcon");
	const Third_instituteIcon = localStorage.getItem("Third_instituteIcon");
	const Fourth_instituteIcon = localStorage.getItem("Fourth_instituteIcon");
	const [data, setData] = useState([]);
    const [error, setError] = useState(null);
	const HandleSecondInstituteIcons = () => {
		setInstituteIcon(
			localStorage.setItem("instituteIcon", Second_instituteIcon)
		);
	};

	const HandleThirdInstituteIcons = () => {
		setInstituteIcon(
			localStorage.setItem("instituteIcon", Third_instituteIcon)
		);
	};

	const HandleFourthInstituteIcons = () => {
		setInstituteIcon(
			localStorage.setItem("instituteIcon", Fourth_instituteIcon)
		);
	};

	const [showDropdown, setShowDropdown] = useState(false);

	const handleClick = (subCategoryName, id) => {
		localStorage.setItem("selectedSubCategoryName", subCategoryName);
		localStorage.setItem("selectedSubCategoryId", id);
		setShowDropdown(false); // Close the dropdown after clicking any item
	};
	// Fetch the Category List

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetSettingsAdd();
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError("Error fetching data");
                }
            } catch (error) {
                console.error("Error fetching settings:", error);
                setError("Error fetching settings");
            }
        };

        fetchData();
    }, []);



	useEffect(() => {
		const HandleGetCatgoryList = async () => {
			setIsLoading(true);
			try {
				const response = await GetCategory();
				setCategoryList(response?.data?.data);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(true);
				console.log("error", error);
			}
		};
		HandleGetCatgoryList();
	}, []);
	console.log("HandleGetCatgoryList", CategoryList[1]?.subcategories[0]?._id);
	// Fetch Overview Data for Subcategory
	const GetOverDataDetails = async (id) => {
		try {
			const response = await PostOverTabsData(id);
			setOverViewPageDetails(response?.data?.data || {});
			setSideDropdownData(response?.data?.data?.subcategories || []);
			setActiveSubcategory(id);
			setShowSideDropdown(true);
		} catch (error) {
			console.log("error", error);
		}
	};

	// const handleMouseLeave = () => {
	// 	setShowSideDropdown(false);
	// 	setActiveSubcategory(null);
	// };
	const handleMouseEnter = (e) => {
		if (!isClicked) {
			e.target.style.backgroundColor = "#f0f0f0";
			e.target.style.color = "#0056b3";
			setIsHovered(true);
		}
	};



	const handleMouseLeave = (e) => {
		if (!isClicked) {
			e.target.style.backgroundColor = "";
			e.target.style.color = "";
			setIsHovered(false);
		}
	};
	useEffect(() => {
		const checkAndSave = () => {
			const id = CategoryList[1]?.subcategories[0]?._id;

			if (id !== undefined) {
				localStorage.setItem("Chameli_Devi_InstitueId", id);
				clearInterval(interval);
			}
		};
		const interval = setInterval(checkAndSave, 1000);

		return () => clearInterval(interval);
	}, [CategoryList]);
	return (
		<div>
			{isLoading ? (
				<>
					<div className="chat-window text-center">
						<div
							style={{
								position: "absolute",
								top: 0,
								bottom: 0,
								margin: "auto",
								zIndex: "999",
							}}
							className="spinner-border"
							role="status"
						>
							<span className="sr-only"></span>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="header-fixed">
						<Navbar1 />
						<header
							id="page-header"
							className="page-header header-03 header-light header-layout-fixed nav-links-hover-style-01 header-sticky-dark-logo"
						>

							<div className="page-header-place-holder"></div>
							<div
								id="page-header-inner"
								className="page-header-inner"
								data-sticky="1"
							>
								<div className="container">
									<div className="header-wrap">
										<div className="header-left">
											<div className="header-content-inner">
												<div className="branding">
													<div className="branding-logo-wrap">
														<Link
															to="/"
															rel="home"
															onClick={() => {
																localStorage.removeItem("College_id");
																localStorage.removeItem("instituteIcon");
															}}
														>
															{!instituteIcon || isHomepage ? (
																<>

                                                             {data.headerLogo && (
                                                          <img src={`${imagebaseurl}/${data.headerLogo}`} className="branding-logo light-logo" alt="Main" />
                                                                                            )}


																	{/* <img
																		src={lightlogo}
																		// style={{ height: "100px" }}
																		alt="Main"
																		className="branding-logo light-logo"
																	/> */}
																</>
															) : (
																<>
																	<img
																		src={`${imagebaseurl}/${instituteIcon}`}
																		// style={{ height: "10px" }}
																		alt="Main"
																		className="branding-logo light-logo"
																	/>
																</>
															)}
														</Link>
													</div>
												</div>
											</div>
										</div>

										<div className="header-right">
											<div className="header-content-inner">
												<div
													id="header-right-inner"
													className="header-right-inner"
												>
													<div className="header-right-inner-content">
														<div
															id="page-navigation"
															className="navigation page-navigation"
														>
															<nav id="menu" className="menu menu--primary">
																<ul
																	id="menu-primary"
																	className="menu__container sm sm-simple"
																>
																	<li
																		className="menu-item menu-item-type-custom menu-item-object-custom 
																menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children
																 menu-item-53 level-1"
																	>
																		<Link to="/">
																			<div className="menu-item">Home</div>
																		</Link>
																	</li>
																	{CategoryList?.slice(1)?.map((CategoryListResult, index) =>
																		index != 0 && CategoryListResult?.is_footer_category == false &&
																		(!CategoryListResult && !CategoryListResult?.instituteId == null) && (
																			<li
																				key={CategoryListResult?._id}
																				className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children level-1`}>
																				<Link to={`${CategoryListResult?._id}`}>   																				    

																					<div onClick={() => { localStorage.removeItem("ActiveTabs"); }}
																						className={`${CategoryListResult?._id === activeSubcategory ? "menu-item-wrap" : "menu-item"}`}>
																						{CategoryListResult?.subcategories?.length > 0 ? (<DropdownButton
																							className={`Drodown_menu ${CategoryListResult?._id === activeSubcategory ? "menu-item-wrap" : "menu-item"}`} id="dropdown-basic-button" title={CategoryListResult?.name}>
																							{CategoryListResult?.subcategories?.map(
																								(subcategoriesResult) => (
																									<React.Fragment
																										key={subcategoriesResult?._id}>
																										{subcategoriesResult?.type === "text" || subcategoriesResult?.type === "both" ? (
																											<>
																												{subcategoriesResult?.subcategories?.length > 0 ? (
																													<div className="submenucus"
																														onMouseEnter={() => GetOverDataDetails(subcategoriesResult?._id)}>
																														<div className="menusubtitle"
																															onMouseEnter={(e) => {
																																e.target.style.backgroundColor = "#f0f0f0";
																																e.target.style.color = "#0056b3";
																															}}
																															onMouseLeave={(e) => {
																																e.target.style.backgroundColor = "";
																																e.target.style.color = "";
																															}}>
																															<span>
																																{subcategoriesResult?.slug != "pharmacy-courses-ug-cdip" && subcategoriesResult?.slug != "professional-ug-and-pg-cdips" && subcategoriesResult?.slug != "law-courses-ug-cdil" && subcategoriesResult?.slug != "law" && (
																																	<span onMouseEnter={() => localStorage.removeItem("instituteIcon")}>{subcategoriesResult?.name}1</span>
																																)}

																																{subcategoriesResult?.slug == "pharmacy-courses-ug-cdip" && (
																																	<span onMouseEnter={HandleSecondInstituteIcons}>{subcategoriesResult?.name}</span>
																																)}
																																{subcategoriesResult?.slug == "professional-ug-and-pg-cdips" && (
																																	<span onMouseEnter={HandleThirdInstituteIcons}>{subcategoriesResult?.name}</span>
																																)}
																																{subcategoriesResult?.slug == "law-courses-ug-cdil" && (
																																	<span onMouseEnter={HandleFourthInstituteIcons}>
																																		{subcategoriesResult?.name}
																																	</span>
																																)}
																																{subcategoriesResult?.slug == "law" && (
																																	<span onMouseEnter={HandleFourthInstituteIcons}>
																																		{subcategoriesResult?.name}
																																	</span>
																																)}
																																<IoIosArrowForward />
																															</span>
																														</div>
																														{showSideDropdown && activeSubcategory === subcategoriesResult?._id && (
																															<div className="side-dropdown-content sidmenussp">
																																{sideDropdownData?.map((sideDropdownDataResult) => (
																																	<div
																																		key={sideDropdownDataResult?._id}
																																		onClick={() => window.location.reload()}>
																																		{sideDropdownDataResult?.type ===
																																			"text" ||
																																			sideDropdownDataResult?.type ===
																																			"both" ? (
																																			<Link 
																																			to={`/Overview/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																			// to={`/Overview/${subcategoriesResult?.slug}`}

																																				onClick={() => localStorage.setItem("selectedSubCategoryName", sideDropdownDataResult?.name)
																																				}>
																																				<div className="menusubtitle"
																																					onMouseEnter={(e) => {
																																						e.target.style.backgroundColor = "#f0f0f0";
																																						e.target.style.color = "#0056b3";
																																					}}
																																					onMouseLeave={(e) => {
																																						e.target.style.backgroundColor = "";
																																						e.target.style.color = "";
																																					}}>
																																					{sideDropdownDataResult?.name}
																																				</div>
																																			</Link>
																																		) : (
																																			<div onMouseEnter={(e) => {
																																				e.target.style.backgroundColor = "#f0f0f0";
																																				e.target.style.color = "#0056b3";
																																			}}
																																				onMouseLeave={(e) => {
																																					e.target.style.backgroundColor = "";
																																					e.target.style.color = "";
																																				}}>
																																				{/* <Link to={`/Pdf_Open/${sideDropdownDataResult?._id}`} onClick={() => { localStorage.setItem("selectedSubCategoryName", sideDropdownDataResult?.name); }}> */}
																																				<Link to={`/Pdf_Open/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`} onClick={() => { localStorage.setItem("selectedSubCategoryName", sideDropdownDataResult?.name); }}>
																																					{sideDropdownDataResult?.name}
																																				</Link>
																																			</div>
																																		)}
																																	</div>
																																)
																																)}
																															</div>
																														)}
																													</div>
																												) : (
																													<div
																														onClick={() => window.location.reload()}>
																														<Link 
																														to={`/Overview/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																														// to={`/Overview/${subcategoriesResult?.slug}`}

																														>

																															<div
																																onMouseEnter={(e) => {
																																	e.target.style.backgroundColor = "#f0f0f0";
																																	e.target.style.color = "#0056b3";
																																}}
																																onMouseLeave={(e) => {
																																	e.target.style.backgroundColor = "";
																																	e.target.style.color = "";
																																}}
																																onClick={() => {
																																	localStorage.setItem("selectedSubCategoryName", subcategoriesResult?.name);
																																	setShowDropdown(!showDropdown);
																																}}>
																																{subcategoriesResult?.slug != "bba-ft" && (
																																	<div onClick={() => { localStorage.removeItem("instituteIcon") }}>
																																		{subcategoriesResult?.name}
																																	</div>)}
																																{subcategoriesResult?.slug == "bba-ft" && (<span onClick={HandleSecondInstituteIcons}>{subcategoriesResult?.name}</span>)}
																															</div>
																														</Link>
																													</div>
																												)}
																											</>
																										) : subcategoriesResult?.type ===
																											"pdf" ? (
																											<div
																												onMouseEnter={(e) => {
																													e.target.style.backgroundColor = "#f0f0f0";
																													e.target.style.color = "#0056b3";
																												}}
																												onMouseLeave={(e) => {
																													e.target.style.backgroundColor = "";
																													e.target.style.color = "";
																												}}>
																												{/* <Link to={`/Pdf_Open/${subcategoriesResult?._id}`}>{subcategoriesResult?.name}</Link> */}
																												<Link to={`/Pdf_Open/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}>{subcategoriesResult?.name}</Link>
																											</div>
																										) : subcategoriesResult?.type === "link" ? (
																											<a
																												href={subcategoriesResult?.url}
																												target="_blank"
																												rel="noopener noreferrer"
																												onClick={() => {
																													// This will ensure the URL is hit on click
																													window.open(subcategoriesResult?.url, "_blank");
																												}}
																												onMouseEnter={(e) => {
																													e.target.style.backgroundColor = "#f0f0f0";
																													e.target.style.color = "#0056b3";
																												}}
																												onMouseLeave={(e) => {
																													e.target.style.backgroundColor = "";
																													e.target.style.color = "";
																												}}
																											>
																												{subcategoriesResult?.name}
																											</a>
																										) : null}

																									</React.Fragment>
																								)
																							)}
																						</DropdownButton>
																						) : (
																							<span className="menu-item-title">
																								{CategoryListResult?.name}
																							</span>
																						)}
																					</div>
																				</Link>
																			</li>
																		)
																	)}

																	{CategoryList?.slice(1)?.map(
																		(CategoryListResult, index) =>
																			index != 0 && CategoryListResult?.is_footer_category == false &&
																			(!CategoryListResult && !CategoryListResult?.instituteId == null) && (

																				<li
																					key={CategoryListResult?._id}
																					className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children level-1`}
																				>
																					<Link to={CategoryListResult?._id}>
																					
																						<div
																							onClick={() => {
																								localStorage.removeItem(
																									"ActiveTabs"
																								);
																							}}
																							className={`${CategoryListResult?._id ===
																								activeSubcategory
																								? "menu-item-wrap"
																								: "menu-item"
																								}`}
																						>
																							{CategoryListResult?.subcategories
																								?.length > 0 ? (
																								<DropdownButton
																									className={`Drodown_menu ${CategoryListResult?._id ===
																										activeSubcategory
																										? "menu-item-wrap"
																										: "menu-item"
																										}`}
																									id="dropdown-basic-button"
																									title={CategoryListResult?.name}
																								>
																									{CategoryListResult?.subcategories?.map(

																										(subcategoriesResult) => (

																											<React.Fragment

																												key={
																													subcategoriesResult?._id
																												}
																											>
																												{subcategoriesResult?.type ===
																													"text" ||
																													subcategoriesResult?.type ===
																													"both" ? (
																													<>
																														{subcategoriesResult
																															?.subcategories
																															?.length > 0 ? (

																															<div
																																className="submenucus"
																																onMouseEnter={() =>
																																	GetOverDataDetails(
																																		subcategoriesResult?._id
																																	)
																																}
																															>
																																<div
																																	className="menusubtitle"
																																	onMouseEnter={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"#f0f0f0";
																																		e.target.style.color =
																																			"#0056b3";
																																	}}
																																	onMouseLeave={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"";
																																		e.target.style.color =
																																			"";
																																	}}
																																>
																																	<span>
																																		{subcategoriesResult?.slug !=
																																			"pharmacy-courses-ug-cdip" &&
																																			subcategoriesResult?.slug !=
																																			"professional-ug-and-pg-cdips" &&
																																			subcategoriesResult?.slug !=
																																			"law-courses-ug-cdil" &&
																																			subcategoriesResult?.slug !=
																																			"law" && (
																																				<span onMouseEnter={() => localStorage.removeItem("instituteIcon")}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"pharmacy-courses-ug-cdip" && (
																																				<span
																																					onMouseEnter={
																																						HandleSecondInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"professional-ug-and-pg-cdips" && (
																																				<span
																																					onMouseEnter={
																																						HandleThirdInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law-courses-ug-cdil" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		<IoIosArrowForward />
																																	</span>
																																</div>
																																{showSideDropdown &&
																																	activeSubcategory ===
																																	subcategoriesResult?._id && (
																																		<div className="side-dropdown-content sidmenussp">
																																			{sideDropdownData?.map((sideDropdownDataResult) => (
																																				<div
																																					key={
																																						sideDropdownDataResult?._id
																																					}
																																					onClick={() =>
																																						window.location.reload()
																																					}
																																				>
																																					{sideDropdownDataResult?.type ===
																																						"text" ||
																																						sideDropdownDataResult?.type ===
																																						"both" ? (
																																						<Link
																																							to={`/Overview/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																							// to={`/Overview/${subcategoriesResult?.slug}`}

																																							onClick={() =>
																																								localStorage.setItem(
																																									"selectedSubCategoryName",
																																									sideDropdownDataResult?.name
																																								)
																																							}
																																						>
																																							<div
																																								className="menusubtitle"
																																								onMouseEnter={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"#f0f0f0";
																																									e.target.style.color =
																																										"#0056b3";
																																								}}
																																								onMouseLeave={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"";
																																									e.target.style.color =
																																										"";
																																								}}
																																							>
																																								{
																																									sideDropdownDataResult?.name
																																								}
																																							</div>
																																						</Link>
																																					) : (
																																						<div
																																							onMouseEnter={(
																																								e
																																							) => {
																																								e.target.style.backgroundColor =
																																									"#f0f0f0";
																																								e.target.style.color =
																																									"#0056b3";
																																							}}
																																							onMouseLeave={(
																																								e
																																							) => {
																																								e.target.style.backgroundColor =
																																									"";
																																								e.target.style.color =
																																									"";
																																							}}
																																						>
																																							<Link
																																								// to={`/Pdf_Open/${sideDropdownDataResult?._id}`}
																																								to={`/Pdf_Open/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																								onClick={() => {
																																									localStorage.setItem(
																																										"selectedSubCategoryName",
																																										sideDropdownDataResult?.name
																																									);
																																								}}
																																							>
																																								{
																																									sideDropdownDataResult?.name
																																								}
																																							</Link>
																																						</div>
																																					)}
																																				</div>
																																			)
																																			)}
																																		</div>
																																	)}
																															</div>
																														) : (
																															<div
																																onClick={() =>
																																	window.location.reload()
																																}
																															>
																																<Link
																																	to={`/Overview/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																																	// to={`/Overview/${subcategoriesResult?.slug}`}

																																>

																																	<div
																																		onMouseEnter={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"#f0f0f0";
																																			e.target.style.color =
																																				"#0056b3";
																																		}}
																																		onMouseLeave={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"";
																																			e.target.style.color =
																																				"";
																																		}}
																																		onClick={() => {
																																			localStorage.setItem(
																																				"selectedSubCategoryName",
																																				subcategoriesResult?.name
																																			);
																																			setShowDropdown(
																																				!showDropdown
																																			);
																																		}}
																																	>
																																		{subcategoriesResult?.slug !=
																																			"bba-ft" && (
																																				<div onClick={() => { localStorage.removeItem("instituteIcon") }}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</div>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"bba-ft" && (
																																				<span onClick={HandleSecondInstituteIcons}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																
																																	</div>
																																</Link>
																															</div>
																														)}
																													</>
																												) : subcategoriesResult?.type ===
																													"pdf" ? (
																													<div
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor =
																																"#f0f0f0";
																															e.target.style.color =
																																"#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor =
																																"";
																															e.target.style.color =
																																"";
																														}}
																													>
																														<Link
																															// to={`/Pdf_Open/${subcategoriesResult?._id}`}
																															to={`/Pdf_Open/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																														>
																															{
																																subcategoriesResult?.name}
																														</Link>
																													</div>
																												) : subcategoriesResult?.type === "link" ? (

																													<a
																														href={subcategoriesResult?.url}
																														target="_blank"
																														rel="noopener noreferrer"
																														onClick={() => {
																															// This will ensure the URL is hit on click
																															window.open(subcategoriesResult?.url, "_blank");
																														}}
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor = "#f0f0f0";
																															e.target.style.color = "#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor = "";
																															e.target.style.color = "";
																														}}
																													>
																														{subcategoriesResult?.name}
																													</a>



																												) : null}
																											</React.Fragment>
																										)
																									)}
																								</DropdownButton>
																							) : (
																								<span className="menu-item-title">
																									{CategoryListResult?.name}
																								</span>
																							)}
																						</div>
																					</Link>
																				</li>
																			)
																	)}

																	{CategoryList?.slice(1)?.map(
																		(CategoryListResult, index) =>
																			index != 0 && CategoryListResult?.is_footer_category === false &&
																			(CategoryListResult.instituteId ==
																				localStorage.getItem("College_id") ||
																				(!CategoryListResult.instituteId &&
																					CategoryListResult?.slug !=
																					"academics" &&
																					CategoryListResult?.slug != "events" &&
																					CategoryListResult?.slug !=
																					"student-corners" &&
																					CategoryListResult?.slug !=
																					"naac")) && (
																				<li
																					key={CategoryListResult?._id}
																					className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children level-1`}
																				>
																					<Link to="#">
																						<div
																							onClick={() => {
																								localStorage.removeItem(
																									"ActiveTabs"
																								);
																							}}
																							className={`${CategoryListResult?._id ===
																								activeSubcategory
																								? "menu-item-wrap"
																								: "menu-item"
																								}`}
																						>
																							{CategoryListResult?.subcategories
																								?.length > 0 ? (
																								<DropdownButton
																									className={`Drodown_menu ${CategoryListResult?._id ===
																										activeSubcategory
																										? "menu-item-wrap"
																										: "menu-item"
																										}`}
																									id="dropdown-basic-button"
																									title={CategoryListResult?.name}
																								>
																									{CategoryListResult?.subcategories?.map(
																										(subcategoriesResult) => (
																											<React.Fragment
																												key={
																													subcategoriesResult?._id
																												}
																											>
																												{subcategoriesResult?.type ===
																													"text" ||
																													subcategoriesResult?.type ===
																													"both" ? (
																													<>
																														{subcategoriesResult
																															?.subcategories
																															?.length > 0 ? (
																															<div
																																className="submenucus"
																																onMouseEnter={() =>
																																	GetOverDataDetails(
																																		subcategoriesResult?._id
																																	)
																																}
																															>
																																<div
																																	className="menusubtitle"
																																	onMouseEnter={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"#f0f0f0";
																																		e.target.style.color =
																																			"#0056b3";
																																	}}
																																	onMouseLeave={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"";
																																		e.target.style.color =
																																			"";
																																	}}
																																>
																																	<span>
																																		{subcategoriesResult?.slug !=
																																			"pharmacy-courses-ug-cdip" &&
																																			subcategoriesResult?.slug !=
																																			"professional-ug-and-pg-cdips" &&
																																			subcategoriesResult?.slug !=
																																			"law-courses-ug-cdil" &&
																																			subcategoriesResult?.slug !=
																																			"law" && (
																																				<span onMouseEnter={() => localStorage.removeItem("instituteIcon")}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"pharmacy-courses-ug-cdip" && (
																																				<span
																																					onMouseEnter={
																																						HandleSecondInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"professional-ug-and-pg-cdips" && (
																																				<span
																																					onMouseEnter={
																																						HandleThirdInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law-courses-ug-cdil" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		<IoIosArrowForward />
																																	</span>
																																</div>
																																{showSideDropdown &&
																																	activeSubcategory ===
																																	subcategoriesResult?._id && (
																																		<div className="side-dropdown-content sidmenussp">
																																			{sideDropdownData?.map(
																																				(
																																					sideDropdownDataResult
																																				) => (
																																					<div
																																						key={
																																							sideDropdownDataResult?._id
																																						}
																																						onClick={() =>
																																							window.location.reload()
																																						}
																																					>
																																						{sideDropdownDataResult?.type ===
																																							"text" ||
																																							sideDropdownDataResult?.type ===
																																							"both" ? (
																																							<Link
																																								to={`/Overview/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																								// to={`/Overview/${subcategoriesResult?.slug}`}

																																								onClick={() =>
																																									localStorage.setItem(
																																										"selectedSubCategoryName",
																																										sideDropdownDataResult?.name
																																									)
																																								}
																																							>
																																								<div
																																									className="menusubtitle"
																																									onMouseEnter={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"#f0f0f0";
																																										e.target.style.color =
																																											"#0056b3";
																																									}}
																																									onMouseLeave={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"";
																																										e.target.style.color =
																																											"";
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</div>
																																							</Link>
																																						) : (
																																							<div
																																								onMouseEnter={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"#f0f0f0";
																																									e.target.style.color =
																																										"#0056b3";
																																								}}
																																								onMouseLeave={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"";
																																									e.target.style.color =
																																										"";
																																								}}
																																							>
																																								<Link
																																									// to={`/Pdf_Open/${sideDropdownDataResult?._id}`}
																																									to={`/Pdf_Open/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																									onClick={() => {
																																										localStorage.setItem(
																																											"selectedSubCategoryName",
																																											sideDropdownDataResult?.name
																																										);
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</Link>
																																							</div>
																																						)}
																																					</div>
																																				)
																																			)}
																																		</div>
																																	)}
																															</div>
																														) : (
																															<div
																																onClick={() =>
																																	window.location.reload()
																																}
																															>
																																<Link
																																	to={`/Overview/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																																	// to={`/Overview/${subcategoriesResult?.slug}`}

																																>

																																	<div
																																		onMouseEnter={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"#f0f0f0";
																																			e.target.style.color =
																																				"#0056b3";
																																		}}
																																		onMouseLeave={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"";
																																			e.target.style.color =
																																				"";
																																		}}
																																		onClick={() =>
																																			localStorage.setItem(
																																				"selectedSubCategoryName",
																																				subcategoriesResult?.name
																																			)
																																		}
																																	>
																																		{subcategoriesResult?.slug !=
																																			"bba-ft" && (
																																				<div onClick={() => { localStorage.removeItem("instituteIcon") }}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</div>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"bba-ft" && (
																																				<span onClick={HandleSecondInstituteIcons}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																		
																																	</div>
																																</Link>
																															</div>
																														)}
																													</>
																												) : subcategoriesResult?.type ===
																													"pdf" ? (
																													<div
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor =
																																"#f0f0f0";
																															e.target.style.color =
																																"#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor =
																																"";
																															e.target.style.color =
																																"";
																														}}
																													>
																														<Link
																															// to={`/Pdf_Open/${subcategoriesResult?._id}`}
																															to={`/Pdf_Open/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																														>
																															{
																																subcategoriesResult?.name
																															}
																														</Link>
																													</div>
																												) : subcategoriesResult?.type === "link" ? (

																													<a
																														href={subcategoriesResult?.url}
																														target="_blank"
																														rel="noopener noreferrer"
																														onClick={() => {
																														
																															window.open(subcategoriesResult?.url, "_blank");
																														}}
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor = "#f0f0f0";
																															e.target.style.color = "#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor = "";
																															e.target.style.color = "";
																														}}
																													>
																														{subcategoriesResult?.name}
																													</a>


																												) : null}
																											</React.Fragment>
																										)
																									)}
																								</DropdownButton>
																							) : (
																								<span className="menu-item-title">
																									{CategoryListResult?.name}
																								</span>
																							)}
																						</div>
																					</Link>
																				</li>
																			)
																	)}
																	
																	<li
																		className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor
																 current-menu-parent menu-item-has-children menu-item-53 level-1"
																	>
																		<div className="menu-item" style={{ padding: "3px" }}>
																			<DropdownButton
																				className="Drodown_menu"
																				id="dropdown-basic-button"
																				title="Career"
																			>
																				<div className="p-1">
																					<Link to="/Advertisement" onClick={() => localStorage.removeItem("instituteIcon")} style={{ padding: "5px" }}>Advertisement</Link>
																					<Link to="/Apply_for_job" onClick={() => localStorage.removeItem("instituteIcon")} style={{ padding: "5px" }}>Apply Here</Link>
																					<Link to="/Login" onClick={() => localStorage.removeItem("instituteIcon")} style={{ padding: "5px" }}>Hr Login</Link>
																					{/* <a style={{ padding: "5px" }} href="https://cdgi.accsofterp.com/AccSoft_CDGI/StudentLogin.aspx" target="_blank" rel="noopener noreferrer">
																					Pay Fee Online
																				</a>
																				<a style={{ padding: "5px" }} href="https://hackwavee.vercel.app" target="_blank" rel="noopener noreferrer">
																					HackWave-2k24
																				</a>
																				<a style={{ padding: "5px" }} href="https://cdgi.edu.in/citronics" target="_blank" rel="noopener noreferrer">
																					Citronics-2k24
																				</a>
																				<a style={{ padding: "5px" }} href="https://cdgi.accsofterp.com/AccSoft_CDGI" target="_blank" rel="noopener noreferrer">
																					AccSoft ERP Login
																				</a>
																				<a style={{ padding: "5px" }} href="http://52.37.150.193/ecampus" target="_blank" rel="noopener noreferrer">
																					eCampus Login
																				</a> */}
																				</div>
																			</DropdownButton>
																		</div>


																	</li>
																	<li
																		className="menu-item menu-item-type-custom menu-item-object-custom 
																menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children
																 menu-item-53 level-1"
																	>
																		<Link to="/Contact_us" onClick={() => localStorage.removeItem("instituteIcon")}>
																			<div className="menu-item">Contact Us</div>
																		</Link>
																	</li>
																	{CategoryList?.slice(1)?.map(
																		(CategoryListResult, index) =>
																			index == 0 && (
																				<li
																					key={CategoryListResult?._id}
																					className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children level-1`}
																				>
																					<Link to="#">
																						<div
																							onClick={() => {
																								localStorage.removeItem(
																									"ActiveTabs"
																								);
																							}}
																							className={`${CategoryListResult?._id ===
																								activeSubcategory
																								? "menu-item-wrap"
																								: "menu-item"
																								}`}
																						>
																							{CategoryListResult?.subcategories
																								?.length > 0 ? (
																								<DropdownButton
																									className={`Drodown_menu ${CategoryListResult?._id ===
																										activeSubcategory
																										? "menu-item-wrap"
																										: "menu-item"
																										}`}
																									id="dropdown-basic-button"
																									title={CategoryListResult?.name}
																								>
																									{CategoryListResult?.subcategories?.map(
																										(subcategoriesResult) => (
																											<React.Fragment
																												key={
																													subcategoriesResult?._id
																												}
																											>
																												{subcategoriesResult?.type ===
																													"text" ||
																													subcategoriesResult?.type ===
																													"both" ? (
																													<>
																														{subcategoriesResult
																															?.subcategories
																															?.length > 0 ? (
																															<div
																																className="submenucus"
																																onMouseEnter={() =>
																																	GetOverDataDetails(
																																		subcategoriesResult?._id
																																	)
																																}
																															>
																																<div
																																	className="menusubtitle"
																																	onMouseEnter={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"#f0f0f0";
																																		e.target.style.color =
																																			"#0056b3";
																																	}}
																																	onMouseLeave={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"";
																																		e.target.style.color =
																																			"";
																																	}}
																																>
																																	<span>
																																		{subcategoriesResult?.slug !=
																																			"pharmacy-courses-ug-cdip" &&
																																			subcategoriesResult?.slug !=
																																			"professional-ug-and-pg-cdips" &&
																																			subcategoriesResult?.slug !=
																																			"law-courses-ug-cdil" &&
																																			subcategoriesResult?.slug !=
																																			"law" && (
																																				<span onMouseEnter={() => localStorage.removeItem("instituteIcon")}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"pharmacy-courses-ug-cdip" && (
																																				<span
																																					onMouseEnter={
																																						HandleSecondInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"professional-ug-and-pg-cdips" && (
																																				<span
																																					onMouseEnter={
																																						HandleThirdInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law-courses-ug-cdil" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		<IoIosArrowForward />
																																	</span>
																																</div>
																																{showSideDropdown &&
																																	activeSubcategory ===
																																	subcategoriesResult?._id && (
																																		<div className="side-dropdown-content sidmenussp">
																																			{sideDropdownData?.map(
																																				(
																																					sideDropdownDataResult
																																				) => (
																																					<div
																																						key={
																																							sideDropdownDataResult?._id
																																						}
																																						onClick={() =>
																																							window.location.reload()
																																						}
																																					>
																																						{sideDropdownDataResult?.type ===
																																							"text" ||
																																							sideDropdownDataResult?.type ===
																																							"both" ? (
																																							<Link
																																								to={`/Overview/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																								// to={`/Overview/${subcategoriesResult?.slug}`}

																																								onClick={() =>
																																									localStorage.setItem(
																																										"selectedSubCategoryName",
																																										sideDropdownDataResult?.name
																																									)
																																								}
																																							>
																																								<div
																																									className="menusubtitle"
																																									onMouseEnter={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"#f0f0f0";
																																										e.target.style.color =
																																											"#0056b3";
																																									}}
																																									onMouseLeave={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"";
																																										e.target.style.color =
																																											"";
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</div>
																																							</Link>
																																						) : (
																																							<div
																																								onMouseEnter={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"#f0f0f0";
																																									e.target.style.color =
																																										"#0056b3";
																																								}}
																																								onMouseLeave={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"";
																																									e.target.style.color =
																																										"";
																																								}}
																																							>
																																								<Link
																																									// to={`/Pdf_Open/${sideDropdownDataResult?._id}`}
																																									to={`/Pdf_Open/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																									onClick={() => {
																																										localStorage.setItem(
																																											"selectedSubCategoryName",
																																											sideDropdownDataResult?.name
																																										);
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</Link>
																																							</div>
																																						)}
																																					</div>
																																				)
																																			)}
																																		</div>
																																	)}
																															</div>
																														) : (
																															<div
																																onClick={() =>
																																	window.location.reload()
																																}
																															>
																																<Link
																																	to={`/Overview/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																																	// to={`/Overview/${subcategoriesResult?.slug}`}

																																>
																																	<div
																																		onMouseEnter={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"#f0f0f0";
																																			e.target.style.color =
																																				"#0056b3";
																																		}}
																																		onMouseLeave={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"";
																																			e.target.style.color =
																																				"";
																																		}}
																																		onClick={() =>
																																			localStorage.setItem(
																																				"selectedSubCategoryName",
																																				subcategoriesResult?.name
																																			)
																																		}
																																	>
																																		{subcategoriesResult?.slug !=
																																			"bba-ft" && (
																																				<div onClick={() => { localStorage.removeItem("instituteIcon") }}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</div>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"bba-ft" && (
																																				<span onClick={HandleSecondInstituteIcons}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{/* {
                                                                    subcategoriesResult?.name
                                                                  }
                                                                  44 */}
																																	</div>
																																</Link>
																															</div>
																														)}
																													</>
																												) : subcategoriesResult?.type ===
																													"pdf" ? (
																													<div
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor =
																																"#f0f0f0";
																															e.target.style.color =
																																"#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor =
																																"";
																															e.target.style.color =
																																"";
																														}}
																													>
																														<Link
																															// to={`/Pdf_Open/${subcategoriesResult?._id}`}
																															to={`/Pdf_Open/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																														>
																															{
																																subcategoriesResult?.name
																															}
																														</Link>
																													</div>
																												) : subcategoriesResult?.type === "link" ? (

																													<a
																														href={subcategoriesResult?.url}
																														target="_blank"
																														rel="noopener noreferrer"
																														onClick={() => {
																															// This will ensure the URL is hit on click
																															window.open(subcategoriesResult?.url, "_blank");
																														}}
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor = "#f0f0f0";
																															e.target.style.color = "#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor = "";
																															e.target.style.color = "";
																														}}
																													>
																														{subcategoriesResult?.name}
																													</a>


																												) : null}
																											</React.Fragment>
																										)
																									)}
																								</DropdownButton>
																							) : (
																								<span className="menu-item-title">
																									{CategoryListResult?.name}
																								</span>
																							)}
																						</div>
																					</Link>
																				</li>
																			)
																	)}

																	<>
																		<li
																			className="menu-item menu-item-type-custom menu-item-object-custom 
																menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children
																 menu-item-53 level-1">

																			<Link to="/Feedback" onClick={() => localStorage.removeItem("instituteIcon")}>
																				<div className="menu-item">Feedback</div>
																			</Link>
																		</li>
																	</>
																</ul>
																<div></div>
															</nav>
														</div>
													</div>
												</div>
											</div>
										</div>

										<FaBars
											onClick={handleShow}
											className="mobile_bar_icons"
											style={{ color: "white" }}
											size={30}
										/>
										<Offcanvas show={show} onHide={handleClose} placement="end">
											<Offcanvas.Header closeButton></Offcanvas.Header>
											<Offcanvas.Body
												style={{ background: "black", color: "white" }}
											>
												<ul
													id="menu-primary"
													className="menu__container sm sm-simple"
													style={{ listStyle: "none" }}
												>
													{CategoryList?.map((CategoryListResult, index) => (
														<li
															key={CategoryListResult?._id}
															className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home 
													current-menu-ancestor current-menu-parent menu-item-has-children level-1`}
														>
															<Link>
																<div
																	className={`${CategoryListResult?._id === activeSubcategory
																		? "menu-item-wrap"
																		: "menu-item"
																		}`}
																>
																	{CategoryListResult?.subcategories?.length >
																		0 ? (
																		<DropdownButton
																			className={`Drodown_menu ${CategoryListResult?._id ===
																				activeSubcategory
																				? "menu-item-wrap"
																				: "menu-item"
																				}`}
																			id="dropdown-basic-button"
																			title={CategoryListResult?.name}
																		>
																			{CategoryListResult?.subcategories?.map(
																				(subcategoriesResult) => (
																					<React.Fragment
																												key={
																													subcategoriesResult?._id
																												}
																											>
																												{subcategoriesResult?.type ===
																													"text" ||
																													subcategoriesResult?.type ===
																													"both" ? (
																													<>
																														{subcategoriesResult
																															?.subcategories
																															?.length > 0 ? (
																															<div
																																className="submenucus"
																																onMouseEnter={() =>
																																	GetOverDataDetails(
																																		subcategoriesResult?._id
																																	)
																																}
																															>
																																<div
																																	className="menusubtitle"
																																	onMouseEnter={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"#f0f0f0";
																																		e.target.style.color =
																																			"#0056b3";
																																	}}
																																	onMouseLeave={(
																																		e
																																	) => {
																																		e.target.style.backgroundColor =
																																			"";
																																		e.target.style.color =
																																			"";
																																	}}
																																>
																																	<span>
																																		{subcategoriesResult?.slug !=
																																			"pharmacy-courses-ug-cdip" &&
																																			subcategoriesResult?.slug !=
																																			"professional-ug-and-pg-cdips" &&
																																			subcategoriesResult?.slug !=
																																			"law-courses-ug-cdil" &&
																																			subcategoriesResult?.slug !=
																																			"law" && (
																																				<span onMouseEnter={() => localStorage.removeItem("instituteIcon")}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"pharmacy-courses-ug-cdip" && (
																																				<span
																																					onMouseEnter={
																																						HandleSecondInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"professional-ug-and-pg-cdips" && (
																																				<span
																																					onMouseEnter={
																																						HandleThirdInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law-courses-ug-cdil" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"law" && (
																																				<span
																																					onMouseEnter={
																																						HandleFourthInstituteIcons
																																					}
																																				>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		<IoIosArrowForward />
																																	</span>
																																</div>
																																{showSideDropdown &&
																																	activeSubcategory ===
																																	subcategoriesResult?._id && (
																																		<div className="side-dropdown-content sidmenussp">
																																			{sideDropdownData?.map(
																																				(
																																					sideDropdownDataResult
																																				) => (
																																					<div
																																						key={
																																							sideDropdownDataResult?._id
																																						}
																																						onClick={() =>
																																							window.location.reload()
																																						}
																																					>
																																						{sideDropdownDataResult?.type ===
																																							"text" ||
																																							sideDropdownDataResult?.type ===
																																							"both" ? (
																																							<Link
																																								to={`/Overview/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																								// to={`/Overview/${subcategoriesResult?.slug}`}

																																								onClick={() =>
																																									localStorage.setItem(
																																										"selectedSubCategoryName",
																																										sideDropdownDataResult?.name
																																									)
																																								}
																																							>
																																								<div
																																									className="menusubtitle"
																																									onMouseEnter={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"#f0f0f0";
																																										e.target.style.color =
																																											"#0056b3";
																																									}}
																																									onMouseLeave={(
																																										e
																																									) => {
																																										e.target.style.backgroundColor =
																																											"";
																																										e.target.style.color =
																																											"";
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</div>
																																							</Link>
																																						) : (
																																							<div
																																								onMouseEnter={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"#f0f0f0";
																																									e.target.style.color =
																																										"#0056b3";
																																								}}
																																								onMouseLeave={(
																																									e
																																								) => {
																																									e.target.style.backgroundColor =
																																										"";
																																									e.target.style.color =
																																										"";
																																								}}
																																							>
																																								<Link
																																									// to={`/Pdf_Open/${sideDropdownDataResult?._id}`}
																																									to={`/Pdf_Open/${sideDropdownDataResult?._id}/${sideDropdownDataResult?.slug}`}
																																									onClick={() => {
																																										localStorage.setItem(
																																											"selectedSubCategoryName",
																																											sideDropdownDataResult?.name
																																										);
																																									}}
																																								>
																																									{
																																										sideDropdownDataResult?.name
																																									}
																																								</Link>
																																							</div>
																																						)}
																																					</div>
																																				)
																																			)}
																																		</div>
																																	)}
																															</div>
																														) : (
																															<div
																																onClick={() =>
																																	window.location.reload()
																																}
																															>
																																<Link
																																	// to={`/Overview/${subcategoriesResult?._id}`}
																																	// to={`/Overview/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																																
																																>
																																	<div
																																		onMouseEnter={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"#f0f0f0";
																																			e.target.style.color =
																																				"#0056b3";
																																		}}
																																		onMouseLeave={(
																																			e
																																		) => {
																																			e.target.style.backgroundColor =
																																				"";
																																			e.target.style.color =
																																				"";
																																		}}
																																		onClick={() =>
																																			localStorage.setItem(
																																				"selectedSubCategoryName",
																																				subcategoriesResult?.name
																																			)
																																		}
																																	>
																																		{subcategoriesResult?.slug !=
																																			"bba-ft" && (
																																				<div onClick={() => { localStorage.removeItem("instituteIcon") }}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</div>
																																			)}
																																		{subcategoriesResult?.slug ==
																																			"bba-ft" && (
																																				<span onClick={HandleSecondInstituteIcons}>
																																					{
																																						subcategoriesResult?.name
																																					}
																																				</span>
																																			)}
																																		{/* {
                                                                    subcategoriesResult?.name
                                                                  }
                                                                  44 */}
																																	</div>
																																</Link>
																															</div>
																														)}
																													</>
																												) : subcategoriesResult?.type ===
																													"pdf" ? (
																													<div
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor =
																																"#f0f0f0";
																															e.target.style.color =
																																"#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor =
																																"";
																															e.target.style.color =
																																"";
																														}}
																													>
																														<Link
																															// to={`/Pdf_Open/${subcategoriesResult?._id}`}
																															to={`/Pdf_Open/${subcategoriesResult?._id}/${subcategoriesResult?.slug}`}
																														>
																															{
																																subcategoriesResult?.name
																															}
																														</Link>
																													</div>
																												) : subcategoriesResult?.type === "link" ? (

																													<a
																														href={subcategoriesResult?.url}
																														target="_blank"
																														rel="noopener noreferrer"
																														onClick={() => {
																															// This will ensure the URL is hit on click
																															window.open(subcategoriesResult?.url, "_blank");
																														}}
																														onMouseEnter={(e) => {
																															e.target.style.backgroundColor = "#f0f0f0";
																															e.target.style.color = "#0056b3";
																														}}
																														onMouseLeave={(e) => {
																															e.target.style.backgroundColor = "";
																															e.target.style.color = "";
																														}}
																													>
																														{subcategoriesResult?.name}
																													</a>


																												) : null}
																											</React.Fragment>
																				)
																			)}
																		</DropdownButton>
																	) : (
																		<span
																			className="menu-item-title"
																			style={{ color: "white" }}
																		>
																			{CategoryListResult?.name}
																		</span>
																	)}
																</div>
															</Link>
														</li>
													))}
													<>
														<li
															className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor
																 current-menu-parent menu-item-has-children menu-item-53 level-1"
														>
															<div className="menu-item" style={{ padding: "3px" }}>
																<DropdownButton
																	className="Drodown_menu"
																	id="dropdown-basic-button"
																	title="Career"
																>
																	<div className="p-1">
																		<Link to="/Advertisement" style={{ padding: "5px" }}>advertisement</Link>
																		<Link to="/Apply_for_job" style={{ padding: "5px" }}>Apply Here</Link>
																		<Link to="/Login" style={{ padding: "5px" }}>Hr Login</Link>
																		{/* <a style={{ padding: "5px" }} href="https://cdgi.accsofterp.com/AccSoft_CDGI/StudentLogin.aspx" target="_blank" rel="noopener noreferrer">
																		Pay Fee Online
																	</a>
																	<a style={{ padding: "5px" }} href="https://hackwavee.vercel.app" target="_blank" rel="noopener noreferrer">
																		HackWave-2k24
																	</a>
																	<a style={{ padding: "5px" }} href="https://cdgi.edu.in/citronics" target="_blank" rel="noopener noreferrer">
																		Citronics-2k24
																	</a>
																	<a style={{ padding: "5px" }} href="https://cdgi.accsofterp.com/AccSoft_CDGI" target="_blank" rel="noopener noreferrer">
																		AccSoft ERP Login
																	</a>
																	<a style={{ padding: "5px" }} href="http://52.37.150.193/ecampus" target="_blank" rel="noopener noreferrer">
																		eCampus Login
																	</a> */}
																	</div>
																</DropdownButton>
															</div>



														</li>

														<li
															className="menu-item menu-item-type-custom menu-item-object-custom 
																menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children
																 menu-item-53 level-1"
														>
															<Link to="/Contact_us" style={{ color: "white" }}>
																<div className="menu-item">Contact Us</div>
															</Link>
														</li>

														<li
															className="menu-item menu-item-type-custom menu-item-object-custom 
																menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children
																 menu-item-53 level-1"
														>
															<Link to="/Feedback" style={{ color: "white" }}>
																<div className="menu-item">Feedback</div>
															</Link>
														</li>
													</>
												</ul>
											</Offcanvas.Body>
										</Offcanvas>
									</div>
								</div>
							</div>

						</header>
					</div>
					<div className="page-header1"></div>
				</>

			)}

			
		</div>
	);
};

export default Header;
