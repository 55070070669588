// import React, { useEffect, useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import Modal from 'react-bootstrap/Modal';
// import loge from '../../assets/images/logo/logo-right.png'
// import { CreateStudentEnquirey } from '../../api/Global';
// import { GetSettingsAdd} from '../../api/Global';

// const Navbar = () => {
//     const navigate = useNavigate();
//     const [name, setName] = useState("");
//     const [mobile, setMobile] = useState("");
//     const [email, setEmail] = useState("");
//     const [city, setCity] = useState("");
//     const [course, setCourse] = useState("");
//     const [mathAnswer, setMathAnswer] = useState("");
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [data, setData]=useState();
//     const [error, setError]=useState();

//      useEffect(() => {
//           const fetchData = async () => {
//             try {
//               const response = await GetSettingsAdd();
//               if (Array.isArray(response.data.data)) {
//                 setData(response.data.data); 
//                 // setFilteredData(response.data.data); 
//               } else {
//                 setError("Error fetching data");
//               }
//             } catch (error) {
//               console.error("Error fetching events:", error);
//               setError("Error fetching events");
//             }
//           };

//           fetchData();
//         }, []);



//     const  HandleSubmit = async () => {
//         const data = {
//             name,
//             email,
//             mobile,
//             email,
//             city,
//             course,
//             mathAnswer
//         }
//         try {
//             const response = await CreateStudentEnquirey(data)
//             console.log("response", response)
//             if(response?.data?.status == true){
//                 alert("Enquiry successfully Submitted")
//                 setName("")
//                 setEmail("")
//                 setMobile("")
//                 setCity("")
//                 setCourse("")
//                 setMathAnswer("")
//                 setShow(false)
//             }
//         }
//         catch (error) {
//             console.log("error", error)
//         }
//     }

//     const handleClick = () => {
//         navigate('/Fees_Structure_Pdf');
//     };
//     return (
//         <div>
//             <div className="page-top-bar top-bar-02">
//                 <div className="container">
//                     <div className="row row-eq-height">
//                         <div className="col-md-6 top-bar-left">
//                             <div className="top-bar-wrap">
//                                 <div className="top-bar-social-network">
//                                     <div className="inner">
//                                         <Link className=" social-link" to="#" target="_blank" rel="nofollow">
//                                             <i className="fas fa-phone-alt">
//                                                 {/* <FaPhoneAlt /> */}
//                                             </i> +91 7314243600
//                                         </Link>
//                                         <a className=" social-link" href="mailto:info@cdgi.edu.in?subject=Application%20for%20Job&body=I%20am%20interested%20in%20applying%20for%20a%20job." target="_blank" rel="nofollow">
//                                             <i className="far fa-envelope">
//                                                 {/* <FaRegEnvelope /> */}
//                                             </i> info@cdgi.edu.in
//                                         </a>



//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-6 top-bar-right">
//                             <div className="top-bar-wrap">   <a href='https://www.agarwalcoal.com/#home' target='_blank'>
//                             <img id='logo-right' src={loge}alt=''/></a>
//                                 <div id="page-navigation-secondary" className="navigation-secondary">
//                                     <nav className="menu menu--secondary">

//                                         <div className="header-buttons">

//                                             {/* <a
//                                                 className="tm-button style-flat tm-button-xxs header-button"
//                                                 href="#"
//                                             >
//                                                 <div className="button-content-wrapper custom-btn btn-11" onClick={handleShow}>
//                                                     <span className="button-text dot">Apply</span>
//                                                 </div>
//                                             </a> */}
//                                             <button class="zoom-in-zoom-out" onClick={handleShow}>Apply Now<div class="dot"></div></button>

//                                         </div>
//                                     </nav>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* <Modal size='lg' show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Admission Enquiry Form</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div class="bg-white">
//                         <div class="modlaPopup">
//                             <div class="modal-title">
//                                 Admissions 2024-25 <a href="#" target="_blank">(Visit CDGI Virtual Tour)</a>
//                                 <table class="table table-striped">
//                                     <thead>
//                                         <tr><th>Courses</th>
//                                             <th>Specialization</th>
//                                             <th>Helpline</th>
//                                         </tr></thead>
//                                     <tbody><tr>
//                                         <td>Engineering/MCA<sup></sup>-CDGI</td>
//                                         <td>CS, IT, AI &amp; DS, CSIT, EC, ME, Civil /MCA<sup></sup></td>
//                                         <td>9981621000, 9109998199</td>
//                                     </tr>
//                                         <tr>
//                                             <td>Management-CDGI</td>
//                                             <td>MBA(FT,MM,FA)</td>
//                                             <td>9109998198</td >
//                                         </tr >
//                                         <tr>
//                                             <td>Pharmacy-CDIP</td>
//                                             <td>B.Pharm, D.Pharm, M.Pharm (Industrial Pharmacy,Pharmacology, Pharmaceutics)<sup></sup></td >
//                                             <td>6262050008, 9826720320</td >
//                                         </tr >
//                                         <tr>
//                                             <td>Professional-CDIPS</td>
//                                             <td>BBA, BCA, B.Com</td >
//                                             <td>6262050006</td >
//                                         </tr >
//                                         <tr>
//                                             <td>Law-CDIL</td>
//                                             <td>LLB (Hons.), BA LLB (Hons.), BBA LLB (Hons.)</td >
//                                             <td>6262050007</td >
//                                         </tr >
//                                         <tr>
//                                             <td colspan="3"><a href="#" onClick={handleClick} target="_blank">
//                                                 Fees Structure &amp; Institute Merit Scholarship</a></td>
//                                         </tr>
//                                     </tbody ></table >
//                             </div >
//                             <div class="elementor-widget-container">
//                                 <div class="tm-modern-heading">
//                                     <div class="heading-primary-wrap">
//                                         <h2 class="heading-primary elementor-heading-title">Admission Enquiry Form
//                                         </h2>
//                                     </div>
//                                     <div class="heading-divider"></div>
//                                     <div class="heading-description-wrap">
//                                         <div class="heading-description">
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div class="elementor-widget-container">
//                                 <div class="unicamp-contact-form-7">
//                                     <div class="wpcf7">
//                                         <form action="#" method="post" class="wpcf7-form init">
//                                             <div class="form-group">
//                                                 <lable>Your Name</lable>
//                                                 <input class="form-control" placeholder="Name"
//                                                     onChange={(e) => setName(e.target.value)}
//                                                     type="text" name="name" value={name}
//                                                 />
//                                             </div>
//                                             <div class="form-group">
//                                                 <lable>Your Email</lable>
//                                                 <input class="form-control" placeholder="Email ID"
//                                                     onChange={(e) => setEmail(e.target.value)}
//                                                     value={email} type="email" name="email" />
//                                             </div>
//                                             <div class="form-group">
//                                                 <label>Mobile Number</label>
//                                                 <input class="form-control" placeholder="Phone"
//                                                     onChange={(e) => setMobile(e.target.value)}
//                                                     value={mobile} type="number" name="mobile" />
//                                             </div>
//                                             <div class="form-group">
//                                                 <label>City</label>
//                                                 <input class="form-control" placeholder="City"
//                                                     onChange={(e) => setCity(e.target.value)}
//                                                     value={city} type="text" name="city" />
//                                             </div>
//                                             <div class="form-group">
//                                                 <label>Course</label>
//                                                 <select required="" size="1" name="course"
//                                                     onChange={(e) => setCourse(e.target.value)} class="form-control">
//                                                     <option value="">Please select Any Course
//                                                     </option>
//                                                     <option value="B.Tech. Courses[CS,IT,AI &amp; DS,CSIT]">Engineering Courses [CS,IT,AI &amp; DS,CSIT]
//                                                     </option>
//                                                     <option value="B.Tech. Course [EC]">Engineering Course [Electronics &amp; Comm. Engg.]
//                                                     </option>
//                                                     <option value="B.Tech. Course [ME]">Engineering Course [Mechnical Engineering]
//                                                     </option>
//                                                     <option value="B.Tech. Course [Civil Engg.]">Engineering Course [Civil Engineering]
//                                                     </option>
//                                                     <option value="Pharmacy Courses[B.Pharm, D.Pharm]">Pharmacy Courses [B.Pharm, D.Pharm]
//                                                     </option>
//                                                     <option value="Pharmacy Course[M.Pharm]">Pharmacy Course [M.Pharm]
//                                                     </option>
//                                                     <option value="Management Courses[MBA(FT,MM,FA)]">Management Courses[MBA(FT,MM,FA)]
//                                                     </option>
//                                                     <option value="Professional Course [BBA]">Professional Courses [BBA]
//                                                     </option>
//                                                     <option value="Professional Course[BCA]">Professional Courses [BCA]
//                                                     </option>
//                                                     <option value="Professional Course[B.Com]">Professional Courses [B.Com]
//                                                     </option>
//                                                     <option value="Law Courses(LLB Hons, BA LLB Hons, BBA LLB Hons)">Law Courses [LLB Hons, BA LLB Hons, BBA LLB Hons]
//                                                     </option>
//                                                     <option value="MCA Course">MCA Course
//                                                     </option>
//                                                 </select>
//                                             </div>
//                                             <div class="form-group">
//                                                 <label>What is nine plus five?</label>
//                                                 <input class="form-control" placeholder="Answer" value={mathAnswer}
//                                                     onChange={(e) => setMathAnswer(e.target.value)}
//                                                     type="text" name="mathAnswer" />
//                                             </div>
//                                             <div class="form-group">
//                                                 <div class="form-submit">
//                                                     <button type="button" onClick={HandleSubmit}>
//                                                         Submit
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div >
//                     </div >
//                 </Modal.Body >

//             </Modal > */}
//         </div >
//     )
// }

// export default Navbar

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import loge from '../../assets/images/logo/logo-right.png';
import { CreateStudentEnquirey, GetSettingsAdd } from '../../api/Global';
import imagebaseurl from '../../config/imageurl';


const Navbar = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [course, setCourse] = useState("");
    const [mathAnswer, setMathAnswer] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetSettingsAdd();
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError("Error fetching data");
                }
            } catch (error) {
                console.error("Error fetching settings:", error);
                setError("Error fetching settings");
            }
        };

        fetchData();
    }, []);

    // const handleSubmit = async () => {
    //     const enquiryData = {
    //         name,
    //         email,
    //         mobile,
    //         city,
    //         course,
    //         mathAnswer
    //     };
    //     try {
    //         const response = await CreateStudentEnquirey(enquiryData);
    //         if (response?.data?.status) {
    //             alert("Enquiry successfully submitted");
    //             setName("");
    //             setEmail("");
    //             setMobile("");
    //             setCity("");
    //             setCourse("");
    //             setMathAnswer("");
    //             setShow(false);
    //         }
    //     } catch (error) {
    //         console.log("Error submitting enquiry:", error);
    //     }
    // };

    const HandleSubmit = async () => {
        const data = {
            name,
            email,
            mobile,
            email,
            city,
            course,
            mathAnswer
        }
        try {
            const response = await CreateStudentEnquirey(data)
            console.log("response", response)
            if (response?.data?.status == true) {
                alert("Enquiry successfully Submitted")
                setName("")
                setEmail("")
                setMobile("")
                setCity("")
                setCourse("")
                setMathAnswer("")
                setShow(false)
            }
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const handleClick = () => {
        navigate('/Fees_Structure_Pdf');
    };

    return (
        <div>
            <div className="page-top-bar top-bar-02">
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-md-6 top-bar-left">
                            <div className="top-bar-wrap">
                                <div className="top-bar-social-network">
                                    <div className="inner">
                                        <Link className="social-link" to="#">
                                            <i className="fas fa-phone-alt"></i> {data.contactNo}
                                        </Link>
                                        <a className="social-link" href={`mailto:${data.email}`} target="_blank" rel="nofollow">
                                            <i className="far fa-envelope"></i> {data.email}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 top-bar-right">
                            <div className="top-bar-wrap">
                              {/* <a href="#"> */}
                              <span>
                                {data.extraLogo && data.extraLogo.map((logo, index) => (
                                <img key={index} id='logo-right' src={`${imagebaseurl}/${logo}`} alt=""/>
                            ))}
                            </span>
                                {/* </a> */}

                             <a href='https://www.agarwalcoal.com/#home' target='_blank'>
                                <img id='logo-right' src={loge} alt="Static Logo" />
                                    </a>


                                {/* <a href='https://www.agarwalcoal.com/#home' target='_blank'>
                                {data.extraLogo && data.extraLogo.map((logo, index) => (
                                <img key={index} id='logo-right' src={`${imagebaseurl}/${logo}`} alt={`Logo `} />
                            ))}
                                </a> */}

                                {/* <a href='https://www.agarwalcoal.com/#home' target='_blank' rel="noopener noreferrer">
                                    {data.extraLogo && data.extraLogo.length > 0 ? (
                                        data.extraLogo.map((logo, index) => (
                                            <img key={index} id='logo-right' src={`${imagebaseurl}/${logo}`} alt={`Logo ${index + 1}`} />
                                        ))
                                    ) : (
                                        <img id='logo-right' src={loge} alt="Static Logo" />
                                    )}
                                </a> */}
                                <div id="page-navigation-secondary" className="navigation-secondary">
                                    <nav className="menu menu--secondary">
                                        <div className="header-buttons">
                                            <button className="zoom-in-zoom-out" onClick={handleShow}>
                                                Apply Now<div className="dot"></div>
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size='lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admission Enquiry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="bg-white">
                        <div class="modlaPopup">
                            <div class="modal-title">
                                Admissions 2024-25 <a href="#" target="_blank">(Visit CDGI Virtual Tour)</a>
                                <table class="table table-striped">
                                    <thead>
                                        <tr><th>Courses</th>
                                            <th>Specialization</th>
                                            <th>Helpline</th>
                                        </tr></thead>
                                    <tbody><tr>
                                        <td>Engineering/MCA<sup></sup>-CDGI</td>
                                        <td>CS, IT, AI &amp; DS, CSIT, EC, ME, Civil /MCA<sup></sup></td>
                                        <td>9981621000, 9109998199</td>
                                    </tr>
                                        <tr>
                                            <td>Management-CDGI</td>
                                            <td>MBA(FT,MM,FA)</td>
                                            <td>9109998198</td >
                                        </tr >
                                        <tr>
                                            <td>Pharmacy-CDIP</td>
                                            <td>B.Pharm, D.Pharm, M.Pharm (Industrial Pharmacy,Pharmacology, Pharmaceutics)<sup></sup></td >
                                            <td>6262050008, 9826720320</td >
                                        </tr >
                                        <tr>
                                            <td>Professional-CDIPS</td>
                                            <td>BBA, BCA, B.Com</td >
                                            <td>6262050006</td >
                                        </tr >
                                        <tr>
                                            <td>Law-CDIL</td>                                             <td>LLB (Hons.), BA LLB (Hons.), BBA LLB (Hons.)</td >
                                            <td>6262050007</td >
                                        </tr >
                                        <tr>
                                            <td colspan="3"><a href="#" onClick={handleClick} target="_blank">
                                                Fees Structure &amp; Institute Merit Scholarship</a></td>                                         </tr>
                                    </tbody ></table >
                            </div >
                            <div class="elementor-widget-container">
                                <div class="tm-modern-heading">
                                    <div class="heading-primary-wrap">
                                        <h2 class="heading-primary elementor-heading-title">Admission Enquiry Form
                                        </h2>
                                    </div>
                                    <div class="heading-divider"></div>
                                    <div class="heading-description-wrap">
                                        <div class="heading-description">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-widget-container">                                 <div class="unicamp-contact-form-7">
                                <div class="wpcf7">
                                    <form action="#" method="post" class="wpcf7-form init">
                                        <div class="form-group">
                                            <lable>Your Name</lable>
                                            <input class="form-control" placeholder="Name"
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" name="name" value={name}
                                            />
                                        </div>
                                        <div class="form-group">
                                            <lable>Your Email</lable>
                                            <input class="form-control" placeholder="Email ID"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email} type="email" name="email" />
                                        </div>
                                        <div class="form-group">
                                            <label>Mobile Number</label>
                                            <input class="form-control" placeholder="Phone"
                                                onChange={(e) => setMobile(e.target.value)}
                                                value={mobile} type="number" name="mobile" />
                                        </div>
                                        <div class="form-group">
                                            <label>City</label>
                                            <input class="form-control" placeholder="City"
                                                onChange={(e) => setCity(e.target.value)}
                                                value={city} type="text" name="city" />
                                        </div>
                                        <div class="form-group">
                                            <label>Course</label>
                                            <select required="" size="1" name="course"
                                                onChange={(e) => setCourse(e.target.value)} class="form-control">
                                                <option value="">Please select Any Course
                                                </option>
                                                <option value="B.Tech. Courses[CS,IT,AI &amp; DS,CSIT]">Engineering Courses [CS,IT,AI &amp; DS,CSIT]
                                                </option>
                                                <option value="B.Tech. Course [EC]">Engineering Course [Electronics &amp; Comm. Engg.]
                                                </option>
                                                <option value="B.Tech. Course [ME]">Engineering Course [Mechnical Engineering]
                                                </option>
                                                <option value="B.Tech. Course [Civil Engg.]">Engineering Course [Civil Engineering]
                                                </option>
                                                <option value="Pharmacy Courses[B.Pharm, D.Pharm]">Pharmacy Courses [B.Pharm, D.Pharm]
                                                </option>
                                                <option value="Pharmacy Course[M.Pharm]">Pharmacy Course [M.Pharm]
                                                </option>
                                                <option value="Management Courses[MBA(FT,MM,FA)]">Management Courses[MBA(FT,MM,FA)]
                                                </option>
                                                <option value="Professional Course [BBA]">Professional Courses [BBA]
                                                </option>
                                                <option value="Professional Course[BCA]">Professional Courses [BCA]
                                                </option>
                                                <option value="Professional Course[B.Com]">Professional Courses [B.Com]
                                                </option>
                                                <option value="Law Courses(LLB Hons, BA LLB Hons, BBA LLB Hons)">Law Courses [LLB Hons, BA LLB Hons, BBA LLB Hons]
                                                </option>
                                                <option value="MCA Course">MCA Course
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>What is nine plus five?</label>
                                            <input class="form-control" placeholder="Answer" value={mathAnswer}
                                                onChange={(e) => setMathAnswer(e.target.value)}
                                                type="text" name="mathAnswer" />
                                        </div>
                                        <div class="form-group">
                                            <div class="form-submit">
                                                <button type="button" onClick={HandleSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div >
                    </div >
                </Modal.Body >

            </Modal >

        </div>
    );
};

export default Navbar;
