import React,{useEffect} from 'react'
import bus from "../../assets/pdf/bus.pdf";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

const Mandatory_Disclosures = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
  return (
    <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink
     e--ua-chrome e--ua-webkit loaded" data-elementor-device-mode="desktop">
            <Header />
            <div style={{ marginTop: "100px" }}>
                <div class="overview-area aboutus-vi mb-5">
				<div class="container">
					<div class="row">
						
						<div class="col-lg-12 text-center">
							<div class="section-title mb-45">
								<h2>AICTE Mandatory Disclosures</h2>
								<p class="m-0">Click to View: AICTE Mandatory Disclosures 2021-22
								</p>
								
									<div class="courses-item">
										<div class="content">
											<h5>AICTE Mandatory Disclosures 2021-22</h5>
											<Link  class="text-decoration-none pharmabtn" to="Mandatory_Disclosures_pdf">
												View
												<i class="fas fa-chevron-right"></i>
											</Link>
										</div>
									</div>
							
							</div>
						</div>
					</div>
					
				</div>
			</div>
            </div>
            <div className='mt-5'>
                <Footer />

            </div>
        </div>
  )
}

export default Mandatory_Disclosures