import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { GetNewsEvents } from "../../api/Global";
import backgroundImage from "../../assets/images/444.png";
import imagebaseurl from "../../config/imageurl";
import Footer from "../footer/Footer";
import Accordion from "react-bootstrap/Accordion";
import ReactHtmlParser from "react-html-parser";
import { TiArrowUp, TiArrowDown } from "react-icons/ti";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import moment from "moment";




const News_And_Events = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showTopIcon, setShowTopIcon] = useState(false);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop === 0) {
      setShowTopIcon(false);
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setShowTopIcon(true);
    } else {
      setShowTopIcon(scrollTop > 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetNewsEvents();
        if (Array.isArray(response.data.data)) {
          // Sort events by date in descending order
          const sortedData = response.data.data.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA; // Descending order
          });
          // const sortedData = response.data.data.sort((a, b) => {
          //   const dateA = new Date(a.date);
          //   const dateB = new Date(b.date);
          //   return dateA - dateB; // Ascending order (Oldest to Newest)
          // });
          setData(sortedData);
          setFilteredData(sortedData);
        } else {
          setError("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        setError("Error fetching events");
      }
    };

    fetchData();
  }, []);


  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchInput(query);

    // const filtered = data.filter((event) =>
    //   event?.title?.toLowerCase().includes(query)
    // );

    const filtered = data.filter((event) =>
          (event?.title && event.title.toLowerCase().includes(query)) ||
           (event?.date && moment(event.date).format("DD MMMM YYYY").toLowerCase().includes(query)) ||
           (event?.content && event.content.toLowerCase().includes(query))
          );


    setFilteredData(filtered);
  };


  const handleSearchClick = () => {
    setSearchInput("");
    setFilteredData(data);
  };

  return (
    <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide 
    page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink 
    e--ua-chrome e--ua-webkit loaded"
      data-elementor-device-mode="desktop">
      <Header />
      {/* Breadcrumb Section */}
      <section
        className="about-breadcrumb breadcumb-bg"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="heading-title">News And Events</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Events Section */}
      <div className="overview-area aboutus-vi">
        <div className="container">
          <div className="row">
            <div className="accordion-container">
              <Accordion defaultActiveKey="0">
                {/* Search Bar */}
                <div style={{ marginBottom: "20px" }}>
                  <input
                    type="text"
                    placeholder="Search events..."
                    value={searchInput}
                    onChange={handleSearchChange}
                    style={{
                      width: "300px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleSearchClick}
                    style={{
                      //   marginLeft: "10px",
                      //   padding: "10px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <FaSearch />
                  </button>
                </div>

                {/* Accordion */}
                <h3>News and Events</h3>
                {filteredData.length > 0 ? (
                  filteredData.map((event, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <div className="event-info">
                          <div className="event-thumbnail-imagess me-3">
                            {/* <div className="metaevent-time">{event.date}</div> */}
                            <img
                              src={`${imagebaseurl}/${event.images[0]}`}
                              alt="Event Thumbnail"
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                        </div>
                        <div className="event_title_info">
                          <div className="metaevent-time">Event:
                            {moment(event.date).format("DD MMMM YYYY")}
                          </div>
                          <h5>{ReactHtmlParser(event?.title)}</h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          {event.images &&
                            Array.isArray(event.images) &&
                            event.images.map((img, idx) => (
                              <div className="col-md-3" key={idx}>
                                <div className="card gallery_event">
                                  <div className="card-image">
                                    <a
                                      href={`${imagebaseurl}/${img}`}
                                      data-fancybox={`gallery-${index}`}
                                      data-caption={` - Image ${idx + 1}`}
                                    >
                                      <img
                                        src={`${imagebaseurl}/${img}`}
                                        alt={`Gallery Image ${idx + 1}`}
                                        className="img-fluid"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <div>{error || "No events available"}</div>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll Buttons */}
      {showTopIcon ? (
        <Link
          id="scrollUp"
          to="#"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "2147483647",
            backgroundColor: "red",
            color: "#fff",
            padding: "10px",
            borderRadius: "50%",
            textAlign: "center",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          <TiArrowUp style={{ fontSize: "24px" }} />
        </Link>
      ) : (
        <Link
          id="scrollBottom"
          to="#"
          onClick={scrollToBottom}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "2147483647",
            backgroundColor: "red",
            color: "#fff",
            padding: "10px",
            borderRadius: "50%",
            textAlign: "center",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          <TiArrowDown style={{ fontSize: "24px" }} />
        </Link>
      )}

      <Footer />
    </div>
  );
};



export default News_And_Events;







// import React, { useState, useEffect } from "react";
// import Header from "../header/Header";
// import { GetNewsEvents } from "../../api/Global";
// import backgroundImage from "../../assets/images/444.png";
// import imagebaseurl from "../../config/imageurl";
// import Footer from "../footer/Footer";
// import Accordion from "react-bootstrap/Accordion";
// import ReactHtmlParser from "react-html-parser";
// import { TiArrowUp, TiArrowDown } from "react-icons/ti";
// import { Link } from "react-router-dom";
// import { FaSearch } from "react-icons/fa";
// import moment from "moment";

// const News_And_Events = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [error, setError] = useState(null);
//   const [searchInput, setSearchInput] = useState("");
//   const [showTopIcon, setShowTopIcon] = useState(false);

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
//   };

//   const handleScroll = () => {
//     const scrollTop = window.scrollY;
//     const scrollHeight = document.documentElement.scrollHeight;
//     const clientHeight = document.documentElement.clientHeight;

//     if (scrollTop === 0) {
//       setShowTopIcon(false);
//     } else if (scrollTop + clientHeight >= scrollHeight) {
//       setShowTopIcon(true);
//     } else {
//       setShowTopIcon(scrollTop > 0);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await GetNewsEvents();
//         if (Array.isArray(response.data.data)) {
//           // इवेंट्स को तारीख के अनुसार Descending Order में सेट करना
//           const sortedData = response.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
//           setData(sortedData);
//           setFilteredData(sortedData);
//         } else {
//           setError("Error fetching data");
//         }
//       } catch (error) {
//         console.error("Error fetching events:", error);
//         setError("Error fetching events");
//       }
//     };

//     fetchData();
//   }, []);

//   // 🔍 सर्च फ़ंक्शन (Title, Date और Content पर सर्च करेगा)
//   const handleSearchChange = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchInput(query);

//     const filtered = data.filter((event) =>
//       (event?.title && event.title.toLowerCase().includes(query)) ||
//       (event?.date && moment(event.date).format("DD MMMM YYYY").toLowerCase().includes(query)) ||
//       (event?.content && event.content.toLowerCase().includes(query))
//     );

//     setFilteredData(filtered);
//   };

//   const handleSearchClick = () => {
//     setSearchInput("");
//     setFilteredData(data);
//   };

//   return (
//     <div className="page-template-default page page-id-2585 desktop desktop-menu mobile-menu-push-to-left header-sticky-both wide page-has-no-sidebar title-bar-none woocommerce elementor-default elementor-kit-22 elementor-page elementor-page-2585 e--ua-blink e--ua-chrome e--ua-webkit loaded"
//       data-elementor-device-mode="desktop">
//       <Header />
      
//       {/* Breadcrumb Section */}
//       <section className="about-breadcrumb breadcumb-bg"
//         style={{
//           backgroundImage: `url(${backgroundImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           width: "100%",
//           height: "400px",
//         }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="breadcrumb-content">
//                 <h2 className="heading-title">News And Events</h2>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Events Section */}
//       <div className="overview-area aboutus-vi">
//         <div className="container">
//           <div className="row">
//             <div className="accordion-container">
//               <Accordion defaultActiveKey="0">

//                 {/* Search Bar */}
//                 <div style={{ marginBottom: "20px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search events..."
//                     value={searchInput}
//                     onChange={handleSearchChange}
//                     style={{
//                       width: "300px",
//                       padding: "10px",
//                       border: "1px solid #ccc",
//                       borderRadius: "5px",
//                     }}
//                   />
//                   <button
//                     type="button"
//                     onClick={handleSearchClick}
//                     style={{
//                       backgroundColor: "#007bff",
//                       color: "#fff",
//                       border: "none",
//                       borderRadius: "5px",
//                       cursor: "pointer",
//                     }}>
//                     <FaSearch />
//                   </button>
//                 </div>

//                 {/* Accordion */}
//                 <h3>News and Events</h3>
//                 {filteredData.length > 0 ? (
//                   filteredData.map((event, index) => (
//                     <Accordion.Item eventKey={index.toString()} key={index}>
//                       <Accordion.Header>
//                         <div className="event-info">
//                           <div className="event-thumbnail-imagess me-3">
//                             <img
//                               src={`${imagebaseurl}/${event.images[0]}`}
//                               alt="Event Thumbnail"
//                               style={{
//                                 width: "150px",
//                                 height: "150px",
//                                 objectFit: "cover",
//                               }}
//                             />
//                           </div>
//                         </div>
//                         <div className="event_title_info">
//                           <div className="metaevent-time">Event: {moment(event.date).format("DD MMMM YYYY")}</div>
//                           <h5>{ReactHtmlParser(event?.title)}</h5>
//                         </div>
//                       </Accordion.Header>
//                       <Accordion.Body>
//                         <div className="row">
//                           {event.images?.map((img, idx) => (
//                             <div className="col-md-3" key={idx}>
//                               <div className="card gallery_event">
//                                 <div className="card-image">
//                                   <a href={`${imagebaseurl}/${img}`} data-fancybox={`gallery-${index}`} data-caption={` - Image ${idx + 1}`}>
//                                     <img src={`${imagebaseurl}/${img}`} alt={`Gallery Image ${idx + 1}`} className="img-fluid" />
//                                   </a>
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       </Accordion.Body>
//                     </Accordion.Item>
//                   ))
//                 ) : (
//                   <div>{error || "No events available"}</div>
//                 )}
//               </Accordion>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Scroll Buttons */}
//       {showTopIcon ? (
//         <Link id="scrollUp" to="#" onClick={scrollToTop} className="scroll-btn">
//           <TiArrowUp style={{ fontSize: "24px" }} />
//         </Link>
//       ) : (
//         <Link id="scrollBottom" to="#" onClick={scrollToBottom} className="scroll-btn">
//           <TiArrowDown style={{ fontSize: "24px" }} />
//         </Link>
//       )}

//       <Footer />
//     </div>
//   );
// };

// export default News_And_Events;






